<script lang="ts">
  import * as Form from '$lib/components/ui/form';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import { cn } from '$lib/utils';
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';
  import Label from '$lib/components/ui/label/label.svelte';
  import Icon from '@iconify/svelte';

  export let form;
  export let name: string;
  export let label: string | undefined = undefined;
  export let description: string | undefined = undefined;
  export let readOnly: boolean = false;
  export let icon: string | undefined = undefined;

  export let value: boolean = false;

  let className: $$Props['class'] = undefined;
  export { className as class };

  const { form: formData } = form;
</script>

{#if name}
  <Form.Field {form} {name} class={cn('space-y-2', className)}>
    <div class="flex items-center space-x-2">
      <Form.Control let:attrs>
        {#if !readOnly}
          <Checkbox {...attrs} bind:checked={$formData[name]} />
        {/if}
        {#if label}
          <Form.Label>
            {#if icon}
              <HantaIcon {icon} class="mr-2 w-4 h-4" />
            {/if}
            {label}
          </Form.Label>
        {/if}
      </Form.Control>
    </div>
    {#if description}
      <Form.Description>{description}</Form.Description>
    {/if}
    <Form.FieldErrors />
  </Form.Field>
{:else}
  <div class="flex flex-col space-y-2">
    <Label for={label}>{label}</Label>
    {#if !readOnly}
      <input
        id={label}
        type="checkbox"
        bind:checked={value}
        class={cn('size-5', className)}
      />
    {:else}
      <div
        class="flex justify-center items-center bg-gray-100 rounded-md size-5"
      >
        {#if value}
          <Icon icon="mdi:check" class="size-4" />
        {/if}
      </div>
    {/if}
  </div>
{/if}
