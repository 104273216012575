import { authStore } from '$lib/auth-store';
import { createFilters } from './queries';
import { supabase } from './supabase';
import { get } from 'svelte/store';

const invokeRpcAggregation = async (
  table,
  field,
  filters,
  is_array,
  join_table,
  join_condition,
) => {
  const supabaseFilters = createFilters(filters, true);

  let { data, error } = await supabase(get(authStore)?.token).rpc(
    'v_array_count_with_filter',
    {
      tablename: table,
      fieldname: field,
      filters: supabaseFilters,
      is_array: is_array,
      join_table: join_table,
      join_condition: join_condition,
    },
  );
  if (error) console.error(error);

  return { data };
};

export async function getBucketsForQuery({
  collection,
  field,
  filters,
  search,
  fulltextsearch,
  is_array,
  join_table,
  join_condition,
}) {
  if (true) {
    const { data } = await invokeRpcAggregation(
      collection,
      field,
      filters,
      is_array,
      join_table,
      join_condition,
    );
    return data;
    // return (data || []).sort((a, b) => b.count - a.count);
  } else if (field === 'specialities_TODO') {
    const query = supabase(get(authStore)?.token)
      .from('v_speciality_count')
      .select(`field:${field},count`);

    if (filters) {
      const supabaseFilters = createFilters(filters);
      supabaseFilters.forEach(filter => {
        query.filter(filter.field, filter.operator, filter.value);
      });
    }
    query.order('count', { ascending: false });
    const { data } = await query;
    return data;
  } else {
    const query = supabase(get(authStore)?.token)
      .from(collection)
      .select(`field:${field},count:${field}.count()`);
    //.not(field, 'is', null)
    //.not(field, 'eq', 0)
    if (filters) {
      const supabaseFilters = createFilters(filters);
      supabaseFilters.forEach(filter => {
        query.filter(filter.field, filter.operator, filter.value);
      });
    }

    if (search) {
      if (fulltextsearch) {
        const searchTerm = search.replace(/ /g, ':* & ') + ':*';
        console.debug(searchTerm);
        query.textSearch('fts', searchTerm);
      } else {
        query.ilike('name', `%${search}%`);
      }
    }
    // query.order('count', { ascending: false });
    const { data, error } = await query;

    if (error) {
      console.error(error);
      return null;
    } else {
      return data.sort((a, b) => b.count - a.count);
    }
  }
}

export async function getBucketsForQuery1({ collection, field }) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from(collection)
    .select(`count:count("${field}")`);

  return data;
}

export async function getChartData({ collection, field }) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from(collection)
    .select(`count:count("${field}")`);

  if (error) {
    console.error(error);
    return null;
  } else {
    return data;
  }
}

export async function getCustomerOrderMetrics({
  customer_uuid,
  time_group,
  criteria,
  date_from,
  date_to,
}) {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'get_customer_order_metrics',
    {
      customer_uuid,
      time_group,
      criteria,
      date_from:
        date_from ?? new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 1),
      date_to: date_to ?? new Date(),
    },
  );
  if (error) {
    console.error(error);
    return null;
  } else {
    return data;
  }
}
