<script lang="ts">
  import Icon from '@iconify/svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { appStore, popupStore } from '$lib/app-store';
  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { getOrCreateItem, getItemById } from '$lib/api/queries';
  import { formatDistanceToNow } from 'date-fns';

  import CrmObjectActions from '$lib/ui/crm-object-actions.svelte';

  export let module: string;
  export let id: string;
  export let isPopup = false;

  const moduleStore = writable(module);
  const idStore = writable(id);

  $: moduleStore.set(module);
  $: idStore.set(id);

  const query = createQuery(
    derived([moduleStore, idStore], ([$module, $id]) => ({
      enabled: !!$module && !!$id,
      queryKey: [$module, $id],
      queryFn: async () => {
        const result = await getOrCreateItem($module, $id);
        if (!result) {
          throw new Error('Item not found');
        }
        return result;
      },
    })),
  );

  function onBack() {
    if (isPopup) {
      popupStore.closePopup();
    } else {
      appStore.select({
        module:
          $appStore.selectedStack[$appStore.selectedStack.length - 1]?.module,
        id: $appStore.selectedStack[$appStore.selectedStack.length - 1]?.id,
        tab: $appStore.selectedStack[$appStore.selectedStack.length - 1]?.tab,
      });
      appStore.setSelectedStack([]);
    }
  }

  $: store = isPopup ? popupStore : appStore;
  $: isMobile = isPopup ? false : $appStore.isMobile;
  $: selected = isPopup ? $popupStore.popupData : $appStore.selected;
</script>

<div class="flex flex-col h-full">
  <div class="px-6 pt-4 pb-2 border-b border-solid border-b-primary-200/20">
    {#if !isPopup && $appStore.selectedStack?.length > 0}
      {#each $appStore.selectedStack.filter(e => e.module !== 'dashboard') as stack}
        <Button variant="link" on:click={onBack}>
          <Icon icon="mdi:arrow-left" class="size-4" />
          Back to {stack.module}
        </Button>
      {/each}
    {:else}
      <div class="flex flex-row items-baseline space-x-4 h-full font-normal">
        {#if isMobile}
          <Button variant="secondary" on:click={onBack} class="rounded-full">
            <Icon icon="mdi:arrow-left" class="size-4" />
          </Button>
        {/if}
        <div>
          <h2 class="font-bold capitalize">
            {selected?.module || 'Loading...'}
          </h2>
          <h3 class="hidden text-sm text-zinc-500">{selected?.id || ''}</h3>
        </div>
        {#if $query.data}
          <CrmObjectActions id={selected?.id} {module} item={$query.data} />
        {/if}
        <slot name="actionBar" />
      </div>
    {/if}
  </div>
  <div class="overflow-auto flex-grow">
    {#if $query.isLoading}
      <div class="p-4">Loading...</div>
    {:else if $query.isError}
      <div class="p-4 text-red-500">Error: {$query.error.message}</div>
    {:else if $query.isSuccess && $query.data}
      {@const item = $query.data}
      {#key `${item?.id}-${item?.modifiedAt}-${item?.deleted}`}
        <slot name="header" {item} />
        <slot {item} />
      {/key}
    {:else}
      <div class="p-4">No data available</div>
    {/if}
  </div>
</div>
