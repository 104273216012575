<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';

  import {
    type Account,
    ActivityType,
    type ActivityWithRefs,
    type Deal,
  } from '$db/schema';

  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import { Button } from '$lib/components/ui/button';
  import { appStore } from '$lib/app-store';

  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let deal: Deal;

  $: officialName = deal?.name;
  $: customer = deal && deal.customerObj;

  $: logo = customer?.logo || null;

  export let activity: ActivityWithRefs;
  const openItem = (account: Account) => {
    appStore.setCurrentModule('accounts');
    appStore.select({
      id: account.id,
      module: 'accounts',
    });
    appStore.openPopup(true);
  };
</script>

<div
  class="flex relative flex-col items-start p-4 space-y-2 w-full md:space-x-8 md:flex-row"
>
  {#if $appStore.isMobile}
    <div
      class="absolute left-0 z-[-1] w-full h-[calc(100%-65px)] bg-blue-300/10 dark:bg-slate-800 top-20 rounded-t-3xl"
    ></div>
  {/if}

  <div>
    <Avatar.Root class="rounded-none size-48">
      <Avatar.Image
        alt="Company Logo"
        class="object-scale-down rounded-none"
        src={logo}
      />

      <Avatar.Fallback
        class="w-full p-1 text-[8px] text-center text-gray-400 rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
      >
        {officialName}
      </Avatar.Fallback>
    </Avatar.Root>
  </div>
  <div class="w-full">
    <div class="flex justify-between items-center space-x-4">
      <div class="flex flex-1 justify-between items-center w-full">
        <div class="w-full">
          <div class="mt-2 space-y-2 text-xs text-muted-foreground">
            {#if customer}
              <CrmAvatar
                hideIcon={true}
                id={customer?.id}
                module="accounts"
                name={customer?.name}
                on:open={() => openItem(customer)}
                size="size-7"
              />
            {/if}
          </div>
          <div class="text-xl font-bold text-left">{officialName}</div>
        </div>
      </div>
    </div>
    <div class="flex mt-6 space-x-4">
      <ActivityActionBar {activity} />
    </div>
    <div class="flex mt-4 w-full">
      <Button
        on:click={() => {
          appStore.openActivity({
            type: ActivityType.enum.Assignment,
            contacts: [],
            deals: [],
            accounts: [],
            candidates: [],
            candidateObj: undefined,
            accountObj: deal.customerObj,
            dealObj: {
              id: deal.id + '',
              name: deal.name,
            },
          });
        }}
      >
        <Icon icon="mdi:link" class="mr-2 size-4" />
        <span>Assign Candidate</span>
      </Button>
    </div>
  </div>
</div>
