import type {
  PriceRequest,
  Dimension,
  DimensionValue,
  Price,
  IDimensionService,
} from './prices';
import { pricesConfig } from './prices-config';

export class DimensionService implements IDimensionService {
  public createTarifDimensions(
    dimensions: Dimension[],
  ): Record<string, number> {
    const offset = pricesConfig.columns.length;
    return Object.fromEntries(
      dimensions.map((dimension, idx) => [dimension.name, idx + offset]),
    );
  }

  public async getDimensionValues(
    item: PriceRequest,
    dimensions: Dimension[],
  ): Promise<DimensionValue[]> {
    const dimensionValues = dimensions
      .filter(dimension => dimension.field)
      .map(dimension => [
        dimension.name,
        function (expression: string) {
          try {
            return eval(expression);
          } catch (e) {
            return null;
          }
        }.call(item, dimension.field),
        dimension.field,
        dimension.type,
      ]);
    const result = dimensionValues.filter(dim => dim[1] !== undefined);

    return result.map(dim => ({
      name: dim[0],
      value: dim[1],
      field: dim[2],
      type: dim[3],
    }));
  }

  public isPriceMatchingDimensions(
    price: Price,
    dimensions: DimensionValue[],
    tarifDimensions: Record<string, number>,
  ): boolean {
    return dimensions.every(dimension => {
      const dimensionIdx = tarifDimensions[dimension.name];
      const priceDimensionValue = price[dimensionIdx];

      if (this.isRange(price, dimensionIdx)) {
        const [min, max] = String(priceDimensionValue)
          .split(' - ')
          .map(parseFloat);
        const numericValue = Number(dimension.value);
        return numericValue > min && numericValue <= max;
      }

      const result =
        dimension.value?.toString() === priceDimensionValue?.toString();
      if (!result) {
        if (dimension.value === null && priceDimensionValue === '') {
          return true;
        }
      }
      return result;
    });
  }

  private isRange(price: Price, idxDimension: number): boolean {
    const value = price[idxDimension];
    if (typeof value === 'string' && value.includes(' - ')) {
      const [min, max] = value.split(' - ').map(parseFloat);
      return !isNaN(min) && !isNaN(max) && min >= 0 && max >= 0;
    }
    return false;
  }
}

function getPropertyValue(obj: any, path: string): any {
  try {
    // Remove 'this.' prefix if present
    const cleanPath = path.replace(/^this\./, '');

    // Split the path into parts
    const parts = cleanPath.split('.');

    // Traverse the object
    let value = obj;
    for (const part of parts) {
      value = value?.[part];
    }

    return value ?? null;
  } catch (e) {
    return null;
  }
}
