<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let label: string = 'Status';
  export let data: Array<{
    status: string;
    count: number;
    amount: number;
    color: string;
    paymentDeadlineStart?: string;
    paymentDeadlineEnd?: string;
  }>;

  // Create event dispatcher
  const dispatch = createEventDispatcher<{
    click: {
      status: string;
      count: number;
      amount: number;
      paymentDeadlineStart?: string;
      paymentDeadlineEnd?: string;
    };
  }>();

  function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }).format(amount);
  }

  function handleClick(type: (typeof data)[number]) {
    dispatch('click', {
      status: type.status,
      count: type.count,
      amount: type.amount,
      paymentDeadlineStart: type.paymentDeadlineStart,
      paymentDeadlineEnd: type.paymentDeadlineEnd,
    });
  }
</script>

<div class="mt-4 grid grid-cols-[auto_1fr_auto] gap-x-8 gap-y-2 text-sm">
  <div class="contents font-semibold">
    <div class="grid col-span-3 grid-cols-subgrid">
      <div>{label}</div>
      <div class="text-right">Count</div>
      <div class="text-right">Amount</div>
    </div>
  </div>
  {#each data as type}
    <div class="contents group">
      <div
        class="grid col-span-3 items-center p-2 rounded transition-colors grid-cols-subgrid hover:bg-primary/20 hover:cursor-pointer"
        on:click={() => handleClick(type)}
        on:keydown={e => e.key === 'Enter' && handleClick(type)}
        role="button"
        tabindex="0"
      >
        <div class="flex items-center">
          <div
            style="background-color: {type.color}"
            class="mr-2 w-4 h-4 rounded-full"
          ></div>
          <span>{type.status}</span>
        </div>
        <div class="text-right">{type.count}</div>
        <div class="font-semibold text-right">
          {formatCurrency(type.amount)}
        </div>
      </div>
    </div>
  {/each}
</div>
