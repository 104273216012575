export class DateUtils {
  /**
   * Calculates the week number and year for a given date.
   * The week number is calculated according to ISO 8601:
   * - Weeks start on Monday
   * - The first week of the year is the one that includes January 4th
   *
   * @param date The date to calculate the week number for
   * @returns A string in the format "week year" (e.g., "1 2024")
   * @throws Error if the input date is invalid
   */
  public static getWeekNumber(date: Date): string {
    if (isNaN(date.getTime())) {
      // throw new Error('Invalid date provided');
      return '';
    }

    // Create UTC date to handle timezone differences
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
    );

    return `${weekNo} ${d.getUTCFullYear()}`;
  }
}
