<script lang="ts">
  import { ContactRoleType } from '$db/schema';
  import Icon from '@iconify/svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { updateContactRole } from '$lib/api/mutations';

  export let crmContact;
  const contactRoles = [
    {
      label: 'Contact',
      value: ContactRoleType.enum.Contact,
      description: 'A person or organization your company interacts with.',
    },
    {
      label: 'Candidate',
      value: ContactRoleType.enum.Candidate,
      description: 'Potential employee for a specific deal.',
    },
    {
      label: 'Contact & Candidate',
      value: ContactRoleType.enum.ContactAndCandidate,
      description:
        'A person acting as a customer contact and potential employee.',
    },
    {
      label: 'Employee',
      value: ContactRoleType.enum.Employee,
      description: 'A person working for your company using this system.',
    },
  ];
</script>

<HantaSelect
  class="text-xs"
  onSelect={value => {
    updateContactRole(value, crmContact.id);
  }}
  options={contactRoles}
  searchable={false}
  value={crmContact.role}
>
  <svelte:fragment let:label let:value slot="trigger">
    <Badge class="py-0.5 text-nowrap" variant="outline">
      {#if value === ContactRoleType.enum.Contact}
        <Icon icon="mdi:account" class="mr-1 w-4 h-4" />
      {:else if value === ContactRoleType.enum.Candidate}
        <Icon icon="mdi:account-search" class="mr-1 w-4 h-4" />
      {:else if value === ContactRoleType.enum.ContactAndCandidate}
        <Icon icon="mdi:account-search" class="mr-1 w-4 h-4" />
      {:else if value === ContactRoleType.enum.Employee}
        <Icon icon="mdi:account" class="mr-1 w-4 h-4" />
      {/if}
      {label}
      <Icon icon="mdi:chevron-down" class="ml-2 w-4 h-4 opacity-50 shrink-0" />
    </Badge>
  </svelte:fragment>
</HantaSelect>
