<script lang="ts">
  import { Command as CommandPrimitive } from 'cmdk-sv';
  import { cn } from '$lib/utils.js';
  import Icon from '@iconify/svelte';

  type $$Props = CommandPrimitive.InputProps;

  let className: string | undefined | null = undefined;
  export { className as class };
  export let value: string = '';
</script>

<div class="flex items-center px-3 border-b" data-cmdk-input-wrapper="">
  <Icon icon="mdi:magnify" class="mr-2 w-4 h-4 opacity-50 shrink-0" />
  <CommandPrimitive.Input
    class={cn(
      'focus-visible:ring-0 flex h-10 w-full rounded-md bg-transparent py-3 border-none text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...$$restProps}
    bind:value
  />
</div>
