<script lang="ts">
  export let moduleName;
  export let item;
</script>

<h1>GenericItem</h1>

<pre>
  {moduleName}
{JSON.stringify(item, null, 5)}
</pre>
