<script lang="ts">
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Activities from '$lib/pages/activities/activities.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import UsersSelector from './../../widgets/users/users-selector.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaOrderSelector from '$lib/widgets/orders/order-selector.svelte';
  import HantaInvoiceSelector from '$lib/widgets/invoices/invoice-selector.svelte';

  import { Label } from '$lib/components/ui/label';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { z } from 'zod';
  import * as Tabs from '$lib/components/ui/tabs';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';

  import { saveIssue } from '$lib/api/mutations';
  import { type ActivityWithRefs, insertIssueSchema } from '$db/schema';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  type Issue = z.infer<typeof insertIssueSchema>;

  const moduleName = 'issues';
  export let item;

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Issue> =>
      Promise.resolve(saveIssue(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, item.id] });
      const previousItems = client.getQueryData<Issue[]>([moduleName, item.id]);

      client.setQueryData([moduleName, item.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, item.id] });
    },
  });

  const form = superForm<Issue>(item, {
    validators: zodClient(insertIssueSchema),
    resetForm: false,
    SPA: true,
    dataType: 'json',
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);

      let parse = insertIssueSchema.parse($formData);
      $addMutation.mutate(parse, {
        onError(error) {
          console.error('Error', error);
        },
        onSuccess: issue => {},
      });
    },
  });

  const { form: formData, errors, enhance } = form;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [],
    deals: [],
    candidates: [],
    contacts: [],
    issues: [
      {
        id: item?.id,
        name: item?.name,
      },
    ],
  };
</script>

<PageWithTitle>
  <div slot="card" class="w-full">
    <HantaInputText {form} name="name" label="Name" hideLabel={true} />
  </div>
  <div slot="short-card" class="p-4 w-full">
    <HantaInputText {form} name="name" label="Name" hideLabel={true} />
  </div>

  <div slot="content" class="grid grid-cols-3 p-1">
    <div class="col-span-3">
      <div class="mt-4">
        <Tabs.Root value="description" class="w-full">
          <Tabs.List>
            <Tabs.Trigger value="description">Description</Tabs.Trigger>
            <Tabs.Trigger value="resolution">Resolution</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="description">
            <HantaInputTextareaRichtext {form} name="description" rows={10} />
          </Tabs.Content>
          <Tabs.Content value="resolution">
            <HantaInputTextareaRichtext {form} name="resolution" rows={10} />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>

    <div class="col-span-3 border-t">
      <Activities
        id={item?.id}
        module={'issues'}
        type="all"
        withToolbar={true}
        withTabs={false}
        {activity}
      />
    </div>
  </div>
  <div slot="sidebar">
    <form method="POST" use:enhance class="p-4 space-y-3">
      <HantaInputText {form} name="refId" label="id" />
      <HantaInputDate {form} name="issuedOn" label="Issued On" />
      <AccountSelector
        bind:value={$formData.account}
        name="account"
        label="Account"
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.status}
        type="Complaint > Status"
        label="Status"
        multiple={false}
      />

      <UsersSelector
        bind:value={$formData.reporter}
        name="reporterId"
        label="Reported by"
        multiple={false}
      />
      <UsersSelector
        bind:value={$formData.responsible}
        name="responsibleId"
        label="Assigneed to"
        multiple={false}
      />

      <HantaOrderSelector
        bind:value={$formData.order}
        name="order"
        label="Order"
        multiple={false}
      />
      <HantaInvoiceSelector
        bind:value={$formData.invoice}
        name="invoice"
        label="Invoice"
        multiple={false}
      />

      <div class="my-4">
        <Label>Files</Label>

        <Files
          folder={`issues/${item?.id}`}
          compact={true}
          title=""
          maxColumns={1}
          uploadOnEnd={true}
        />
      </div>

      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
