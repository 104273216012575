let observer; // Store the observer instance in a variable
let debounceTimeout; // Store the timeout ID for the debounce

function debounce(func, delay) {
  return function (...args) {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => func.apply(this, args), delay);
  };
}

function parseLiMessages(linkedInUrl, resultCallback) {
  const parseAndLogMessagesDebounced = debounce(parseAndLogMessages, 1000); // 1 second

  function attachOnNewMessageObserver() {
    const messageContainer = document.querySelector(
      '.msg-s-message-list-container',
    );

    if (!messageContainer) {
      console.error('Message container not found');
      return;
    }

    observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          console.debug('New message added');
          // You can add your custom logic here, for example:
          parseAndLogMessagesDebounced();
        }
      }
    });

    observer.observe(messageContainer, {
      childList: true,
      subtree: true,
    });
  }

  function attachFormSubmitListener() {
    document.querySelectorAll('form').forEach(form => {
      // Check if the form already has an event listener attached
      if (!form.hasAttribute('data-listener-attached')) {
        form.addEventListener('submit', event => {
          parseAndLogMessages();
        });
        // Mark this form as having a listener attached
        form.setAttribute('data-listener-attached', 'true');
      }
    });
  }

  // Function to scroll the message container
  function scrollMessageContainer(container, callback) {
    const scrollHeight = container.scrollHeight;
    const scrollStep = 100;
    let scrollPosition = 0;

    function scrollStepFunction() {
      if (scrollPosition < scrollHeight) {
        container.scrollTo(0, scrollPosition);
        scrollPosition += scrollStep;
        setTimeout(scrollStepFunction, 100);
      } else {
        callback();
      }
    }

    scrollStepFunction();
  }

  // Function to parse and log messages
  function parseAndLogMessages() {
    // Select all conversation items
    const conversationItems = document.querySelectorAll(
      "[data-view-name='message-list-item']",
    );
    const timeElements = document.querySelectorAll(
      'time.msg-s-message-list__time-heading',
    );

    let allMessages = [];
    let lastSenderName = 'Unknown';
    let lastUserId = 'Unknown';
    let lastDate = '';

    // Helper function to extract and clean user name
    function extractUserName(fullText) {
      return fullText ? fullText.split('\n')[0].trim() : 'Unknown';
    }

    // Assume the first profile link is the current user (me)
    const myProfileLinkElement = document.querySelector(
      '.msg-s-profile-card-one-to-one .msg-s-event-listitem__link',
    );
    const myProfileLink = myProfileLinkElement
      ? myProfileLinkElement.href
      : null;
    const myUserId = myProfileLink
      ? myProfileLink.split('/in/')[1].split('/')[0]
      : 'Unknown';

    // Chat with user details
    const chatWithUserElement = document.querySelector(
      '.msg-thread__link-to-profile',
    );
    const chatWithUserName = extractUserName(
      chatWithUserElement ? chatWithUserElement.textContent.trim() : 'Unknown',
    );
    const chatWithUserProfileLink = chatWithUserElement
      ? chatWithUserElement.href
      : null;
    const chatWithUserId = chatWithUserProfileLink
      ? chatWithUserProfileLink.split('/in/')[1].split('/')[0]
      : 'Unknown';

    // Create a map of message elements to their corresponding dates
    const dateMap = new Map();
    timeElements.forEach(timeElement => {
      const dateText = timeElement.textContent.trim();
      let nextElement = timeElement.nextElementSibling;
      while (
        nextElement &&
        nextElement.classList &&
        !nextElement.classList.contains('msg-s-event-listitem')
      ) {
        nextElement = nextElement.nextElementSibling;
      }
      if (nextElement) {
        dateMap.set(nextElement, dateText);
      }
    });

    // Iterate through each conversation item
    conversationItems.forEach(message => {
      // Get the message ID
      const messageId = message.getAttribute('data-event-urn');

      // Get the sender's name
      const senderNameElement = message.querySelector(
        '.msg-s-message-group__profile-link',
      );
      const senderName = extractUserName(
        senderNameElement
          ? senderNameElement.textContent.trim()
          : lastSenderName,
      );

      // Get the user profile link
      const userProfileLinkElement = message.querySelector(
        '.msg-s-message-group__profile-link',
      );
      const userProfileLink = userProfileLinkElement
        ? userProfileLinkElement.href
        : null;

      // Extract the user ID from the profile link
      const userId = userProfileLink
        ? userProfileLink.split('/in/')[1].split('/')[0]
        : lastUserId;

      // Update the last known sender and user ID
      if (senderNameElement) lastSenderName = senderName;
      if (userProfileLinkElement) lastUserId = userId;

      // Get the message date if present in the map
      const messageDate = dateMap.get(message) || lastDate;

      // Update the last known date
      if (dateMap.has(message)) lastDate = messageDate;

      // Get the message timestamp
      const messageTimeElement = message.querySelector(
        '.msg-s-message-group__timestamp',
      );
      const messageTime = messageTimeElement
        ? messageTimeElement.textContent.trim()
        : 'Unknown';

      // Get the message content and preserve whitespace and line breaks
      const messageContentElement = message.querySelector(
        '.msg-s-event__content',
      );
      const messageContentHtml = messageContentElement
        ? messageContentElement.innerHTML
        : '';
      const messageContent = messageContentHtml
        .replace(/<br>/g, '\n')
        .replace(/<\/?[^>]+(>|$)/g, '')
        .trim();

      // Determine if the message is incoming
      const isIncoming = userId !== myUserId;

      // Add the extracted information to the array
      allMessages.push({
        messageId,
        message: messageContent,
        sender: senderName,
        userId,
        time: `${messageDate} ${messageTime}`,
        isIncoming,
      });
    });

    // Log the messages as a table
    console.table(allMessages);
    resultCallback({
      messages: allMessages,
      chatWithUser: {
        name: chatWithUserName,
        profileLink: chatWithUserProfileLink,
        userId: chatWithUserId,
      },
      me: {
        profileLink: myProfileLink,
        userId: myUserId,
      },
    });
  }

  // Select the message container
  const messageContainer = document.querySelector(
    '.msg-s-message-list-container',
  );

  if (observer) {
    observer.disconnect();
    console.debug('Observer disconnected.');
  }

  attachOnNewMessageObserver();

  // Scroll the message container and then parse and log messages
  scrollMessageContainer(messageContainer, () => {
    parseAndLogMessages();
  });
}

function parseLinkedIn() {
  return new Promise((resolve, reject) => {
    const url = location.protocol + '//' + location.host + location.pathname;
    const scrollDelta = document.body.scrollHeight;

    if (location.pathname.startsWith('/in/')) {
      scrollToSmoothly(scrollDelta, 1000);

      setTimeout(() => {
        linkedInClickOnLoadMoreButtons();
      }, 500);
    }

    setTimeout(async () => {
      await hantaLinkedinParser(url, result => resolve({ result: result }));
    }, 1000);
  });
}

async function hantaLinkedinParser(linkedInUrl, callback) {
  const isCompany = location.pathname.startsWith('/company/');
  const isMessaging = location.pathname.startsWith('/messaging/');
  const isProfile = location.pathname.startsWith('/in/');
  if (isMessaging) {
    parseLiMessages(linkedInUrl, callback);
  } else if (isCompany) {
    parseLiCompanyCodeTagBased(linkedInUrl, callback);
    //parseLiCompany(linkedInUrl, callback);
  } else if (isProfile) {
    linkedInClickOnLoadMoreButtons();
    setTimeout(async () => {
      await parseLiContact(linkedInUrl, callback);
    }, 500);
  } else if (import.meta.env.DEV) {
    await parseLiContact(linkedInUrl, callback);
  } else {
    callback({ error: 'No data found' });
  }
}

async function parseLiContact(linkedInUrl, callback) {
  let company;
  let contactInfos = [];
  let contact;

  const { firstname, lastname } = extractContactName();

  const { position } = extractPosition();
  const { photo, linkedInPhoto } = await extractPhoto();
  const { schwerpunkt } = extractSummary();
  const { candidateCompany } = extractCompanyName();

  const { lebenslauf, lebenslaufLinkedIn } = extractLebenslauf();

  const linkedinId = location.host.startsWith('localhost')
    ? 'jeffweiner08'
    : location.pathname.split('/')[2];

  linkedinId &&
    document.querySelectorAll('code').forEach(el => {
      try {
        if (
          el?.innerText?.indexOf('OrganizationMemberTab') >= 0 ||
          el?.innerText?.indexOf('identityDashProfilesByMemberIdentity') >= 0
        ) {
          const found = JSON.parse(el.innerText);
          found.included.forEach(el => {
            contactInfos.push(el);
            const {
              publicIdentifier,
              defaultLocalizedName,
              firstName,
              lastName,
              birthDateOn,
              headline,
              profilePicture,
            } = el;
            if (publicIdentifier === linkedinId) {
              contact = el;
            }
          });
        }
      } catch (e) {}
    });

  const { phone, email, address } = extractCommunication();
  let result = {
    isCandidate: true,
    linkedin: linkedInUrl,
    name: `${firstname} ${lastname}`,
    firstname,
    lastname,
    photo,
    linkedInPhoto,
    phone,
    email,
    addressWork: address,
    focus: schwerpunkt,
    candidateCompany,
    // resume: lebenslauf,
    resumeLinkedIn: lebenslaufLinkedIn,
    position,
    contact,
    contactInfos,
  };

  /*
  extractTopSkills(result);
  extractSkills(result);
  extractQualifications(result);
  */
  closePopup();
  callback(result);
}

const parseAddress = location => {
  if (!location)
    return { street: null, city: null, postalCode: null, country: null };

  const parts = location.split(', ');
  return {
    street: parts[0] || null,
    city: parts[1] || null,
    postalCode: parts[2] || null,
    country: parts[3] ? parts[3].split(' ')[0] : null, // Assuming the country is prefixed by the last comma and ends before a space
  };
};

function parseLiCompany(linkedInUrl, callback) {
  const linkedinWithoutAbout = linkedInUrl
    ? linkedInUrl.split('/').slice(0, 5).join('/') + '/'
    : linkedInUrl;
  const linkedin = linkedinWithoutAbout;
  const universalNameUrl = linkedinWithoutAbout.split('/').at(-2);

  // Selectors based on typical LinkedIn company page structure
  const selectors = {
    companyName: 'h1',
    logo: 'img.org-top-card-primary-content__logo',
    description: 'p.org-about-us-organization-description__text',
    companyLocation: 'img.org-location-map__image',
    website: "a[href^='http']",
    industry: 'dd.mb4.t-black--light.text-body-medium',
    size: 'dd.t-black--light.text-body-medium.mb1',
    headquarters: 'dd.mb4.t-black--light.text-body-medium',
    specialties: 'dd.mb4.t-black--light.text-body-medium',
  };

  // Extracting company information

  const description = document.querySelector(selectors.description)?.innerText;
  const logo = document
    .querySelector('img.org-top-card-primary-content__logo')
    ?.getAttribute('src');
  const name = document.querySelector(selectors.companyName)?.innerText;
  const companyLocation = document
    .querySelector(selectors.companyLocation)
    ?.getAttribute('alt');
  const address = parseAddress(companyLocation);

  const websiteUrl = document.querySelector(selectors.website).href;
  const phone = document.querySelector('a[href^="tel:"]');
  // const industry = document.querySelector().textContent.trim();
  // const size = document.querySelector(selectors.size).textContent.trim();

  /* const specialities = document
    .querySelectorAll(selectors.specialties)[4]
    .textContent.trim(); */

  const result = {
    linkedin,
    name,
    officialName: name,
    logo,
    address,
    websiteUrl,

    description,
    phone: phone?.number ?? '',

    // universalName,
    // specialities,

    // employeeCount
    // tagline,
    // foundedOn: foundedOn?.year ?? 0,
    // employeeCountRange: `${start ?? ''}${end ? ' - ' : '+'}${end ?? ''}`,
  };

  callback(result);
}

function parseLiCompanyCodeTagBased(linkedInUrl, callback) {
  console.debug('parseLiCompany');
  let companies = [];
  let contact = [];

  const linkedinWithoutAbout = linkedInUrl
    ? linkedInUrl.split('/').slice(0, 5).join('/') + '/'
    : linkedInUrl;

  const universalNameUrl = linkedinWithoutAbout.split('/').at(-2);

  document.querySelectorAll('code').forEach(el => {
    try {
      if (
        el?.innerText?.indexOf('OrganizationMemberTab') >= 0 ||
        el?.innerText?.indexOf('identityDashProfilesByMemberIdentity') >= 0
      ) {
        const found = JSON.parse(el.innerText);
        console.debug('Hanta:', { found });
        found.included.forEach(el => {
          if (el['pageType'] === 'COMPANY') {
            const {
              viewerPermissions,
              similarOrganizations,
              memberTabs,
              employeeExperienceSettings,
              lixTreatments,
              callToAction,
              ...parsedCompany
            } = el;

            companies.push(parsedCompany);
          } else if (el['lastName']) {
            contact.push(el);
          }
        });
      }
    } catch (e) {}
  });

  const company = companies.find(el => el.universalName === universalNameUrl);

  if (!company) {
    // location.reload();
    fetch(linkedInUrl)
      .then(response => response.text())
      .then(async text => {
        try {
          const id = 'voyager-response';
          const divResult = document.getElementById(id);
          if (divResult) {
            if (
              divResult.getAttribute('aria-description') === universalNameUrl
            ) {
              callback({ error: 'No data found' });
              return;
            } else {
              divResult.remove();
            }
          }
          const div = document.createElement('div');
          div.id = id;
          div.setAttribute('aria-description', universalNameUrl);
          div.innerHTML = text;
          div.classList.add('sr-only');
          document.body.appendChild(div);
          await hantaLinkedinParser(linkedInUrl, callback);
        } catch (e) {
          console.debug('Hanta: ', { e });
        }
      })
      .catch(e => {
        console.debug('Hanta: ', { e });
        callback({ error: 'No data found' });
      });
  } else {
    let result = {};
    if (company) {
      const { name } = extractAccountName();
      const officialName = name; // TODO: get official name

      const {
        universalName,
        foundedOn,
        employeeCount,
        employeeCountRange: { start, end } = {},
        phone,
        specialities,
        websiteUrl,
        tagline,
        headquarter,
        description,
        crunchbaseFundingData,
      } = company || {};

      const {
        address: {
          country,
          geographicArea,
          city,
          postalCode,
          line1: street,
          line2,
        },
      } = headquarter || {
        address: {},
      };

      const {
        logoResolutionResult: {
          vectorImage: { rootUrl, artifacts },
        },
      } = company;

      const logo =
        rootUrl && artifacts && artifacts.length > 0
          ? rootUrl + artifacts[0].fileIdentifyingUrlPathSegment
          : null;

      result = {
        universalName,
        linkedin: linkedinWithoutAbout,
        logo,
        websiteUrl,
        name: officialName ?? name,
        description,
        officialName,
        employeeCount,
        phone: phone?.number ?? '',
        tagline,
        foundedOn: foundedOn?.year ?? 0,
        employeeCountRange: `${start ?? ''}${end ? ' - ' : '+'}${end ?? ''}`,
        specialities,
        address: {
          country,
          geographicArea,
          city,
          postalCode,
          street,
          line2,
        },
        crunchbaseFundingData,
        company,
      };
      callback(result);
    } else {
      callback({ error: 'No data found' });
    }
  }
}

function extractCommunication() {
  let communication = {};
  var email = document.querySelector("a[href^='mailto:']");
  if (email && email.textContent !== undefined) {
    communication['email'] = email.textContent.trim();
  }

  const phoneSection = document.querySelectorAll(
    "section:has([href='#phone-handset-medium'])",
  );
  if (phoneSection && phoneSection.length >= 2) {
    const phone = phoneSection[1].querySelector('span');
    communication['phone'] = phone?.innerText.trim();
  }

  const addressSection = document.querySelectorAll(
    "section:has([href='#location-marker-medium'])",
  );
  if (addressSection && addressSection.length >= 2) {
    const address = addressSection[1].querySelector('a');
    communication['address'] = {
      street: address?.innerText.trim(),
    };
  }

  return communication;
}

async function extractPhoto() {
  return new Promise(async (resolve, reject) => {
    let photo = '';
    let el = document.querySelector(
      '.pv-top-card-profile-picture__image--show',
    );
    if (el && el.src) {
      const response = await fetch(el.src);
      if (response.ok) {
        // Get the blob data from the response
        const blob = await response.blob();

        // Use FileReader to read the blob as Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // reader.result contains the Base64 string
          const base64String = reader.result;
          photo = base64String;
          console.debug({ photo });
          resolve({ photo, linkedInPhoto: el.src });
        };

        reader.onerror = error => {
          console.error('Error occurred reading the blob as Base64', error);
          reject(error);
        };

        reader.readAsDataURL(blob);
      }
    } else {
      resolve({ photo: '', linkedInPhoto: '' });
    }
  });
}

function extractContactName() {
  let regex = /(\(\d.*\) )?(.*) \| LinkedIn/;
  let groups = regex.exec(document.title);
  const fullName = groups && groups[groups.length - 1];

  let split = fullName && fullName.trim().split(' ');
  let vorname = '';
  let name = '';
  if (split && split.length > 0) {
    vorname = split.slice(0, split.length - 1).join(' ');
    split.length > 1 && (name = split[split.length - 1]);
  }

  return {
    firstname: vorname,
    lastname: name,
  };
}

function extractAccountName() {
  const h1 = document.querySelector('h1[title]');
  if (h1) {
    return {
      name: h1.title,
    };
  }

  return {
    name: '',
  };
}

function extractQualifications(result) {
  const CONTACT_QUALIFICATIONS = '.pv-accomplishments-block__title';
  const CONTACT_SPRACHEN = '.pv-accomplishments-block__summary-list-item';
  const CONTACT_QUALIFICATIONS_SUMMARY =
    '.pv-accomplishments-block__summary-list-item';

  let qualifikationenUndAuszeichnungen = document.getElementsByClassName(
    CONTACT_QUALIFICATIONS,
  );
  if (
    qualifikationenUndAuszeichnungen &&
    qualifikationenUndAuszeichnungen.length > 0
  ) {
    for (let i = 0; i < qualifikationenUndAuszeichnungen.length; i++) {
      if (qualifikationenUndAuszeichnungen[i].innerText === 'Sprachen') {
        let sprachen =
          qualifikationenUndAuszeichnungen[i].parentNode.getElementsByClassName(
            CONTACT_SPRACHEN,
          );
        let sprachkenntnisse = [];
        for (let i = 0; i < sprachen.length; i++) {
          let sprache = sprachen[i].innerText;
          if (sprache === 'English') {
            sprache = 'Englisch';
          } else if (sprache === 'German') {
            sprache = 'Deutsch';
          } else if (sprache === 'French') {
            sprache = 'Französisch';
          } else if (sprache === 'Russian') {
            sprache = 'Russisch';
          }

          sprachkenntnisse.push({ name: sprachen[i].innerText });
        }
        result['sprachen'] = sprachkenntnisse;
      } else {
        let skillsNodes = qualifikationenUndAuszeichnungen[
          i
        ].parentNode.querySelectorAll(CONTACT_QUALIFICATIONS_SUMMARY);
        for (let i = 0; i < skillsNodes.length; i++) {
          let skillName = skillsNodes[i].innerText;
          skillName = skillName ? skillName.trim() : skillName;
          skills.push({ name: skillName });
        }
      }
    }
  }
}

function extractPosition() {
  let position = '';
  const CONTACT_POSITION = '[data-generated-suggestion-target]';
  var positionElem = document.querySelector(CONTACT_POSITION);
  if (positionElem && positionElem.innerText) {
    position = positionElem.innerText.trim();
  }
  return { position };
}

function extractCompanyName() {
  let candidateCompany = '';
  //TODO: other languages
  // Selector targets buttons with an aria-label containing "Aktuelles Unternehmen:"
  const CONTACT_FIRMA_SELECTOR = "button[aria-label*='Aktuelles Unternehmen:']";
  let firmaButton = document.querySelector(CONTACT_FIRMA_SELECTOR);
  if (firmaButton) {
    // Extracting the company name from the aria-label
    let ariaLabel = firmaButton.getAttribute('aria-label');
    // Assuming the company name is always followed by "Aktuelles Unternehmen: " and ends before the first period.
    let companyNameStartIndex =
      ariaLabel.indexOf('Aktuelles Unternehmen: ') +
      'Aktuelles Unternehmen: '.length;
    let companyNameEndIndex = ariaLabel.indexOf('.', companyNameStartIndex);
    candidateCompany = ariaLabel.substring(
      companyNameStartIndex,
      companyNameEndIndex,
    );
  }
  return { candidateCompany };
}

function extractSummary() {
  const CONTACT_SUMMARY = '.pv-about__summary-text > span';
  let summary = document.querySelectorAll(CONTACT_SUMMARY);
  let schwerpunkt = '';
  if (summary) {
    summary.forEach(elem => {
      schwerpunkt += elem.textContent.trim() + '\n';
    });
  }
  return { schwerpunkt };
}

function extractSkills(result) {
  let skills = [];
  const CONTACT_SKILLS =
    '#skill-categories-expanded .pv-skill-category-entity__name';

  var skillsNodes = document.querySelectorAll(CONTACT_SKILLS);
  if (skillsNodes && skillsNodes.length > 0) {
    for (let i = 0; i < skillsNodes.length; i++) {
      var skillName = skillsNodes[i].textContent;
      skillName = skillName ? skillName.trim() : skillName;
      skills.push({ name: skillName });
    }
  }
  result['skills'] = skills;
}

function extractTopSkills(result) {
  const CONTACT_TOP_SKILLS =
    '.pv-skill-categories-section__top-skills .pv-skill-category-entity__name';

  let topSkills = [];
  let topSkills1 = document.querySelectorAll(CONTACT_TOP_SKILLS);
  if (topSkills1 && topSkills1.length > 0) {
    for (let i = 0; i < topSkills1.length; i++) {
      topSkills.push(
        topSkills1[i].textContent ? topSkills1[i].textContent.trim() : '',
      );
    }
  }
  result['topSkills'] = topSkills;
}

function extractLebenslauf() {
  let lebenslauf = '';
  const lebenslaufLinkedIn = [];

  let sections = document.querySelectorAll('section');
  let lebenslaufElem = Array.from(sections).filter(section => {
    return Array.from(section.querySelectorAll('span')).some(
      span => span.textContent.trim() === 'Berufserfahrung',
    );
  });

  if (lebenslaufElem && lebenslaufElem.length > 0) {
    // lebenslauf
    const stations = Array.from(
      lebenslaufElem[0].querySelectorAll('ul li'),
    ).map(li => li.querySelectorAll('span[aria-hidden="true"]'));

    if (stations) {
      stations.forEach(station => {
        if (station.length > 1) {
          const title = station[0].innerText || '';
          const company = station[1].innerText || '';
          const dates = (station.length > 2 &&
            station[2].innerText.split('–')) || ['n/a', 'n/a'];
          const start = parseDate(dates[0]);
          const end = parseDate(dates[1] ? dates[1] : dates[0]);
          const location = (station.length > 3 && station[3].innerText) || '';
          lebenslauf += `${company} | ${title} | ${location} | ${
            (station.length > 2 && station[2].innerText) || ''
          }\n\n`;
          lebenslaufLinkedIn.push({
            name: title,
            employer: company,
            start: start,
            end: end,
            location,
          });
        }
      });
    }
  }

  return { lebenslauf, lebenslaufLinkedIn };
}

function linkedInClickOnLoadMoreButtons() {
  const CONTACT_MORE_INFO = '#top-card-text-details-contact-info';
  const CONTACT_MORE_JOBS = '.pv-profile-section__see-more-inline';
  const CONTACT_MORE_WEITERE = '.pv-skills-section__additional-skills';
  const CONTACT_BTN =
    '.a.ember-view.link-without-visited-state.cursor-pointer.text-heading-small';

  let moreInfoBtn = document.querySelector(CONTACT_MORE_INFO);
  if (moreInfoBtn) {
    moreInfoBtn.click();
  }
  const modal = document.querySelector('#artdeco-modal-outlet');
  if (modal) {
    modal.style.position = 'fixed';
    modal.style.opacity = '0';
  }

  let moreJobsBtn = document.querySelector(CONTACT_MORE_JOBS);
  if (
    moreJobsBtn &&
    moreJobsBtn.length > 0 &&
    moreJobsBtn[0].getAttribute('aria-expanded') === 'false'
  ) {
    moreJobsBtn[0].click();
  }

  let btnWeitere = document.querySelector(CONTACT_MORE_WEITERE);
  if (btnWeitere && btnWeitere.getAttribute('aria-expanded') === 'false') {
    btnWeitere.click();
  }

  let kontaktBtn = document.querySelector(CONTACT_BTN);
  if (kontaktBtn && kontaktBtn.children) {
    kontaktBtn.click();
  }

  if (modal) {
    setTimeout(() => {
      modal.style.position = 'absolute';
      modal.style.opacity = '1';
    }, 2000);
  }
}
function closePopup() {
  const popup = document.querySelector('button[aria-label="Verwerfen"]');
  if (popup) {
    popup.click();
  }
  document.scrollingElement.scrollTop = 0;
}

function scrollToSmoothly(pos, time) {
  /*Time is exact amount of time the scrolling will take (in milliseconds)*/
  /*Pos is the y-position to scroll to (in pixels)*/
  /*Code written by hev1*/
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    console.warn('Position must be a number or a numeric String.');
    throw 'Position must be a number';
  }
  if (pos < 0 || time < 0) {
    return;
  }
  var currentPos = window.scrollY || window.screenTop;
  var start = null;
  time = time || 500;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      var progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      var progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}

function parseDate(datum) {
  datum = datum.trim();
  if (!datum) {
    return null;
  } else if (datum === 'Heute' || datum === 'heute') {
    return null;
  } else if (datum.indexOf('/') >= 0) {
    const pos = datum.indexOf('/');
    const month = datum.substring(0, pos);
    const year = datum.substring(pos + 1);
    return new Date(year, parseInt(month) - 1);
  } else if (datum.length === 4) {
    return new Date('1.1.' + datum);
  } else if (datum.startsWith('seit ')) {
    return parseDate(datum.substring(5, datum.length));
  } else {
    const monthsName = [
      'Jan.',
      'Feb.',
      'März',
      'Apr.',
      'Mai',
      'Juni',
      'Juli',
      'Aug.',
      'Sep.',
      'Sept.',
      'Okt.',
      'Nov.',
      'Dez.',
    ];
    const monthsPos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 9, 10, 11, 12];
    const dates = datum.split(' ');
    return new Date(monthsPos[monthsName.indexOf(dates[0])] + '.1.' + dates[1]);
  }
}
export { hantaLinkedinParser, parseLinkedIn };
