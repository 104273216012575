<script lang="ts">
  import { onMount } from 'svelte';
  import HantaInputMultiSelectLean from './../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { Button } from '$lib/components/ui/button';
  import { getItems } from '$lib/api/queries';

  export let hideLabel = false;
  export let label = 'User';
  export let value = undefined;
  export let onChange = undefined;
  export let multiple = false;
  export let readOnly = false;
  onMount(async () => {
    console.debug('users selector', value);
  });

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,photo',
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label={hideLabel ? '' : label}
  {loadOptions}
  {multiple}
  onSelectChanged={value => onChange && onChange(value)}
  placeholder="Select user..."
  {readOnly}
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.photo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.photo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 w-full text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>

<Button class="hidden" on:click={() => console.debug(value)} variant="link"
  >Assigne to me</Button
>
