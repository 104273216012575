<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  export let communcation;
</script>

{#if communcation?.values}
  <Button
    variant="ghost"
    size="icon"
    href={communcation.values}
    class="text-primary"
    target={'_blank'}
  >
    <Icon
      icon="bxs:badge-check"
      class="text-teal-600 dark:text-teal-300 size-5"
    />
  </Button>
{/if}
