<script lang="ts">
  import { appStore } from '$lib/app-store';
  import { cn } from '$lib/utils';
  import Dashboard from '$lib/pages/dashboard/dashboard.svelte';
  import Timelogs from '$lib/pages/timelogs/timelogs.svelte';
  import InvoicesOverall from '../pages/invoices/overall/invoices-overall.svelte';

  export let module: string;
</script>

<div class={cn($appStore.isCollapsed ? 'ml-8' : 'ml-[170px]')}>
  {#if module === 'timelogs'}
    <div
      class={cn(
        $appStore.isMobile ? 'max-w-md' : 'w-full fixed pl-16 pt-14 h-1/2',
      )}
    >
      <Timelogs />
    </div>
  {:else if module === 'dashboard'}
    <div
      class={cn($appStore.isMobile ? 'max-w-md' : 'fixed pl-16 pt-14 h-1/2')}
    >
      <Dashboard />
    </div>
  {:else if module === 'finance'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {:else if module === 'crm'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {:else if module === 'invoices'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {/if}
</div>
