<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import Label from '$lib/components/ui/label/label.svelte';
  import VolumeCalculator from './volume-calculator.svelte';

  export let form;
  export let readOnly = false;
  let { form: formData, enhance, errors } = form;

  export let billedWeight;

  $: volumeFactor = $formData.shipment?.product === 'ECO' ? 250 : 200;
  $: weight = round(
    ($formData.shipment?.weightUnit === 'kg'
      ? $formData.shipment?.weightPh
      : $formData.shipment?.weightPh * 0.453592) ?? 0.0,
  );

  $: packagesVolume = $formData.shipment?.packages?.reduce((acc, pkg) => {
    return (
      acc + (pkg.plength * pkg.pwidth * pkg.pheight * pkg.pquantity) / 1000000
    );
  }, 0);

  $: volume =
    $formData.shipment?.packages?.length > 0
      ? packagesVolume
      : round(
          ($formData.shipment?.volumeUnit === 'm3'
            ? $formData.shipment?.volume
            : $formData.shipment?.volume / 61023.744) ?? 0.0,
          4,
        );

  $: volumeKg = round(volume * volumeFactor, 2);

  $: billedWeight = Math.max(weight, volumeKg);
  $: billedWeightDescription =
    volumeKg > weight ? 'Volume Weight' : 'Physical Weight';

  function round(value, decimals = 2) {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
</script>

<div class="p-6 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Icon icon="mdi:weight" class="mr-2" />
    Weight & Volume
  </h2>
  {#if $formData.shipment}
    <div class="space-y-4">
      <div class="grid grid-cols-4 gap-4">
        <div class="flex col-span-2 items-center space-x-4 w-48">
          <div>
            <HantaSelect
              {readOnly}
              class="w-16"
              label="Unit"
              searchable={false}
              bind:value={$formData.shipment.weightUnit}
              options={[
                { label: 'kg', value: 'kg' },
                { label: 'lb', value: 'lb' },
              ]}
            />
          </div>
          <HantaInputNumber
            {form}
            {readOnly}
            name={'shipment.weightPh'}
            label="Physical Weight"
            asFloat={true}
          />
        </div>

        {#if $formData.shipment.weightUnit !== 'kg'}
          <div>
            <Label>Weight</Label>
            <div class="text-base font-semibold">{weight} kg</div>
          </div>
        {/if}
      </div>
      <div class="space-y-4">
        {#if !$formData.shipment.packages?.length}
          <div class="grid grid-cols-4 gap-4">
            <div class="flex col-span-2 items-center space-x-4 w-48">
              <div>
                <HantaSelect
                  {readOnly}
                  class="w-16"
                  label="Unit"
                  searchable={false}
                  bind:value={$formData.shipment.volumeUnit}
                  options={[
                    { label: 'm3', value: 'm3' },
                    { label: 'in3', value: 'in3' },
                  ]}
                />
              </div>
              <HantaInputNumber
                {form}
                {readOnly}
                name={'shipment.volume'}
                label="Volume"
                asFloat={true}
              />
            </div>

            {#if $formData.shipment.volumeUnit !== 'm3'}
              <div>
                <Label>Volume</Label>
                <div class="text-base font-semibold">{volume} m3</div>
              </div>
            {/if}

            <div>
              <Label>Volume in kg</Label>
              <div class="text-base font-semibold">{volumeKg} kg</div>
            </div>
          </div>
        {/if}
        <VolumeCalculator
          bind:packages={$formData.shipment.packages}
          {volumeFactor}
          {readOnly}
        />

        <div class="flex justify-start items-baseline text-xl">
          <div class="text-gray-600">
            Billed Weight:
            <div class="text-xs font-semibold lowercase text-muted-foreground">
              {billedWeightDescription}
            </div>
          </div>

          <span class="ml-2 font-semibold text-gray-800">{billedWeight} kg</span
          >
        </div>
      </div>
    </div>
  {/if}
</div>
