<script lang="ts">
  import { Badge } from '$lib/components/ui/badge';

  export let status;
</script>

<Badge
  variant={status === 'Cancelled' ? 'destructive' : 'default'}
  class="ml-2 {status === 'Paid' ? 'bg-green-600' : ''}"
>
  {status}
</Badge>
