<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import Icon from '@iconify/svelte';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';
  import ActivityRefsPopover from '../activity-refs-popover.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { getItems } from '$lib/api/queries';
  import { formatRoleName, isCandidate } from '$lib/app/utils.js';
  import { onMount, tick } from 'svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';
  import File from '$lib/components/hanta/files/file.svelte';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,photo,role',
    });

    return result.data;
  };

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name',
    });
    return result.data;
  };

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }
      saveActivity($formData);
      appStore.closeActivity();
    },
  });

  let { errors, form: formData } = form;

  const onContactsChanged = value => {
    const candidates = [];
    const contacts = [];
    value.forEach(v => {
      if (isCandidate(v.role)) {
        candidates.push(v);
      } else {
        contacts.push(v);
      }
    });

    formData.update(data => ({
      ...data,
      candidates: candidates,
      contacts: contacts,
    }));
  };

  onMount(() => {
    formData.update(data => ({
      ...data,
      allContacts: [...data.contacts, ...data.candidates],
    }));
  });

  const refsChanged = async value => {
    const allContacts = [
      ...new Map(
        [...$formData.contacts, ...$formData.candidates].map(item => [
          item.id,
          item,
        ]),
      ).values(),
    ];

    await tick();

    formData.update(data => ({
      ...data,
      allContacts,
    }));
  };
</script>

<Popup {form} minHeight={700}>
  <svelte:fragment slot="header">
    <Icon icon="mdi:envelope" class="mr-4 w-5 h-5" />
    Mail
  </svelte:fragment>
  <div class="flex overflow-hidden flex-col py-0 mt-4 h-full" slot="content">
    <div class="flex px-6 min-w-0 max-w-full">
      <HantaInputMultiSelect
        class="w-full min-w-0"
        {form}
        label="Contacted"
        loadOptions={loadOptionsContacts}
        multiple={true}
        name="allContacts"
        onSelectChanged={onContactsChanged}
      >
        <div
          class="flex gap-1.5 items-center w-full h-full content-stretch"
          let:index
          let:item
          slot="item"
        >
          <div class="w-full">
            <CrmAvatar id={item.id} module="contacts" name={item.name} />
          </div>
          <Badge class="text-xs font-light" variant="secondary"
            >{formatRoleName(item.role)}</Badge
          >
        </div>
        <div
          class="flex overflow-hidden gap-1.5 items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <CrmAvatar
            id={selection.id}
            module="contacts"
            name={selection.name}
          />
          <span class="text-xs font-light text-muted-foreground"
            >{formatRoleName(selection.role)}</span
          >
        </div>
      </HantaInputMultiSelect>
    </div>
    <div class="flex flex-col px-6 w-full md:gap-6 md:flex-row">
      <HantaInputDate {form} label="Date" name="startDate" withTime={true} />
      <HantaInputMultiSelect
        asArray={false}
        class="w-full"
        {form}
        label="Consultant"
        {loadOptions}
        multiple={false}
        name="consultantObj"
        placeholder="Choose consultant..."
      >
        <div
          class="flex gap-1.5 items-center w-full h-full"
          let:index
          let:item
          slot="item"
        >
          <CrmAvatar
            id={item.id}
            module="users"
            name={item.name}
            size="size-7"
          />
        </div>
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <CrmAvatar
            id={selection.id}
            module="users"
            name={selection.name}
            size="size-7"
          />
        </div>
      </HantaInputMultiSelect>
    </div>

    <hr
      class="mx-6 my-1 border-t border-solid border-t-solid border-t-primary-200/20"
    />

    <div class="overflow-scroll h-full group">
      {#if activity.hasAttachment}
        <div class="grid grid-cols-2 gap-2 mx-8 my-4">
          {#each activity.attachments ?? [] as attachment}
            <File
              file={attachment}
              folder={`activities/${activity?.id}`}
              compact={true}
            />
          {/each}
        </div>
      {/if}
      <HantaInputTextareaRichtext
        class="w-full h-full"
        {form}
        name="description"
        placeholder="Notes..."
        resizable={false}
        variant="ghost"
      />
    </div>
    <!--
    <HantaInputTextarea
      class="w-full h-full"
      {form}
      name="description"
      placeholder="Notes..."
      resizable={false}
      variant="ghost"
    />
    -->

    <div
      class="flex flex-row items-center mx-6 space-x-4 border-t border-solid border-t-primary-200/20"
    >
      <ActivityRefsPopover {activity} {form} onSelectChanged={refsChanged} />
    </div>
  </div>
</Popup>
