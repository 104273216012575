import { type PriceRequest } from './prices';

export class DiscountCalculator {
  public calculateDiscounts(item: PriceRequest): void {
    this.calculateBaseDiscounts(item);
    this.calculateManualDiscounts(item);
    this.calculateFinalAmounts(item);
  }

  private calculateBaseDiscounts(item: PriceRequest): void {
    item.discountEur = item.price - item.customerPrice;
    if (item.price > 0) {
      item.discountPercents = (item.discountEur / item.price) * 100;
    }
    item.discountTotalEur = item.discountEur;
    item.discountTotalPercents = item.discountPercents;
  }

  private calculateManualDiscounts(item: PriceRequest): void {
    if (!isNaN(item.manualDiscount) && item.manualDiscount !== 0) {
      item.manualDiscountEur = (item.manualDiscount * item.price) / 100;
      item.discountTotalEur = item.discountEur + item.manualDiscountEur;
      item.discountTotalPercents = item.manualDiscount + item.discountPercents;
    }
  }

  private calculateFinalAmounts(item: PriceRequest): void {
    item.eur = item.price - item.discountTotalEur;
    item.mdl = Math.round(item.eur * item.mdl2eur);

    item.revenueEur = item.eur - item.supplierEur;
    if (item.price > 0) {
      item.revenuePercents = item.revenueEur / item.price;
    }
  }
}
