<script lang="ts">
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';
  import { Input, type Variant } from '$lib/components/ui/input/index';
  import Label from '$lib/components/ui/label/label.svelte';
  import { cn } from '$lib/utils';
  import Icon from '@iconify/svelte';

  export let value;
  export let icon: any;

  export let variant: Variant = 'hanta';
  export let label = undefined;
  export let placeholder = undefined;
  export let description = '';
  export let readOnly = false;
  export let isLink = false;
  export let hideLabel = false;

  type $$Props = {
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    variant?: Variant;
    readOnly?: boolean;
    isLink?: boolean;
    icon?: any;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

{#if label && !hideLabel}
  <Label class="block mb-2">
    <HantaIcon {icon} />

    {label}
    {#if isLink && value}
      <a href={value} class="ml-2" target="_blank">
        <Icon icon="mdi:square-arrow-out-up-right" class="inline size-4" />
      </a>
    {/if}
  </Label>
{/if}
{#if readOnly}
  <div
    class="inline-flex items-center p-0 w-full h-7 text-sm font-medium whitespace-nowrap bg-transparent rounded-md border-none ring-0 transition-colors outline-none items-left ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-muted hover:outline-none hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-b focus:bg-secondary"
  >
    {value ?? ''}
  </div>
{:else}
  <Input bind:value class={cn('w-full', className)} {variant} {placeholder} />
{/if}
