<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import HantaInputSelect from './hanta-input-select.svelte';

  export let form;
  export let name;
  export let types = [
    'email',
    'secondaryEmail',
    'mobile',
    'phone',
    'fax',
    'web',
    'xing',
    'whatsApp',
    'skype',
    'twitter',
    'facebook',
    'values',
  ];

  const { form: formData } = form;

  const getLabel = type => {
    if (type === 'secondaryEmail') {
      return 'Secondary email';
    } else {
      return type;
    }
  };

  const genericTypes = [
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'fax', label: 'Fax' },
    { value: 'web', label: 'Website' },
    { value: 'other', label: 'Other' },
  ];

  function addgeneric() {
    if (!$formData[name].generic) {
      $formData[name].generic = [];
    }
    $formData[name].generic = [
      ...$formData[name].generic,
      { type: 'email', value: '' },
    ];
  }

  function removegeneric(index) {
    $formData[name].generic = $formData[name].generic.filter(
      (_, i) => i !== index,
    );
  }

  function handleTypeChange(index, selectedValue) {
    $formData[name].generic[index].type = selectedValue;
    if (selectedValue !== 'other') {
      $formData[name].generic[index].customType = '';
    }
  }
</script>

{#if $formData[name]}
  {#each types || [] as type}
    <Form.Field class="space-y-1" {form} name={`${name}.${type}`}>
      <Form.Control let:attrs>
        <Form.Label class="capitalize">{getLabel(type)}</Form.Label>
        <Input
          {...attrs}
          bind:value={$formData[name][type]}
          class="w-full"
          variant="hanta"
        />
      </Form.Control>
      <Form.FieldErrors />
    </Form.Field>
  {/each}

  <div class="mt-6">
    <h3 class="mb-2 text-xs font-semibold">Additional Contact Details</h3>
    {#if $formData[name].generic}
      {#each $formData[name].generic as _, index}
        <div class="flex items-center mb-2 space-x-2">
          <Form.Field
            class="w-32"
            {form}
            name={`${name}.generic.${index}.type`}
          >
            <Form.Control let:attrs>
              <HantaInputSelect
                class="w-24"
                {form}
                name="[{name}].generic[{index}].type"
                options={genericTypes}
              />
            </Form.Control>
          </Form.Field>

          {#if $formData[name].generic[index].type === 'other'}
            <Form.Field
              class="flex-1"
              {form}
              name={`generic.${index}.customType`}
            >
              <Form.Control let:attrs>
                <Input
                  {...attrs}
                  bind:value={$formData[name].generic[index].customType}
                  placeholder="Custom type"
                  class="w-full"
                  variant="hanta"
                />
              </Form.Control>
            </Form.Field>
          {/if}
          <Form.Field
            class="flex-1"
            {form}
            name={`${name}.generic.${index}.value`}
          >
            <Form.Control let:attrs>
              <Input
                {...attrs}
                bind:value={$formData[name].generic[index].value}
                placeholder="Value"
                class="w-full"
                variant="hanta"
              />
            </Form.Control>
          </Form.Field>
          <Button
            variant="ghost"
            class="shrink-0"
            on:click={() => removegeneric(index)}
          >
            <Icon icon="mdi:trash" class="size-4" />
          </Button>
        </div>
      {/each}
    {/if}
    <Button variant="outline" class="mt-2" on:click={addgeneric}>
      <Icon icon="mdi:plus" class="mr-2 w-4 h-4" /> Add Contact Detail
    </Button>
  </div>
{/if}
