<script lang="ts">
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/app-store';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import Icon from '@iconify/svelte';
  import { onMount } from 'svelte';

  let buttonPosition: number;

  onMount(() => {
    const updatePosition = () => {
      buttonPosition = window.innerHeight * (2 / 3);
    };
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  });

  function toggleCollapse() {
    appStore.toggleCollapse();
  }

  $: sidebarWidth = $appStore.isCollapsed ? '60px' : '197px';
</script>

<div
  class="fixed inset-y-0 transition-all duration-300 ease-in-out pointer-events-none"
  style="width: {sidebarWidth}"
>
  <div
    class="absolute inset-y-0 right-0 w-px bg-gray-200 dark:bg-gray-700"
  ></div>

  <div class="absolute right-0 bottom-2 z-[10000]">
    <Tooltip.Root>
      <Tooltip.Trigger asChild let:builder>
        <Button
          variant="ghost"
          builders={[builder]}
          on:click={toggleCollapse}
          class={cn(
            'px-1 py-4 -mr-4 text-xs font-medium transition-all duration-300 ease-in-out pointer-events-auto bg-secondary toggle-button',
            $appStore.isCollapsed ? '-mr-3' : ' -mr-3',
          )}
        >
          <span class="flex items-center">
            {#if $appStore.isCollapsed}
              <Icon
                icon="mdi:arrow-collapse-right"
                class="transition-transform duration-300 size-3"
              />
            {:else}
              <Icon
                icon="mdi:arrow-collapse-left"
                class="transition-transform duration-300 size-3"
              />
            {/if}
          </span>
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content side="right">
        {#if $appStore.isCollapsed}
          Expand
        {:else}
          Collapse
        {/if}
      </Tooltip.Content>
    </Tooltip.Root>
  </div>
</div>

<style>
  .toggle-button {
    transform: translateX(50%);
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
  }

  .toggle-button:hover {
    transform: translateX(50%) translateY(-1px);
  }

  .toggle-button:hover :global(svg) {
    transform: scale(1.2);
  }

  .toggle-button:active {
    transform: translateX(50%) translateY(0px);
  }
</style>
