<script lang="ts">
  import HantaInputMultiSelectLean from '../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';

  export let accountId = undefined;
  export let value = undefined;
  export let readOnly = false;

  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const filters = [];
    if (accountId) {
      filters.push({
        field: 'customer',
        value: accountId,
        operator: 'eq',
        active: true,
      });
    }

    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,photo',
      filters: filters,
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label="Contact"
  loadOptions={loadOptionsContacts}
  multiple={false}
  placeholder="Select contact..."
  {readOnly}
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.photo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.photo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 w-full text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>
