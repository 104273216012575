<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { cn } from '$lib/utils';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';

  import Icon from '@iconify/svelte';
  import { fields as fieldsDef } from '$lib/config';

  const dispatch = createEventDispatcher();
  export let collection = 'accounts';

  $: fields = fieldsDef[collection]?.filter(el => el.sortable);

  let sortBy;

  // reset to default direction when changing the sort field
  $: if (sortBy) {
    direction = 'asc';
  }

  let direction: 'asc' | 'desc' | undefined;

  const changeDirection = () => {
    if (direction === 'desc') {
      sortBy = undefined;
    }
    direction = direction === 'asc' ? 'desc' : 'asc';
  };

  $: direction !== undefined
    ? dispatch('sort', { column: sortBy, order: direction })
    : dispatch('sort', undefined);
</script>

<div class={cn('flex justify-end')}>
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        variant="outline"
        builders={[builder]}
        class="flex items-center px-2 py-1 space-x-2 max-w-[100px]"
      >
        <Icon icon="mdi:filter-variant" class="size-4" />
        <span class="w-full text-xs truncate"
          >{sortBy
            ? `by ${fields.find(el => el.value === sortBy)?.label}`
            : 'Sort'}</span
        >
      </Button>
    </DropdownMenu.Trigger>

    <DropdownMenu.Content class="w-56">
      <DropdownMenu.Label>Sort by</DropdownMenu.Label>
      <DropdownMenu.Separator />
      <DropdownMenu.RadioGroup bind:value={sortBy}>
        {#each fields || [] as field}
          <DropdownMenu.RadioItem bind:value={field.value}
            >{field.label}</DropdownMenu.RadioItem
          >
        {/each}
      </DropdownMenu.RadioGroup>
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  {#if sortBy}
    <Button size="icon" variant="ghost" on:click={changeDirection}>
      <Icon
        icon={direction === 'asc'
          ? 'mdi:sort-ascending'
          : 'mdi:sort-descending'}
        class="size-4"
      />
    </Button>
  {/if}
</div>
