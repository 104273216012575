<script>
  import { onDestroy, onMount } from 'svelte';

  import { appStore } from '$lib/app-store';
  import { dark, light } from '@clerk/themes';

  let organizationComponent;
  let organizationListComponent;
  import { clerkStore } from './clerk-store';

  let isLightTheme = true;

  onMount(async () => {
    clerkStore.subscribe(({ clerk }) => {
      clerk?.mountOrganizationSwitcher(organizationComponent, {
        afterSelectOrganizationUrl: '/',
        appearance: {
          baseTheme: isLightTheme ? light : dark,
        },
      });
    });
    // $authStore?.clerk?.mountOrganizationList(organizationListComponent, {});
  });

  onDestroy(() => {
    clerkStore.subscribe(({ clerk }) => {
      clerk?.unmountOrganizationSwitcher(organizationComponent);
      clerk?.unmountOrganizationList(organizationListComponent);
    });
  });

  $: if (organizationComponent) {
    const style = document.createElement('style');
    style.textContent = $appStore.isCollapsed
      ? '.cl-organizationPreviewTextContainer__organizationSwitcherTrigger { display: none; }'
      : '.cl-organizationPreviewTextContainer__organizationSwitcherTrigger { display: block; }';
    organizationComponent.appendChild(style);
  }
</script>

<div
  class="md:fixed md:-top-2 md:left-0 md:bg-background z-[99999] pl-1 pt-1 md:dark:!text-white"
>
  <div class="org-menu">
    <div id="organizations" bind:this={organizationComponent} />

    <div
      id="organizationList"
      bind:this={organizationListComponent}
      class="hidden"
    />
  </div>
</div>

<style>
  .org-menu {
    z-index: 99999;
  }

  :global(#global-nav) {
    z-index: 10;
  }
</style>
