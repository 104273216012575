<script lang="ts">
  import { RadioGroup as RadioGroupPrimitive } from 'bits-ui';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils';

  type $$Props = RadioGroupPrimitive.ItemProps;
  type $$Events = RadioGroupPrimitive.ItemEvents;

  let className: $$Props['class'] = undefined;
  export let value: $$Props['value'];
  export { className as class };
</script>

<RadioGroupPrimitive.Item
  {value}
  class={cn(
    'aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    className,
  )}
  {...$$restProps}
  on:click
>
  <div class="flex justify-center items-center">
    <RadioGroupPrimitive.ItemIndicator>
      <Icon icon="mdi:circle" class="fill-current size-2" />
    </RadioGroupPrimitive.ItemIndicator>
  </div>
</RadioGroupPrimitive.Item>
