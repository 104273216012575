<script lang="ts">
  import { onMount } from 'svelte';
  import HantaInputMultiSelectLean from './../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { getItemById, getItems } from '$lib/api/queries';

  export let initialValue = undefined;
  export let hideLabel = false;
  export let value = undefined;
  export let onChange = undefined;
  export let readOnly = false;

  const select =
    'id,name,logo, creditRating,debtReason,currentBalance,maxDebt,currentCreditLimit,baseTariff:tariffs!baseTariff(id, name),customerTariff:tariffs!customerTariff(id, name),supplierTariff:tariffs!supplierTariff(id, name)';

  onMount(() => {
    if (initialValue) {
      getItemById('accounts', initialValue).then(item => {
        value = item;
      });
    }
  });

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 25,
      search: keyword,
      select,
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label={hideLabel ? '' : 'Account'}
  loadOptions={loadOptionsAccounts}
  multiple={false}
  onSelectChanged={value => onChange && onChange(value)}
  placeholder="Select account..."
  {readOnly}
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.logo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.logo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 w-full text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>
