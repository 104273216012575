<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    ActivityType,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';

  import Icon from '@iconify/svelte';

  import { appStore } from '$lib/app-store';

  import ActivityRefsPopover from '../activity-refs-popover.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      saveActivity($formData);
      appStore.closeActivity();
    },
  });

  let { errors, form: formData } = form;
</script>

<Popup {form}>
  <svelte:fragment slot="header">
    <Icon icon="mdi:notepad-text" class="mr-4 size-5" />
    Note
  </svelte:fragment>
  <div class="flex overflow-scroll flex-col py-0 mt-4 h-96" slot="content">
    <HantaInputTextareaRichtext
      {form}
      variant="ghost"
      name="description"
      placeholder="Start typing to leave a note"
      resizable={false}
    />

    <div
      class="flex flex-row items-center mx-6 space-x-4 border-t border-solid border-t-primary-200/20"
    >
      <ActivityRefsPopover {activity} {form} />
    </div>
  </div>
</Popup>
