import { NumberUtils } from './utils/NumberUtils';
import { type PriceRequest } from './prices';

export class TotalsCalculator {
  public calculateTotals(item: PriceRequest, withMdl: boolean): void {
    this.calculateEurTotals(item);
    if (withMdl) {
      this.calculateMdlTotals(item);
    }
    this.calculateRevenueTotals(item);
  }

  private calculateEurTotals(item: PriceRequest): void {
    item.totalEUR = item.services.reduce(
      (total: number, service: any) => total + (service.eur || 0),
      item.eur || 0,
    );
  }

  private calculateMdlTotals(item: PriceRequest): void {
    item.totalMDL = item.services.reduce(
      (total: number, service: any) => total + (service.mdl || 0),
      item.mdl || 0,
    );
  }

  private calculateRevenueTotals(item: PriceRequest): void {
    item.revenueTotalEur = NumberUtils.roundValue(
      (item.revenueEur || 0) +
        item.services.reduce(
          (total: number, el: any) => total + (el.revenueEur || 0),
          0,
        ),
    );

    item.supplierTotalEur = NumberUtils.roundValue(
      (item.supplierEur || 0) +
        item.services.reduce(
          (total: number, el: any) => total + (el.supplierEur || 0),
          0,
        ),
    );
  }
}
