<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { restoreItem, deleteItemPermanently } from '$lib/api/trash-api';
  import { formatDistanceToNow } from 'date-fns';
  import Icon from '@iconify/svelte';
  import { saveFavorite, deleteFavorite } from '$lib/api/mutations';
  import { getFavorites } from '$lib/api/queries';
  import { get } from 'svelte/store';
  import CrmDeleteObject from '$lib/ui/crm-delete-object.svelte';
  import { currentUserStore } from '$lib/current-user-store';
  import {
    createMutation,
    createQuery,
    useQueryClient,
  } from '@tanstack/svelte-query';

  export let module;
  export let id;
  export let item;

  // Array of modules that can be favorited
  const FAVORITABLE_MODULES = ['accounts', 'deals', 'contacts', 'invoices'];

  // Check if current module can be favorited
  $: canBeFavorited = FAVORITABLE_MODULES.includes(module);

  $: field = `${module.slice(0, -1)}Id`;

  let showDeleteDialog = false;
  const queryClient = useQueryClient();

  // Query to fetch favorites using getFavorites
  const favoritesQuery = createQuery({
    queryKey: ['favorites'],
    queryFn: getFavorites,
    enabled: canBeFavorited, // Only run query if module can be favorited
  });

  // Check if current item is favorited
  $: isFavorited =
    canBeFavorited && $favoritesQuery.data?.some(fav => fav[field] === id);

  // Get favorite ID if exists
  $: favoriteId =
    canBeFavorited && $favoritesQuery.data?.find(fav => fav[field] === id)?.id;

  const saveFavoriteMutation = createMutation({
    mutationFn: async () => {
      await currentUserStore.loadUser();
      const currentUser = get(currentUserStore);
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const favoriteItem = {
        userId: currentUser.id,
        [field]: id,
      };
      return saveFavorite(favoriteItem);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] });
    },
  });

  const deleteFavoriteMutation = createMutation({
    mutationFn: async () => {
      return deleteFavorite(field, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] });
    },
  });

  const onDelete = () => {
    showDeleteDialog = true;
  };

  const toggleFavorite = () => {
    if (isFavorited) {
      $deleteFavoriteMutation.mutate();
    } else {
      $saveFavoriteMutation.mutate();
    }
  };

  const onDeleteFn = () => {
    refreshQuery();
  };

  const refreshQuery = () => {
    queryClient.invalidateQueries({ queryKey: [module, id] });
    queryClient.invalidateQueries({ queryKey: [module] });
    queryClient.invalidateQueries({ queryKey: ['trash'] });
  };

  async function onRestore() {
    await restoreItem(module, id);
    refreshQuery();
  }

  async function onDeleteItemPermanently() {
    await deleteItemPermanently(module, id);
    refreshQuery();
  }
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Button builders={[builder]} variant="link" size="sm">
          <span>Actions</span>
          <Icon
            icon="mdi:chevron-down"
            class="ml-4 w-4 h-4 text-muted-foreground"
          />
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <p>More actions</p>
      </Tooltip.Content>
    </Tooltip.Root>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content class="w-56">
    <DropdownMenu.Item
      disabled={module === 'invoices' && item?.status !== 'Draft'}
      on:click={onDelete}
    >
      <Icon icon="ph:trash-duotone" class="mr-2 w-4 h-4" />
      Delete
    </DropdownMenu.Item>
    <!-- 
      View history
      Move to trash
      Duplicate
      Notify me
      Import 
      Export
      Copy link
      View all comments
    -->
  </DropdownMenu.Content>
</DropdownMenu.Root>

<div class="flex gap-2 items-center">
  {#if canBeFavorited}
    <Tooltip.Root>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          on:click={toggleFavorite}
          variant="ghost"
          size="icon"
          disabled={$favoritesQuery.isLoading ||
            $saveFavoriteMutation.isPending ||
            $deleteFavoriteMutation.isPending}
          class="hover:bg-muted"
        >
          {#if $favoritesQuery.isLoading || $saveFavoriteMutation.isPending || $deleteFavoriteMutation.isPending}
            <Icon icon="ph:spinner-gap" class="w-4 h-4 animate-spin" />
          {:else if isFavorited}
            <Icon icon="ph:star-fill" class="w-4 h-4 text-yellow-500" />
          {:else}
            <Icon icon="ph:star-duotone" class="w-4 h-4" />
          {/if}
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <p>
          {isFavorited ? 'Remove from favorites' : 'Add to favorites'}
        </p>
      </Tooltip.Content>
    </Tooltip.Root>
  {/if}

  {#if item?.deleted}
    <div
      class="flex absolute top-0 left-0 justify-between items-center px-4 py-3 pr-12 w-full text-xl text-white bg-red-500"
    >
      <span
        >This {module?.slice(0, -1)} has been moved to Trash
        {formatDistanceToNow(new Date(item?.deletedAt))} ago</span
      >
      <div class="flex gap-2">
        <Button variant="outline" class="text-primary" on:click={onRestore}>
          <Icon icon="mdi:restore" class="mr-2 size-4" />
          Restore {module?.slice(0, -1)}
        </Button>
        <Button
          variant="outline"
          class="text-primary"
          on:click={onDeleteItemPermanently}
        >
          <Icon icon="mdi:trash-can-outline" class="mr-2 size-4" />
          Delete from Trash
        </Button>
      </div>
    </div>
  {/if}
</div>
<CrmDeleteObject bind:open={showDeleteDialog} {module} {id} {onDeleteFn} />
