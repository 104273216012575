import App from './lib/auth/app-with-auth.svelte';
import { initSentry } from './sentry';
import { debounce } from '$lib/utils/hanta-utils';

let lastUrl;

initSentry();

const debounceInit = debounce(() => {
  init();
}, 2000);

const observer = new MutationObserver(() => {
  const path = location.pathname.split('/');
  if (path.length >= 3 && path[3] === 'overlay') return;
  const url = location.protocol + '//' + location.host + location.pathname;

  // init on route change
  if (url !== lastUrl) {
    lastUrl = url;
    debounceInit();
  }
});

observer.observe(document, {
  subtree: true,
  childList: true,
});

function init() {
  const linkedinUrl = 'https://www.linkedin.com';
  const linkedInFeedUrl = `${linkedinUrl}/feed`;
  const isAccountPage = window.location.href.startsWith(
    `${linkedinUrl}/company/`,
  );
  //const isAccountPage = document.querySelector('.org-top-card');
  const linkedinFeed = window.location.href.startsWith(linkedInFeedUrl);
  const linkedInLoginUrl = window.location.href.startsWith(
    `${linkedInFeedUrl}?login=1`,
  );
  const isProfilePage = document.querySelector('#profile-content');

  if (isProfilePage || isAccountPage) {
    const target =
      document.querySelector('.pv-top-card') ||
      document.querySelector('.artdeco-card');
    mountApp(target);
  } else if (linkedInLoginUrl) {
    setTimeout(() => {
      const target = document.querySelector('main');
      mountApp(target);
    }, 1000);
  } else if (linkedinFeed) {
    setTimeout(() => {
      const target = document.querySelector('aside');
      mountApp(target, true);
    }, 1000);
  } else {
    const target = document.querySelector('.application-outlet');
    if (target) {
      mountApp(target);
    }
  }
}
let svelteApp;
const mountApp = (target, insertAsFirstChild = false) => {
  if (svelteApp) {
    svelteApp.$destroy();
  }
  const app = document.querySelector('#hanta-app');
  if (app) {
    try {
      console.debug('REMOVED APP.');
      app.remove();
    } catch (e) {
      console.error('REMOVED APP.', e);
    }
  }

  const hantaDiv = document.createElement('div');
  hantaDiv.id = 'hanta-app';
  hantaDiv.style.setProperty('z-index', '10000');

  if (insertAsFirstChild) {
    const parentDiv = target.children[0];
    target.insertBefore(hantaDiv, parentDiv);
  } else {
    target.appendChild(hantaDiv);
  }

  svelteApp = new App({
    target: hantaDiv,
  });
};
