<script lang="ts">
  import { superForm } from 'sveltekit-superforms';

  import { type Contact, insertContactsSchema } from '$db/schema';

  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveContact } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';

  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputAddress from '../input/hanta-input-address.svelte';
  import HantaInputCommunication from '../input/hanta-input-communication.svelte';
  import HantaInputTextarea from '../input/hanta-input-textarea.svelte';
  import HantaInputNumber from '../input/hanta-input-number.svelte';
  import HantaInputDate from '../input/hanta-input-date.svelte';
  import HantaInputMultiSelect from '../input/hanta-input-multi-select.svelte';
  import HantaInputLovSelect from '../input/hanta-input-lov-select.svelte';
  import { getItems } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { isContact } from '$lib/app/utils';
  import Icon from '@iconify/svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  const moduleName = 'contacts';
  export let crmContact: Contact;

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Contact> =>
      Promise.resolve(saveContact(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, crmContact.id] });
      const previousItems = client.getQueryData<Contact[]>([
        moduleName,
        crmContact.id,
      ]);

      client.setQueryData([moduleName, crmContact.id], {
        ...crmContact,
        ...newItem,
      });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, crmContact.id] });
    },
  });

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,logo',
    });

    return result.data;
  };

  const formSchema = insertContactsSchema.partial();
  let initContact: Contact = {
    ...crmContact,
    addressPrivate: crmContact?.addressPrivate ?? {},
    addressWork: crmContact?.addressWork ?? {},
    communicationPrivate: crmContact?.communicationPrivate ?? {},
    communicationWork: crmContact?.communicationWork ?? {},
  };
  const form = superForm<Contact>(initContact, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(formSchema),
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);

      let parse = insertContactsSchema.parse($formData);
      $addMutation.mutate(parse, {
        onError(error) {
          console.error('Error', error);
        },
        onSuccess: () => {},
      });
    },
  });

  let lovs = {
    Cluster: [],
    Language: [],
    Industry: [],
    Skill: [],
    Region: [],
    Salutation: [],
    PersoehnlicherEindruck: [],
    FachlicherEindruck: [],
  };

  onMount(async () => {
    const lovNames = [
      'Cluster',
      'Language',
      'Industry',
      'Skill',
      'Salutation',
      'Region',
      'PersoehnlicherEindruck',
      'FachlicherEindruck',
    ];

    for (const lov of lovNames) {
      const result = await getItems({
        collection: 'lovs',
        from: 0,
        to: 1,
        search: '',
        select: 'id,name,values',
        filters: [
          {
            field: 'name',
            operator: 'eq',
            value: lov,
            active: true,
          },
        ],
      });
      lovs[lov] = result.data
        .map(el => el.values)
        .flat()
        .map(el => el.name);
    }
  });

  let { form: formData, enhance, errors } = form;
</script>

<form class="w-full" method="POST" use:enhance>
  <div>
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
    >
      Fact Sheet
    </h2>

    <div class="flex flex-col px-4 m-2 rounded">
      {#if isContact(crmContact?.role)}
        <HantaInputMultiSelect
          asArray={false}
          class="w-full"
          {form}
          label="Account"
          loadOptions={loadOptionsAccounts}
          multiple={false}
          name="customer"
          placeholder="Select..."
        >
          <svelte:fragment slot="icon">
            <Icon icon="mdi:building" class="w-3 h-3" />
          </svelte:fragment>

          <div
            class="flex items-center w-full h-full"
            let:index
            let:item
            slot="item"
          >
            <Avatar.Root class="size-8">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={item?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm truncate">{item?.name}</div>
          </div>
          <div
            class="flex overflow-hidden items-center w-full h-full"
            let:selection
            slot="selection"
          >
            <Avatar.Root class="size-8">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={selection?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 w-full text-sm truncate">
              {selection?.name}
            </div>
          </div>
        </HantaInputMultiSelect>
      {/if}
      <!--HantaInputMultiSelect
        {form}
        name="consultant"
        label="Consultant"
        simpleMode={true}
        options={[]}
      /-->
      <HantaInputText {form} name="refId" label="Id" readOnly={true} />
      <HantaInputText {form} name="blockingNotice" label="Blocking Notice" />
      <HantaInputTextarea {form} name="internalNotes" label="Internal Notes" />

      <HantaInputMultiSelect
        {form}
        name="personalImpression"
        label="Personal Impression"
        icon="mdi:user"
        options={lovs['PersoehnlicherEindruck']}
        simpleMode={true}
        multiple={false}
      />

      <HantaInputMultiSelect
        {form}
        name="professionalImpression"
        label="Professional Impression"
        icon="mdi:briefcase"
        options={lovs['FachlicherEindruck']}
        simpleMode={true}
        multiple={false}
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.targetRegion}
        type="Region"
        label="Target Regions"
      />

      <HantaInputDate {form} name="availableFrom" label="Available from" />
      <HantaInputNumber
        {form}
        name="annualGrossTarget"
        label="Annual Gross Target"
      />
      <HantaInputText {form} name="noticePeriod" label="Notice Period" />

      <HantaInputTextarea {form} name="salaryNotes" label="Salary Notes" />
      <HantaInputNumber {form} name="hourlyRate" label="Hourly Rate" />
      <HantaInputNumber {form} name="dailyRate" label="Daily Rate" />
    </div>
  </div>

  <!-- Qualifications -->

  <div>
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
    >
      Qualifications
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <!-- -->

      <!-- cluster -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.cluster}
        type="Cluster"
        label="Tags"
        addable={true}
        icon="mdi:tags"
      />

      <!-- languageSkills -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.languageSkills}
        type="Language"
        label="Language Skills"
        addable={true}
      />

      <!-- industryKnowledge -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.industryKnowledge}
        type="Industry"
        label="Industry Knowledge"
        addable={true}
      />

      <!-- skills -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.skills}
        type="Skill"
        label="Skills"
        addable={true}
      />
    </div>
  </div>

  <!-- Personal info -->
  <div>
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
    >
      Personal Info
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputLovSelect
        {form}
        bind:value={$formData.salutation}
        type="Salutation"
        label="Salutation"
        multiple={false}
      />

      <HantaInputText {form} name="title" label="Title" />
      <HantaInputText {form} name="firstname" label="First name" />
      <HantaInputText {form} name="lastname" label="Last name" />
      <HantaInputText {form} name="position" label="Position" />

      <HantaInputText {form} name="candidateCompany" label="Company" />
      <HantaInputText {form} name="jobTitle" label="Job title" />
      <HantaInputText
        {form}
        name="positionInCompany"
        label="Position in company"
      />

      <HantaInputText {form} name="department" label="Department" />

      <HantaInputTextarea {form} name="impression" label="Impression" />
      <HantaInputTextarea {form} name="other" label="Additional information" />
      <HantaInputTextarea {form} name="focus" label="Focus" />

      <HantaInputDate {form} name="birthday" label="Date of Birth" />

      <HantaInputText {form} name="nationality" label="Nationality" />

      <!--
      <HantaInputMultiSelect
        {form}
        name="nationality"
        label="Nationality"
        multiple={false}
        options={lovs.Cluster}
        simpleMode={true}
      />-->
    </div>
  </div>

  <!-- Address -->
  <div class="mb-20">
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
    >
      Address (private)
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputAddress {form} name="addressPrivate" label="Address" />
    </div>
  </div>

  <!-- Contact -->
  <div class="mb-20">
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
    >
      Contact (private)
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputCommunication
        {form}
        name="communicationPrivate"
        label="Contact"
      />
      <HantaInputText {form} name="linkedin" label="LinkedIn" />
      <!--
      <HantaInputText {form} name="xing" label="Xing" />
      -->
    </div>
  </div>

  {#if isContact(crmContact.role)}
    <!-- Address -->
    <div class="mb-20">
      <h2
        class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
      >
        Address (work)
      </h2>
      <div class="flex flex-col px-4 m-2 rounded">
        <HantaInputAddress {form} name="addressWork" label="Address" />
      </div>
    </div>

    <!-- Contact -->
    <div class="mb-20">
      <h2
        class="sticky top-0 px-6 py-1 font-semibold border-b border-solid z-1 border-b-primary-200/20 bg-primary-50"
      >
        Contact (work)
      </h2>
      <div class="flex flex-col px-4 m-2 rounded">
        <HantaInputText {form} name="linkedin" label="LinkedIn" />
        <HantaInputCommunication
          {form}
          name="communicationWork"
          label="Contact"
        />

        <!--
        <HantaInputText {form} name="xing" label="Xing" />
        -->
      </div>
    </div>
  {/if}

  <FormActions {form} />
</form>
