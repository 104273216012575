<script lang="ts">
  import { popupStore } from '$lib/app-store';
  import * as Dialog from '$lib/components/ui/dialog/index.js';
  import HantaObjectView from './hanta-object-view.svelte';
  import HantaObjectContent from './hanta-object-content.svelte';

  $: ({ isOpen, popupType, popupData } = $popupStore);
  $: module = popupData?.module;
  $: id = popupData?.id;

  function handleClose() {
    popupStore.closePopup();
  }
</script>

<Dialog.Root
  bind:open={isOpen}
  onOpenChange={open => {
    if (!open) handleClose();
  }}
>
  <Dialog.Content
    class="sm:max-w-[90vw] sm:max-h-[90vh] h-full overflow-y-auto"
  >
    {#if popupType === 'crmObject' && module && id}
      <HantaObjectView {module} {id} isPopup={true} let:item>
        <svelte:fragment slot="header">
          <!-- Add any header content specific to the popup view -->
        </svelte:fragment>
        <svelte:fragment slot="actionBar">
          <!-- Add any action bar content specific to the popup view -->
        </svelte:fragment>

        <HantaObjectContent {module} {item} />
      </HantaObjectView>
    {/if}
  </Dialog.Content>
</Dialog.Root>

<style>
  :global(.dialog-content) {
    max-height: 90vh !important;
    height: 90vh !important;
    overflow-y: auto !important;
  }
</style>
