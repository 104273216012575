<script lang="ts">
  import Pricelist from './pricelist.svelte';
  import PricelistAddButton from './pricelist-add-button.svelte';
  import PricelistSelector from './pricelist-selector.svelte';

  export let pricelists = [];
  let selectedPricelist;
  $: pricelist =
    selectedPricelist &&
    (pricelists || []).find(el => el.name === selectedPricelist);

  function addPricelist(event) {
    const newPricelist = {
      name: event.detail.name,
      prices: [],
      dimensions: [],
    };
    pricelists = [...pricelists, newPricelist];
    selectedPricelist = newPricelist.name;
  }
</script>

<div class="relative space-y-4 w-full">
  <div class="flex space-x-4 w-full">
    <PricelistSelector
      bind:selectedPricelist
      pricelists={pricelists?.map(p => ({ label: p.name, value: p.name }))}
    />

    <PricelistAddButton on:add={addPricelist} />
  </div>
  {#if pricelist}
    <Pricelist {pricelist} />
  {/if}
</div>
