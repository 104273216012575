<script lang="ts">
  import AuthOrgButtonClerk from './clerk/auth-org-button-clerk.svelte';
  import AuthSupabaseOrgButtonSupabase from './supabase/org/auth-org-button-supabase.svelte';
</script>

{#if import.meta.env.VITE_AUTH_PROVIDER === 'clerk'}
  <AuthOrgButtonClerk />
{:else if import.meta.env.VITE_AUTH_PROVIDER === 'supabase'}
  <AuthSupabaseOrgButtonSupabase />
{/if}
