import { writable, get } from 'svelte/store';

type Package = string;

const packages = ['headhunter', 'logistics', 'timelogs', 'crm', 'freelance'];

export type AuthStoreType = {
  packages?: string[];
  user?: any;
  token?: string;
  organization?: any;
  organizations?: any[];
};

function createAuthStore() {
  const store = writable<AuthStoreType>({
    user: undefined,
    token: undefined,
    organization: undefined,
    organizations: undefined,
  });

  const { subscribe, set, update } = store;

  function getPackages(): Package[] {
    const $store = get(store);
    return $store?.packages || ['crm', 'logistics'];
  }

  function hasPackage(packageName: Package): boolean {
    const packages = getPackages();
    return packages.includes(packageName);
  }

  function isHeadhunter(): boolean {
    return hasPackage('headhunter');
  }

  function isLogistics(): boolean {
    return hasPackage('logistics');
  }

  function isTimelogs(): boolean {
    return hasPackage('timelogs');
  }

  function isCRM(): boolean {
    return hasPackage('crm');
  }

  function isFreelance(): boolean {
    return hasPackage('freelance');
  }

  return {
    subscribe,
    set,
    update,
    getPackages,
    hasPackage,
    isHeadhunter,
    isLogistics,
    isTimelogs,
    isCRM,
    isFreelance,
  };
}

export const authStore = createAuthStore();

// Usage example:
// const packages = authStore.getPackages();
