<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';
  import { cn } from '$lib/utils';
  import { intProxy } from 'sveltekit-superforms';
  import { numberProxy } from 'sveltekit-superforms';
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';

  export let form;
  export let name;
  export let label = undefined;
  export let description = undefined;
  export let asFloat = false;
  export let readOnly = false;
  export let step = 0.01;
  export let alignRight = false;
  export let icon = undefined;
  export let min = undefined;
  export let max = undefined;

  let className: $$Props['class'] = undefined;
  export { className as class };

  const { form: formData } = form;

  $: fieldProxyTarget = asFloat
    ? numberProxy(formData, name, {
        empty: 'zero',
        initiallyEmptyIfZero: true,
        delimiter: '.',
      })
    : intProxy(formData, name, {
        empty: 'zero',
        initiallyEmptyIfZero: true,
      });
</script>

<Form.Field class={cn('space-y-1', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>
        {#if icon}
          <HantaIcon {icon} />
        {/if}
        {label}
      </Form.Label>
    {/if}
    {#if readOnly}
      <span
        class="text-sm font-bold w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        >{$fieldProxyTarget}</span
      >
    {:else}
      <Input
        {...attrs}
        bind:value={$fieldProxyTarget}
        variant="hanta"
        type="number"
        {step}
        {min}
        {max}
        class={`w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${alignRight ? 'text-right' : ''}`}
      />
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>
