<script lang="ts">
  import { deleteItem } from '$lib/api/mutations';
  import * as Card from '$lib/components/ui/card';
  import ActivityHover from './activity-hover.svelte';

  import * as Accordion from '$lib/components/ui/accordion';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import { Label } from '$lib/components/ui/label';
  import ActivityRefs from './activity-refs.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { appStore } from '$lib/app-store';
  import Button from '$lib/components/ui/button/button.svelte';
  import File from '$lib/components/hanta/files/file.svelte';
  import { getActivity } from '$lib/api/queries';
  import ActivityIframe from './activity-iframe.svelte';

  export let item = undefined;
  export let module = undefined;
  export let id = undefined;
  export let compact = false;
  let open = undefined;

  const editItem = async () => {
    const assignment = await getActivity(item.id);
    appStore.openActivity(assignment);
    // appStore.openPopup(true);
  };

  const onDeleteItem = async () => {
    await deleteItem('activities', item.id);
  };

  const openItem = payload => {
    appStore.setSelectedStack([
      {
        id: id,
        module: module,
        tab: 'timeline',
      },
    ]);
    appStore.select(payload);
    appStore.openPopup(true);
  };

  const formatDate = date => {
    return new Date(date).toLocaleDateString(undefined, {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  // Function to convert newlines to <br> tags
  function convertLineBreaks(text) {
    return (text || '').split('\n').join('<br>');
  }
</script>

<div class="relative pt-0 pr-6 html-renderer group">
  <Button
    variant="ghost"
    size="sm"
    class="absolute top-0 -right-3 group-hover:block"
    on:click={editItem}
  >
    <Icon icon="mdi:pencil" class="size-3" />
  </Button>

  <Button
    variant="ghost"
    size="sm"
    class="absolute -right-3 top-8"
    on:click={onDeleteItem}
  >
    <Icon icon="mdi:trash" class="size-3" />
  </Button>

  {#if item.type === 'LinkedIn'}
    <div
      class="flex overflow-hidden items-start mb-4"
      class:justify-end={item.incommingMessage}
    >
      <div
        class={cn(
          'p-4 rounded-lg shadow-md',
          item.incommingMessage
            ? 'bg-blue-100 dark:bg-secondary '
            : 'bg-gray-100 dark:bg-primary-foreground',
        )}
      >
        <div class="flex items-center mb-2">
          {#if item.incommingMessage}
            <ActivityRefs {item} {id} on:open={e => openItem(e.detail)} />
          {:else}
            <span class="text-sm text-gray-500">{item.name ?? ''}</span>

            {#if !item.incommingMessage}
              <ActivityRefs {item} {id} on:open={e => openItem(e.detail)} />
            {/if}
          {/if}
        </div>
        <p class="mb-2 text-sm text-primary/80">
          {@html convertLineBreaks(item.description)}
        </p>
        <div class="text-gray-500">{formatDate(item.startDate)}</div>
      </div>
    </div>
  {:else if item.type === 'Assignment'}
    <div class="overflow-hidden flex-1 py-1.5 ml-10 min-w-0">
      <div class="space-x-3 text-sm text-gray-500">
        <span class="block mb-2 ml-auto w-full text-xs text-right">
          {formatDate(item.startDate) ?? '-'}
        </span>

        <div class="flex flex-wrap my-8 space-x-4 space-y-4">
          <div class="flex flex-col items-start space-y-4">
            <div class="flex space-x-2">
              <CrmAvatar
                module="contacts"
                id={item?.candidates[0]?.id}
                name={item?.candidates[0]?.name}
                on:open={e => openItem(e.detail)}
              />
              <span>was assigned </span>
            </div>

            <div class="flex items-center">
              <div class="px-2 mr-2 bg-primary-100 text-primary-900">to</div>

              <CrmAvatar
                module="deals"
                id={item?.deals[0]?.id}
                name={item?.deals[0]?.name}
                on:open={e => openItem(e.detail)}
              />
            </div>
            <div class="flex space-x-2">
              <span class="px-4 py-1 mx-auto bg-primary-100 text-primary-900"
                >now in status</span
              >

              <ActivityHover>
                <span class="px-2 py-0.5 rounded-md bg-muted text-primary">
                  {item?.accounts[0]?.name ?? 'anonymous'}
                </span>
              </ActivityHover>

              {#if item.status}
                <Badge variant="secondary" class="bg-primary/10">
                  {item.status}
                </Badge>
              {/if}
            </div>
          </div>
        </div>

        <div class="hidden p-2 my-2 border text-muted-foreground">
          {item?.description ?? ''}
          {item?.rejectedReason ?? ''}
          {item?.rejectedBy ?? ''}
        </div>
      </div>
    </div>
  {:else if item.type === 'Phone'}
    <div
      class="overflow-hidden relative px-10 py-4 w-full shadow-md bg-primary-800"
    >
      <div class="flex flex-row justify-between pr-4 mb-1 w-full">
        <div>
          <span class="font-bold">{'Call'}</span>
          <!--
          <span class="mr-1">logged by</span>

          <ActivityHover
            name={item.createdBy ?? item._createdBy ?? 'anonymous'}
            module="contacts"
            id="3076e864-89f5-48a8-a1f9-b876329deaf6"
          >
            <span class="px-2 py-0.5 rounded-md bg-muted text-primary">
              {(item.createdBy || item._createdBy) ?? 'anonymous'}
            </span>
          </ActivityHover-->
          <ActivityRefs {item} {id} on:open={e => openItem(e.detail)} />
        </div>
        <div class="text-xs text-right">
          {formatDate(item.startDate) ?? '-'}
        </div>
      </div>
      <Icon
        icon="mdi:phone"
        class="absolute left-3 top-4 text-primary/50 size-4"
      />

      {item.name}
      <p class="mt-4 text-base">
        {@html item?.description ?? ''}
      </p>
      <ActivityHover>
        <span class="px-2 py-0.5 rounded-md bg-muted text-primary">
          {(item.createdBy || item._createdBy) ?? 'anonymous'}
        </span>
      </ActivityHover>
    </div>
  {:else if item.type === 'Todo'}
    <Card.Root class="overflow-hidden w-full shadow-md">
      <Card.Header>
        <Card.Title class="flex items-center space-x-2">
          <Checkbox id={item.id} />

          <Label
            for={item.id}
            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 peer-data-[disabled=true]:cursor-not-allowed peer-data-[disabled=true]:opacity-70"
            >{item.name ?? '-'}</Label
          >
        </Card.Title>
      </Card.Header>
      <Card.Content>
        <p class="mt-4 text-base">
          {@html item?.description ?? ''}
        </p>
        <ActivityHover>
          <span class="px-2 py-0.5 rounded-md bg-muted text-primary">
            {(item.createdBy || item._createdBy) ?? 'anonymous'}
          </span>
        </ActivityHover>
        <ActivityRefs {item} {id} on:open={e => openItem(e.detail)} />
      </Card.Content>
    </Card.Root>
  {:else}
    <Card.Root
      class={cn(
        'w-full shadow-md ',
        item.type === 'Note' && 'bg-yellow-100/50 dark:bg-yellow-950/20',
      )}
    >
      <Card.Description class="relative">
        <Accordion.Root bind:value={open}>
          <Accordion.Item value="open">
            <Accordion.Trigger
              class={cn(
                'px-2 no-underline hover:no-underline overflow-hidden',
                open && 'bg-primary/10 ',
              )}
            >
              <div class={cn('flex flex-col w-full text-left')}>
                <div class="flex flex-row justify-between pr-4 mb-1 w-full">
                  <div>
                    {#if item.type === 'Note'}
                      <Icon
                        icon="mdi:notepad-text"
                        class="absolute top-1 left-1 text-primary/50 size-4"
                      />
                    {:else if item.type === 'Email'}
                      <Icon
                        icon="mdi:at"
                        class="absolute top-1 left-1 text-primary/50 size-4"
                      />
                    {/if}
                    <span class="font-bold">{item?.type ?? 'Note'}</span>
                    <!--span class="mr-1">logged by</span>
                    <ActivityHover>
                      <span
                        class="px-2 py-0.5 rounded-md bg-muted text-primary"
                      >
                        {(item.createdBy || item._createdBy) ?? 'anonymous'}
                      </span>
                    </ActivityHover-->
                  </div>
                  <div class="text-xs text-right">
                    {formatDate(item.startDate) ?? '-'}
                  </div>
                </div>

                <div class="text-base text-primary">
                  <span>{item.name ?? ''}</span>
                </div>
                <ActivityRefs {item} {id} on:open={e => openItem(e.detail)} />

                {#if !open}
                  {#if item.hasAttachment}
                    <div class="my-4 -ml-2 space-y-2 max-w-sm">
                      {#each item.attachments ?? [] as attachment}
                        <File
                          file={attachment}
                          folder={`activities/${item?.id}`}
                          {compact}
                        />
                      {/each}
                    </div>
                  {/if}
                  <p class="mt-4 line-clamp-6">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    {#if item.type === 'Email'}
                      <ActivityIframe
                        class="w-full min-h-[20vh] max-h-[50vh] "
                        {item}
                      />
                    {:else}
                      {@html item?.description ?? ''}
                    {/if}
                  </p>
                {/if}
              </div>
            </Accordion.Trigger>
            <Accordion.Content class="text-left">
              <p class="ml-10">
                <!-- svelte-ignore a11y-missing-attribute -->
                {#if item.type === 'Email'}
                  {#if item.hasAttachment}
                    <div class="my-4 -ml-2 space-y-2 max-w-sm">
                      {#each item.attachments ?? [] as attachment}
                        <File
                          file={attachment}
                          folder={`activities/${item?.id}`}
                          {compact}
                        />
                      {/each}
                    </div>
                  {/if}
                  <ActivityIframe {item} class="w-full min-h-[70vh]" />
                {:else}
                  {@html item?.description ?? ''}
                {/if}
              </p>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </Card.Description>
    </Card.Root>
  {/if}
</div>

<style>
  :global(ul) {
    list-style-type: disc;
    padding-left: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  :global(ol) {
    list-style-type: decimal;
    padding-left: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  :global(li) {
    margin-bottom: 0.25em;
  }

  :global(li:last-child) {
    margin-bottom: 0;
  }
</style>
