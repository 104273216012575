<script lang="ts">
  import Button from '$lib/components/ui/button/button.svelte';
  import BgRemover from './bg-remover.svelte';

  export let initialPhoto: string = '';
  export let onPhotoUpdate: (newPhoto: string) => Promise<void>;

  let imageUrl = '';

  const isPluginMode = import.meta.env.MODE === 'plugin';

  function convertBase64ToImageUrl() {
    if (initialPhoto) {
      imageUrl = initialPhoto.startsWith('data:')
        ? initialPhoto
        : `data:image/png;base64,${initialPhoto}`;
    }
  }

  async function onGenerationComplete(processedImages: string[]) {
    if (processedImages && processedImages.length > 0) {
      const newPhoto = processedImages[0];
      await onPhotoUpdate(newPhoto);
    }
  }
</script>

{#if !isPluginMode}
  <div class="">
    <Button variant="ghost" on:click={convertBase64ToImageUrl}
      >Remove Background</Button
    >
    <BgRemover
      showUploadFeature={false}
      inputImageUrl={imageUrl}
      {onGenerationComplete}
    />
  </div>
{/if}
