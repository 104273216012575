export const beforeCallbacks = [];

export const beforeNavigate = (/* callback */) => {
  // TODO
  console.debug('beforeNavigate');
};

export const goto = () => {
  // TODO
  console.debug('goto');
};

export const invalidateAll = () => {
  // TODO
  console.debug('invalidateAll');
};

export const onNavigate = () => {
  // TODO
  console.debug('onNavigate');
};
