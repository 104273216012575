<script lang="ts">
  import { getAvatar } from '$lib/api/queries';
  import * as Avatar from '$lib/components/ui/avatar';
  import ActivityHover from '$lib/pages/activities/activity-hover.svelte';
  import { cn } from '$lib/utils';
  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import Button from '../ui/button/button.svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let hideIcon = false;
  export let module;
  export let id;
  export let name = undefined;
  export let size = 'size-5';
  export let withBorder = false;
  export let withHover = true;
  export let asDiv = false;
  export let className;

  export { className as class };

  const moduleStore = writable(module);
  const idStore = writable(id);
  const nameStore = writable(name);

  $: moduleStore.set(module);
  $: idStore.set(id);
  $: nameStore.set(name);

  const query = createQuery(
    derived([moduleStore, idStore], ([$module, $id]) => ({
      enabled: !!$module,
      queryKey: ['_avatars', $module, $id],
      queryFn: () => getAvatar($module, $id),
    })),
  );

  const openItem = () => {
    dispatch('open', {
      id: id,
      module: module,
    });
  };
</script>

{#if $query.isSuccess}
  {@const item = $query.data}
  {#if asDiv}
    {#key item?.id}
      <div>
        <Avatar.Root
          class={cn(
            'object-scale-down',
            size,
            item?.photo && 'rounded-full',
            module === 'accounts' && 'rounded-none size-10',
            item?.customerObj?.logo && 'rounded-none',
            withBorder && 'p-1 border border-solid  ',
            className,
          )}
        >
          {#if item?.photo}
            <Avatar.Image src={item?.photo} alt={name} />
          {:else if item?.logo}
            <Avatar.Image
              src={item?.logo}
              alt={name}
              class="object-scale-down"
            />
          {:else if module === 'deals'}
            <Avatar.Image
              src={item?.customerObj?.logo}
              alt={name}
              class="object-scale-down"
            />
          {/if}

          <Avatar.Fallback
            class="overflow-hidden text-xs uppercase rounded-none"
          >
            {name?.slice(0, 2) ?? ''}
          </Avatar.Fallback>
        </Avatar.Root>

        <div class="my-2 text-base font-bold text-nowrap">
          {name ?? item?.name ?? ''}
        </div>
      </div>
    {/key}
  {:else if !withHover}
    <Button
      slot="trigger"
      class="flex gap-1.5 items-center p-0 w-auto h-auto text-sm"
      variant="ghost"
      on:click={openItem}
    >
      {#key item.id}
        {#if !hideIcon}
          <Avatar.Root
            class={cn(
              'object-scale-down',
              size,
              item?.photo && 'rounded-full',
              module === 'accounts' && 'rounded-none size-10',
              item?.customerObj?.logo && 'rounded-none',
              withBorder && 'p-1 border border-solid  ',
              className,
            )}
          >
            {#if item?.photo}
              <Avatar.Image src={item?.photo} alt={name} />
            {:else if item?.logo}
              <Avatar.Image
                src={item?.logo}
                alt={name}
                class="object-scale-down"
              />
            {:else if module === 'deals'}
              <Avatar.Image
                src={item?.customerObj?.logo}
                alt={name}
                class="object-scale-down"
              />
            {/if}

            <Avatar.Fallback
              class="overflow-hidden text-xs uppercase rounded-none"
            >
              {name?.slice(0, 2) ?? ''}
            </Avatar.Fallback>
          </Avatar.Root>
        {/if}
        <span class="truncate text-nowrap">{name ?? item?.name ?? ''}</span>
      {/key}
    </Button>
  {:else}
    <ActivityHover>
      <div slot="content" class="flex gap-2 items-center space-x-2 w-full">
        {#if !hideIcon}
          <Avatar.Root
            class={cn(
              'object-scale-down size-20',
              item?.photo && 'rounded-full',
              item?.logo && 'rounded-none',
              item?.customerObj?.logo && 'rounded-none',
            )}
          >
            {#if item?.photo}
              <Avatar.Image src={item?.photo} alt={name} />
            {:else if module === 'deals'}
              <Avatar.Image src={item?.customerObj?.logo} alt={name} />
            {/if}

            <!--Avatar.Fallback>{name}</Avatar.Fallback-->
            <Avatar.Fallback
              class="w-full p-1 text-[8px] text-center text-gray-400 rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
            >
              {name?.slice(0, 2) ?? ''}
            </Avatar.Fallback>
          </Avatar.Root>
        {/if}
        <div class="flex flex-col gap-2 items-start w-full">
          <p class="text-sm font-semibold">{name ?? item?.name ?? ''}</p>
          <Button variant="secondary" size="sm" on:click={openItem}>View</Button
          >
        </div>
      </div>

      <Button
        slot="trigger"
        class="flex gap-1.5 items-center p-0 w-auto h-auto text-sm"
        variant="link"
        on:click={openItem}
      >
        {#key item.id}
          {#if !hideIcon}
            <Avatar.Root
              class={cn(
                'object-scale-down',
                size,
                item?.photo && 'rounded-full',
                module === 'accounts' && 'rounded-none size-10',
                item?.customerObj?.logo && 'rounded-none',
                withBorder && 'p-1 border border-solid  ',
              )}
            >
              {#if item?.photo}
                <Avatar.Image src={item?.photo} alt={name} />
              {:else if item?.logo}
                <Avatar.Image
                  src={item?.logo}
                  alt={name}
                  class="object-scale-down"
                />
              {:else if module === 'deals'}
                <Avatar.Image
                  src={item?.customerObj?.logo}
                  alt={name}
                  class="object-scale-down"
                />
              {/if}

              <Avatar.Fallback>{name?.slice(0, 2) ?? ''}</Avatar.Fallback>
            </Avatar.Root>
          {/if}
          <span class="truncate text-nowrap">{name ?? item?.name ?? ''}</span>
        {/key}
      </Button>
    </ActivityHover>
  {/if}
{/if}
