<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  export let linkedin: string;

  const shouldOpenInNewTab = true;
</script>

{#if linkedin}
  <Button
    variant="ghost"
    size="icon"
    href={linkedin}
    class="ml-1"
    target={shouldOpenInNewTab ? '_blank' : ''}
  >
    <Icon
      icon="mdi:linkedin"
      class="size-6 text-[#0077B5] dark:text-[#e6f6ff]"
    />
  </Button>
{/if}
