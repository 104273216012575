<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import PriceService from './price-service.svelte';

  export let form;
  export let readOnly = false;
  let { form: formData, enhance, errors } = form;

  export let billedWeight;
</script>

<div class="p-6 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Icon icon="mdi:cash-usd" class="mr-2" />
    Financial Details
  </h2>

  <HantaInputNumber
    {form}
    {readOnly}
    name="shipment.manualDiscount"
    label="Manual discount in % (0-100)"
    asFloat={true}
    min={0}
    max={100}
    step={1}
  />

  <PriceService
    bind:lead={$formData.shipment}
    supplierTarifId={$formData.account?.supplierTariff?.id}
    customerTarifId={$formData.account?.customerTariff?.id}
    baseTarifId={$formData.account?.baseTariff?.id}
    refDate={$formData.orderDate || $formData.createdAt}
    weight={billedWeight}
  />

  <div
    class="grid grid-cols-4 gap-3 justify-items-end p-4 pl-24 my-8 text-2xl rounded-lg shadow-md bg-primary-foreground/10"
  >
    <h1
      class="col-span-2 self-start w-full text-xl font-semibold text-gray-800 uppercase"
    >
      Total:
    </h1>
    <div class="font-semibold text-gray-800">
      {$formData.shipment?.totalEUR ?? 0}
    </div>

    <div class="w-full text-primary/40">€</div>

    <div class="col-start-3 text-2xl font-semibold text-gray-800">
      {$formData.shipment?.totalNational ?? 0}
    </div>
    <div class="w-full text-primary/40">MDL</div>

    {#if $formData.shipment?.rate?.discountTotalEur}
      <div class="col-span-2 w-full text-xl uppercase text-muted-foreground">
        Discount:
      </div>
      <div class="text-2xl font-semibold text-muted-foreground">
        -{($formData.shipment?.rate?.discountTotalPercents ?? 0).toFixed(2)}
      </div>
      <div class="w-full text-primary/40">%</div>
      <div class="col-start-3 text-2xl font-semibold text-muted-foreground">
        -{($formData.shipment?.rate?.discountTotalEur ?? 0).toFixed(2)}
      </div>
      <div class="w-full text-primary/40">€</div>
    {/if}
  </div>
</div>
