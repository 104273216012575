<script lang="ts">
  import { Pagination as PaginationPrimitive } from 'bits-ui';
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils';
  import Icon from '@iconify/svelte';
  type $$Props = PaginationPrimitive.PrevButtonProps;
  type $$Events = PaginationPrimitive.PrevButtonEvents;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<PaginationPrimitive.PrevButton asChild let:builder>
  <Button
    variant="ghost"
    class={cn('gap-1 pl-2.5', className)}
    builders={[builder]}
    on:click
    {...$$restProps}
  >
    <slot>
      <Icon icon="mdi:chevron-left" class="w-4 h-4" />
      <span>Previous</span>
    </slot>
  </Button>
</PaginationPrimitive.PrevButton>
