<script lang="ts">
  import { fly } from 'svelte/transition';
  import CandidateFactSheetForm from './../../widgets/contacts/candidate-fact-sheet-form.svelte';
  import * as Tabs from '$lib/components/ui/tabs';

  import DealForm from './deal-form.svelte';
  import Activities from '$lib/pages/activities/activities.svelte';
  import { type Account, type ActivityWithRefs, type Deal } from '$db/schema';
  import ContactsWidget from '$lib/widgets/contacts/contacts-widget.svelte';
  import DealHeader from './deal-header.svelte';
  import DealKanban from './deal-kanban.svelte';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import FlowWidget from '$lib/widgets/flow/flow-widget.svelte';

  import { getItemById } from '$lib/api/queries';
  import { appStore } from '$lib/app-store';
  import DealCard from './deal-card.svelte';
  import DealCardShort from './deal-card-short.svelte';
  import Report from './report/report.svelte';
  import Candidates from './table/candidates.svelte';
  import Statistics from './statistics/statistics.svelte';

  export let deal: Deal;
  export let currentTab = $appStore.isMobile ? 'about' : 'overview';

  let currentTabLocal = currentTab;

  $: if (currentTab) {
    currentTabLocal = currentTab;
  }

  $: customer = deal && deal.customerObj;

  let currentContact = null;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [
      {
        id: customer?.id,
        name: customer?.name,
      },
    ],
    deals: [
      {
        id: deal?.id,
        name: deal?.name,
        customer: customer?.id,
      },
    ],
    candidates: [],
    contacts: [],
  };

  const openItem = (account: Account) => {
    appStore.setCurrentModule('accounts');
    appStore.select({
      id: account.id,
      module: 'accounts',
    });
    appStore.openPopup(true);
  };
</script>

<PageWithTitle
  withSidebar={(currentTabLocal === 'flow' && currentContact?.id) ||
    currentTabLocal === 'overview' ||
    currentTabLocal === undefined}
>
  <div class="flex space-x-2" slot="short-card">
    <DealCardShort {deal} {activity} />
  </div>

  <div slot="card">
    <DealCard {deal} {activity} />
  </div>

  <div class="relative" slot="content">
    <Tabs.Root
      bind:value={currentTabLocal}
      class="flex flex-col items-start w-full h-full mt-4 overflow-hidden"
    >
      <Tabs.List>
        {#if $appStore.isMobile}
          <Tabs.Trigger value="about">About</Tabs.Trigger>
        {/if}
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
        <Tabs.Trigger value="kanban">Kanban</Tabs.Trigger>

        {#if !$appStore.isMobile}
          <Tabs.Trigger value="flow">Flow</Tabs.Trigger>
          <Tabs.Trigger value="candidates">Candidates</Tabs.Trigger>
          <!--Tabs.Trigger value="statistics">Statistics</Tabs.Trigger-->
        {/if}
      </Tabs.List>

      {#if $appStore.isMobile}
        <Tabs.Content class="text-left" value="about">
          <DealForm {deal} />
        </Tabs.Content>
      {/if}
      <!--Tabs.Content class="text-left" value="statistics">
        <Statistics />
      </Tabs.Content-->
      <Tabs.Content class="w-full h-full p-2 overflow-scroll" value="overview">
        <DealHeader {deal} />
        {#key deal?.id}
          <ContactsWidget
            contacts={deal?.contacts || []}
            dealId={deal?.id}
            customerId={deal.customer}
          />
        {/key}

        <div
          class="p-4 px-4 py-5 my-8 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
        >
          <Files compact={true} folder={`deals/${deal?.id}`} />
        </div>
      </Tabs.Content>

      <Tabs.Content class="w-full h-full p-2 overflow-scroll" value="timeline">
        {#if currentTabLocal === 'timeline'}
          <Activities id={deal.id} module={'deals'} type="all" />
        {/if}
      </Tabs.Content>

      <Tabs.Content class="w-full h-full p-2 overflow-scroll" value="kanban">
        {#if currentTabLocal === 'kanban'}
          <DealKanban {deal} />
        {/if}
      </Tabs.Content>

      <Tabs.Content
        class="w-full h-full p-2 overflow-scroll"
        value="candidates"
      >
        {#if !$appStore.isMobile && currentTabLocal === 'candidates'}
          <Candidates {deal} />
        {/if}
      </Tabs.Content>
      <Tabs.Content class="w-full h-full p-2 overflow-scroll" value="flow">
        {#if !$appStore.isMobile && currentTabLocal === 'flow'}
          <FlowWidget
            {deal}
            on:current={e => {
              if (!e.detail || currentContact?.id === e.detail.id) {
                currentContact = null;
              } else {
                getItemById('contacts', e.detail.id).then(e => {
                  currentContact = e;
                });
              }
            }}
          />
        {/if}
      </Tabs.Content>
    </Tabs.Root>
  </div>

  <div slot="sidebar">
    {#if currentTabLocal !== 'flow'}
      <DealForm {deal} />
    {:else if currentContact?.id}
      {#key currentContact?.id}
        <div
          transition:fly={{
            duration: 200,
            x: 500,
            y: 0,
            opacity: 0.5,
          }}
        >
          <CandidateFactSheetForm crmContact={currentContact} />
        </div>
      {/key}
    {/if}
  </div>
</PageWithTitle>
