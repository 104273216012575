<script lang="ts">
  import { onMount } from 'svelte';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { insertInvoiceSchema, type Invoice } from '$db/schema';
  import { saveInvoice, finalizeInvoice } from '$lib/api/invoice-api';
  import { toast } from 'svelte-sonner';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import PageWithTitle from '$lib/ui/page-with-title.svelte';
  import InvoiceHeader from './invoice-header.svelte';
  import InvoiceBillingInfo from './invoice-billing-info.svelte';
  import InvoicePositions from './invoice-positions.svelte';
  import InvoiceIntroduction from './invoice-introduction.svelte';
  import InvoiceConclusion from './invoice-conclusion.svelte';
  import InvoiceSidebar from './invoice-sidebar.svelte';

  import InvoicePayments from './invoice-payments.svelte';

  import { createPrintDataForInvoice } from '$lib/pages/invoices/invoice-utils';
  import { authStore } from '$lib/auth-store';
  import { docx2pdf, generateDocx } from '$lib/api/docx-api';

  import { calculateSum } from './invoice-utils';

  export let item: Invoice = {};
  export let flipDurationMs = 300;

  const form = superForm(item, {
    dataType: 'json',
    validators: zodClient(insertInvoiceSchema),
    onSubmit: async () => {
      try {
        const newInvoice = await saveInvoice($formData);
        console.debug('Saved invoice', newInvoice);
      } catch (e) {
        console.error('Error saving invoice', e);
        toast.error(e.message);
      }
    },
  });

  let { form: formData, enhance, errors } = form;

  $: hasChanges = form && form.isTainted() && $formData.status === 'Draft';
  $: isLocked = $formData.status !== 'Draft';
  $: sum = calculateSum($formData);
  $: total = tweened(sum.sum, { duration: 500, easing: cubicOut });

  onMount(() => {
    if (item) {
      form.reset(item);
    }
  });

  const templateName = 'invoice.docx';

  const onFinalize = async () => {
    try {
      const id = $formData?.id;
      await form.submit();
      const finalInvoice = await finalizeInvoice(id);
      const finalInvoicePrintData = createPrintDataForInvoice(finalInvoice);

      const orgId = $authStore?.organization?.id;
      const filename = `orgs/${orgId}/invoices/${id}/invoice/invoice`;

      const renderedDoc = {
        ...finalInvoicePrintData,
        meta: {
          orgId,
          templateName,
          templateUrl: '/templates/',
          filename: finalInvoicePrintData?.name,
          destination: filename + '.docx',
        },
      };

      const { signedUrl } = await generateDocx(renderedDoc);
      await docx2pdf(signedUrl, filename + '.pdf');

      // Update the form data with the finalized invoice
      form.update(f => ({ ...f, ...finalInvoice }));

      toast.success('Invoice finalized successfully');
    } catch (error) {
      console.error('Error finalizing invoice:', error);
    }
  };
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<form
  class="overflow-hidden w-full h-full"
  method="POST"
  use:enhance
  on:keydown={e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }}
>
  <PageWithTitle>
    <InvoiceHeader
      slot="short-card"
      {form}
      total={$total}
      {sum}
      {isLocked}
      {hasChanges}
      {onFinalize}
    />

    <div class="flex flex-col gap-4 w-full" slot="card">
      <InvoiceHeader
        {form}
        total={$total}
        {sum}
        {isLocked}
        {hasChanges}
        {onFinalize}
      />
      <InvoiceBillingInfo {form} {formData} {isLocked} />
    </div>

    <div class="flex flex-col gap-4 mt-8 w-full" slot="content">
      {#if isLocked}
        <InvoicePayments invoiceId={$formData.id} />
      {/if}
      <InvoicePositions
        positions={$formData.positions}
        orders={$formData.ordersRefs}
        {isLocked}
        {form}
        {errors}
        {flipDurationMs}
      />

      <InvoiceIntroduction {form} {isLocked} />
      <InvoiceConclusion {form} {isLocked} />
    </div>

    <div slot="sidebar">
      <InvoiceSidebar {item} {isLocked} {form} />
    </div>
  </PageWithTitle>
</form>
