<script lang="ts">
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Button } from '$lib/components/ui/button';
  import {
    Avatar,
    AvatarImage,
    AvatarFallback,
  } from '$lib/components/ui/avatar';
  import Icon from '@iconify/svelte';
  import { signOut } from '$lib/api/supabase-auth-api';
  import { authStore } from '$lib/auth-store';

  export let showProfileDialog = false;
  let activeSection = 'profile';

  const sections = [
    { id: 'profile', label: 'Profile', icon: 'lucide:user' },
    { id: 'security', label: 'Security', icon: 'lucide:shield' },
  ];

  // Connected accounts
  const connectedAccounts = [
    {
      provider: 'Apple',
      icon: 'mdi:apple',
      email: $authStore.user?.email,
      connected: true,
    },
    {
      provider: 'Google',
      icon: 'flat-color:google',
      email: $authStore.user?.email,
      connected: false,
    },
    {
      provider: 'LinkedIn',
      icon: 'mdi:linkedin',
      email: $authStore.user?.email,
      connected: true,
    },
  ];

  async function handleSignOut() {
    await signOut();
  }

  function closeDialog() {
    showProfileDialog = false;
    activeSection = 'profile';
  }
</script>

<Dialog.Root bind:open={showProfileDialog}>
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="ghost"
        class="relative w-8 h-8 rounded-full"
      >
        <Avatar class="w-8 h-8">
          {#if $authStore.user?.user_metadata?.avatar_url}
            <AvatarImage
              src={$authStore.user.user_metadata.avatar_url}
              alt={$authStore.user.user_metadata?.full_name || 'User'}
            />
          {/if}
          <AvatarFallback>
            {($authStore.user?.email?.[0] || 'U').toUpperCase()}
          </AvatarFallback>
        </Avatar>
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="w-56 z-[99999]" align="end">
      <div class="flex gap-2 justify-start items-center p-2">
        <div class="flex flex-col space-y-1 leading-none">
          {#if $authStore.user?.user_metadata?.full_name}
            <p class="font-medium">
              {$authStore.user.user_metadata.full_name}
            </p>
          {/if}
          {#if $authStore.user?.email}
            <p class="w-[200px] truncate text-sm text-muted-foreground">
              {$authStore.user.email}
            </p>
          {/if}
        </div>
      </div>
      <DropdownMenu.Separator />
      <DropdownMenu.Item on:click={() => (showProfileDialog = true)}>
        <Icon icon="lucide:user" class="mr-2 w-4 h-4" />
        Account settings
      </DropdownMenu.Item>
      <DropdownMenu.Separator />
      <DropdownMenu.Item
        class="text-destructive focus:text-destructive"
        on:click={handleSignOut}
      >
        <Icon icon="lucide:log-out" class="mr-2 w-4 h-4" />
        Log out
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  <Dialog.Content class="max-w-4xl max-h-[90vh] overflow-y-auto">
    <div class="flex">
      <!-- Sidebar -->
      <div class="pr-6 w-48 border-r">
        <Dialog.Title class="mb-2 text-2xl font-semibold">Account</Dialog.Title>
        <Dialog.Description class="mb-6 text-sm text-muted-foreground">
          Manage your account info.
        </Dialog.Description>

        <nav class="space-y-1">
          {#each sections as section}
            <button
              class="flex items-center gap-2 w-full px-3 py-2 text-sm rounded-md transition-colors
                {activeSection === section.id
                ? 'bg-muted font-medium'
                : 'hover:bg-muted/50'}"
              on:click={() => (activeSection = section.id)}
            >
              <Icon icon={section.icon} class="w-4 h-4" />
              {section.label}
            </button>
          {/each}
        </nav>
      </div>

      <!-- Main Content -->
      <div class="flex-1 pl-6">
        {#if activeSection === 'profile'}
          <div class="space-y-6">
            <!-- Profile Section -->
            <div class="pb-6 border-b">
              <h3 class="mb-4 text-xl font-semibold">Profile details</h3>
              <div class="flex justify-between items-center">
                <div class="flex gap-4 items-center">
                  <Avatar class="w-16 h-16">
                    {#if $authStore.user?.user_metadata?.avatar_url}
                      <AvatarImage
                        src={$authStore.user.user_metadata.avatar_url}
                        alt={$authStore.user.user_metadata?.full_name || 'User'}
                      />
                    {/if}
                    <AvatarFallback>
                      {($authStore.user?.email?.[0] || 'U').toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <p class="font-medium">
                      {$authStore.user?.user_metadata?.full_name || 'User'}
                    </p>
                    <p class="text-sm text-muted-foreground">
                      {$authStore.user?.email}
                    </p>
                  </div>
                </div>
                <Button variant="outline">Update profile</Button>
              </div>
            </div>

            <!-- Email Section -->
            <div>
              <h3 class="mb-4 text-lg font-medium">Email addresses</h3>
              <div class="space-y-4">
                <div class="flex justify-between items-center py-2">
                  <div>
                    <p>{$authStore.user?.email}</p>
                    <span class="text-sm text-muted-foreground">Primary</span>
                  </div>
                  <Button variant="ghost" size="sm">
                    <Icon icon="lucide:more-horizontal" class="w-4 h-4" />
                  </Button>
                </div>
                <Button variant="outline" class="gap-2">
                  <Icon icon="lucide:plus" class="w-4 h-4" />
                  Add email address
                </Button>
              </div>
            </div>

            <!-- Connected Accounts -->
            <div>
              <h3 class="mb-4 text-lg font-medium">Connected accounts</h3>
              <div class="space-y-2">
                {#each connectedAccounts as account}
                  <div class="flex justify-between items-center py-2">
                    <div class="flex gap-3 items-center">
                      <Icon icon={account.icon} class="w-5 h-5" />
                      <div>
                        <p class="font-medium">{account.provider}</p>
                        <p class="text-sm text-muted-foreground">
                          {account.email}
                        </p>
                      </div>
                    </div>
                    {#if account.connected}
                      <div class="flex gap-2 items-center">
                        <span class="text-sm text-muted-foreground"
                          >Connected</span
                        >
                        <Button variant="ghost" size="sm">
                          <Icon icon="lucide:more-horizontal" class="w-4 h-4" />
                        </Button>
                      </div>
                    {:else}
                      <Button variant="outline" size="sm">Connect</Button>
                    {/if}
                  </div>
                {/each}
              </div>
            </div>
          </div>
        {:else if activeSection === 'security'}
          <div class="space-y-6">
            <h3 class="text-xl font-semibold">Security</h3>
            <!-- Add security settings here -->
          </div>
        {/if}
      </div>
    </div>
  </Dialog.Content>
</Dialog.Root>
