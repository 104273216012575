<script lang="ts">
  import HantaInputMultiSelectLean from '../input/hanta-input-multi-select-lean.svelte';

  import { getItems } from '$lib/api/queries';

  export let hideLabel = false;
  export let value = undefined;
  export let onChange = undefined;

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'orders',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name',
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label={hideLabel ? '' : 'Order'}
  loadOptions={loadOptionsAccounts}
  multiple={false}
  onSelectChanged={value => onChange && onChange(value)}
  placeholder="Select order..."
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full"
    let:selection
    slot="selection"
  >
    <div class="ml-2 w-full text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>
