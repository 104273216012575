<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import Textarea from '$lib/components/ui/textarea/textarea.svelte';
  import type { Props } from '$lib/components/ui/textarea/index';
  import { cn } from '$lib/utils';
  import { stringProxy } from 'sveltekit-superforms';

  export let form;
  export let name;
  export let rows: number = 3;
  export let label: string = '';
  export let description = '';
  export let placeholder: string = '';
  export let resizable: boolean = true;
  export let readOnly: boolean = false;
  export let variant: $$Props['variant'] = 'hanta';

  const { form: formData } = form;

  type $$Props = Props & {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    resizable?: boolean;
    readOnly?: boolean;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  $: proxy = stringProxy(formData, name, { empty: 'null' });
</script>

<Form.Field class={cn('space-y-1', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>{label}</Form.Label>
    {/if}
    {#if !readOnly}
      <Textarea
        {...attrs}
        {rows}
        bind:value={$proxy}
        class={cn('p-1 w-full h-full', !resizable && 'resize-none')}
        {placeholder}
        {variant}
      />
    {:else}
      <div class="w-full text-sm text-foreground min-h-[150px]">
        {@html $proxy?.replace(/\n/g, '<br/>')}
      </div>
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>
