<script>
  import {
    dndzone,
    TRIGGERS,
    SOURCES,
    DRAGGED_ELEMENT_ID,
  } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import { tick } from 'svelte';
  import { activeZoneId, selectedItems } from './selection-store';
  import KanbanItem from './kanban-item.svelte';

  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  const flipDurationMs = 120;

  export let items = [];
  let zoneId = `zone-${Math.floor(Math.random() * 1000000)}`;

  function transformDraggedElement(el) {
    if (
      !el.getAttribute('data-selected-items-count') &&
      Object.keys($selectedItems).length
    ) {
      el.setAttribute(
        'data-selected-items-count',
        Object.keys($selectedItems).length + 1,
      );
    }
  }
  function handleConsider(e) {
    const {
      items: newItems,
      info: { trigger, source, id },
    } = e.detail;
    if (source !== SOURCES.KEYBOARD) {
      if (
        Object.keys($selectedItems).length &&
        trigger === TRIGGERS.DRAG_STARTED
      ) {
        if (Object.keys($selectedItems).includes(id)) {
          delete $selectedItems[id];
          $selectedItems = { ...$selectedItems };
          tick().then(() => {
            items = newItems.filter(
              item => !Object.keys($selectedItems).includes(item.id),
            );
          });
        } else {
          $selectedItems = {};
        }
      }
    }
    if (trigger === TRIGGERS.DRAG_STOPPED) $selectedItems = {};
    items = newItems;
  }
  function handleFinalize(e) {
    let {
      items: newItems,
      info: { trigger, source, id },
    } = e.detail;
    if (Object.keys($selectedItems).length) {
      if (trigger === TRIGGERS.DROPPED_INTO_ANOTHER) {
        items = newItems.filter(
          item => !Object.keys($selectedItems).includes(item.id),
        );
      } else if (
        trigger === TRIGGERS.DROPPED_INTO_ZONE ||
        trigger === TRIGGERS.DROPPED_OUTSIDE_OF_ANY
      ) {
        tick().then(() => {
          const idx = newItems.findIndex(item => item.id === id);
          // to support arrow up when keyboard dragging
          const sidx = Math.max(
            Object.values($selectedItems).findIndex(item => item.id === id),
            0,
          );
          newItems = newItems.filter(
            item => !Object.keys($selectedItems).includes(item.id),
          );
          newItems.splice(idx - sidx, 0, ...Object.values($selectedItems));
          items = newItems;
          $activeZoneId = zoneId;
          if (source !== SOURCES.KEYBOARD) $selectedItems = {};
        });
      }
    } else {
      items = newItems;
    }
    console.debug({ items });
    dispatch('changes', {
      items,
    });
  }
  function handleMaybeSelect(id, e) {
    if (!e.ctrlKey && !e.metaKey) return;
    if (e.key && e.key !== 'Shift') return;
    if ($activeZoneId !== zoneId) {
      $selectedItems = {};
      $activeZoneId = zoneId;
    }
    if (Object.keys($selectedItems).includes(id)) {
      delete $selectedItems[id];
    } else {
      $selectedItems[id] = items.find(item => item.id === id);
    }
    $selectedItems = { ...$selectedItems };
  }
</script>

<section
  class="p-2 bg-primary-800/50 w-[24rem] h-[calc(100%-5rem)] overflow-y-auto"
  use:dndzone={{ items, flipDurationMs, transformDraggedElement }}
  on:consider={handleConsider}
  on:finalize={handleFinalize}
>
  {#each items || [] as item (item.id)}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="shadow-md bg-secondary min-h-[60px]"
      animate:flip={{ duration: flipDurationMs }}
      class:selected={Object.keys($selectedItems).includes(item.id)}
      on:mousedown={e => handleMaybeSelect(item.id, e)}
      on:keydown={e => handleMaybeSelect(item.id, e)}
    >
      <KanbanItem assignment={item} />
    </div>
  {/each}
</section>

<style>
  div {
    @apply border border-solid border-primary/10 py-1 px-2 my-3 rounded-lg;
  }
  section {
    min-height: 12em;
  }
  .selected {
    @apply border-primary bg-primary;
    opacity: 0.7;
  }
  :global([data-selected-items-count]::after) {
    position: absolute;
    right: 0.2em;
    padding: 0.5em;
    content: attr(data-selected-items-count);
    color: white;
    background: rgba(0, 0, 0, 0.6);
  }
</style>
