<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import Timelog from './timelog.svelte';

  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { getItems } from '$lib/api/queries';
  import { cn } from '$lib/utils';
  import TimelogsTotal from './timelogs-total.svelte';
  import type { Holiday } from '$lib/api/holiday-api';
  import { checkHoliday } from '$lib/api/holiday-api';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import { onMount } from 'svelte';

  const now = new Date();
  const from = writable(new Date(now.getFullYear(), now.getMonth(), 1));
  const to = writable(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const search = writable('');

  const query = createQuery(
    derived([from, to, search], ([$from, $to]) => ({
      enabled: true,
      queryKey: ['timelogs', $from, $to, $search],
      queryFn: ({ signal }) =>
        getItems(
          {
            collection: 'timelogs',
            from: 0,
            to: 1000,
            search: $search,
            filters: [
              {
                field: 'date',
                operator: 'gte',
                value: $from.toLocaleDateString('sv').substring(0, 10),
                active: true,
              },
              {
                field: 'date',
                operator: 'lte',
                value: $to.toLocaleDateString('sv').substring(0, 10),
                active: true,
              },
            ],
            sort: {
              column: 'date',
              order: 'asc',
            },
            select: '*',
          },
          signal,
        ),
    })),
  );

  const defaultTimeEntry = {
    date: new Date().toISOString(),
    starttime: '09:00',
    endtime: '17:00',
    pause: '30',
  };

  let timeEntry = { ...defaultTimeEntry };

  const onPreviuosMonthFn = () => {
    from.set(new Date($from.getFullYear(), $from.getMonth() - 1, 1));
    to.set(new Date($to.getFullYear(), $to.getMonth(), 0));
  };

  const onNextMonthFn = () => {
    from.set(new Date($from.getFullYear(), $from.getMonth() + 1, 1));
    to.set(new Date($to.getFullYear(), $to.getMonth() + 2, 0));
  };

  const getWeeksInMonth = (month: number, year: number) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    // Get the starting Monday (if first day is not Monday, get previous Monday)
    const start = new Date(firstDay);
    const startDay = start.getDay();
    start.setDate(start.getDate() - (startDay === 0 ? 6 : startDay - 1));

    // Get the ending Sunday (if last day is not Sunday, get next Sunday)
    const end = new Date(lastDay);
    end.setDate(end.getDate() + ((7 - end.getDay()) % 7));

    const weeks = [];
    const current = new Date(start);

    while (current <= end) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push(new Date(current));
        current.setDate(current.getDate() + 1);
      }
      weeks.push(week);
    }

    return weeks;
  };

  $: weeks = getWeeksInMonth($from.getMonth(), $from.getFullYear());

  $: totalMinutes = ($query.data?.data || []).reduce((acc, item) => {
    const minutes = item.duration.split(':').reduce((acc, time, i) => {
      return acc + parseInt(time) * Math.pow(60, 1 - i);
    }, 0);
    return acc + minutes;
  }, 0);

  $: totalHours = Math.floor(totalMinutes / 60);
  $: totalMinutesRest = (totalMinutes % 60) / 60;

  let holidayCache = new Map<string, Holiday>();

  let country = '';
  let county = '';

  onMount(() => {
    country = localStorage.getItem('holiday-country') || 'DE';
    county = localStorage.getItem('holiday-county') || 'HH';
  });

  $: {
    if (country) {
      localStorage.setItem('holiday-country', country.toUpperCase());
    }
  }

  $: {
    if (county) {
      localStorage.setItem('holiday-county', county.toUpperCase());
    }
  }

  async function fetchHolidaysForMonth(year: number, month: number) {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    holidayCache.clear();

    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const dateKey = d.toLocaleDateString('sv').substring(0, 10);
      if (!holidayCache.has(dateKey)) {
        const holidayInfo = await checkHoliday(
          new Date(d),
          country.toUpperCase(),
          `${country.toUpperCase()}-${county.toUpperCase()}`,
        );
        holidayCache.set(dateKey, holidayInfo);
      }
    }
  }

  $: {
    if ($from && country && county) {
      fetchHolidaysForMonth($from.getFullYear(), $from.getMonth());
    }
  }

  $: nextDay = (async () => {
    if (!$query.data?.data || $query.data.data.length === 0) {
      return new Date($from.getFullYear(), $from.getMonth(), 1, 9, 0);
    }

    const sortedEntries = [...$query.data.data].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );

    const latestEntry = sortedEntries[0];
    const latestDate = new Date(latestEntry.date);
    let nextDate = new Date(latestDate);
    nextDate.setDate(latestDate.getDate() + 1);

    // Keep checking until we find a valid work day
    let isValidWorkday = false;
    while (!isValidWorkday) {
      const dayOfWeek = nextDate.getDay();

      if (dayOfWeek === 6) {
        nextDate.setDate(nextDate.getDate() + 2);
        continue;
      } else if (dayOfWeek === 0) {
        nextDate.setDate(nextDate.getDate() + 1);
        continue;
      }

      const dateKey = nextDate.toLocaleDateString('sv').substring(0, 10);
      const holidayInfo = holidayCache.get(dateKey);

      if (holidayInfo && holidayInfo.isHoliday) {
        nextDate.setDate(nextDate.getDate() + 1);
        continue;
      }

      isValidWorkday = true;
    }

    if (nextDate.getMonth() !== $from.getMonth()) {
      const lastDay = new Date($from.getFullYear(), $from.getMonth() + 1, 0);
      while (lastDay.getDay() === 0 || lastDay.getDay() === 6) {
        lastDay.setDate(lastDay.getDate() - 1);
      }
      lastDay.setHours(9, 0, 0, 0);
      return lastDay;
    }

    nextDate.setHours(9, 0, 0, 0);
    return nextDate;
  })();

  let open = false;
</script>

<div>
  <div class="m-1">
    <div class="flex items-end space-x-2">
      <Button variant="outline" size="sm" on:click={onPreviuosMonthFn}>
        <Icon icon="mdi:arrow-left" class="size-5" />
      </Button>

      <div class="w-44">
        <div class="text-muted-foreground">Month</div>
        <div class="text-xl">
          {$from.toLocaleDateString('de', {
            month: 'long',
            year: 'numeric',
          })}
        </div>
      </div>
      <Button variant="outline" size="sm" on:click={onNextMonthFn}>
        <Icon icon="mdi:arrow-right" class="size-5" />
      </Button>

      <Button
        variant="outline"
        on:click={() => {
          timeEntry = {
            ...defaultTimeEntry,
            date: nextDay.toISOString(),
          };
          open = true;
        }}
      >
        <Icon icon="mdi:plus" class="mr-2 size-4" />
        Add
      </Button>
      <div class="flex items-center pl-12 space-x-4">
        <div class="grid gap-1.5 items-center w-full max-w-sm">
          <Label for="country">Country</Label>
          <Input
            type="text"
            id="country"
            bind:value={country}
            placeholder="e.g., DE"
            class="w-[100px] uppercase "
            maxlength="2"
          />
        </div>

        <div class="grid gap-1.5 items-center w-full max-w-sm">
          <Label for="county">Region</Label>
          <Input
            type="text"
            id="county"
            bind:value={county}
            placeholder="e.g., HH"
            class="w-[100px] uppercase"
            maxlength="2"
          />
        </div>
      </div>
      <Timelog {timeEntry} bind:open />
    </div>

    <div class="w-full my-2 overflow-scroll h-[calc(100svh-200px)]">
      {#if $query.isLoading}
        <div
          class="w-full h-[2px] bg-primary-800 duration-400 animate-pulse"
        ></div>
      {:else if $query.isError}
        <p>Error: {$query.error.message}</p>
      {:else if $query.isSuccess}
        {@const data = $query.data?.data || []}
        {@const projects = data.reduce((acc, item) => {
          const { project } = item;
          if (!acc[project]) {
            acc[project] = [];
          }
          acc[project].push(item);
          return acc;
        }, {})}

        <div class="flex items-center space-x-4 md:w-1/3">
          {#if Object.entries(projects)?.length > 1}
            <div
              class="p-4 my-4 w-48 border border-solid shadow-md bg-secondary"
            >
              <div class="text-muted-foreground">Total</div>
              <div class="text-xl">
                {(totalHours + totalMinutesRest).toFixed(2)} hours
              </div>
            </div>
          {/if}

          {#each Object.entries(projects) as [projectName, projectItems]}
            <TimelogsTotal
              project={projectName}
              data={projectItems}
              from={$from}
              to={$to}
            />
          {/each}
        </div>

        <div class="grid grid-cols-7 gap-1 mt-4">
          {#each ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as dayName}
            <div
              class="p-2 text-sm font-semibold text-center text-muted-foreground"
            >
              {dayName}
            </div>
          {/each}

          {#each weeks as week}
            {#each week as date}
              {@const isCurrentMonth = date.getMonth() === $from.getMonth()}
              {@const dayData = data.filter(
                el => new Date(el.date).toDateString() === date.toDateString(),
              )}
              {@const dateKey = date.toLocaleDateString('sv').substring(0, 10)}
              {@const holidayInfo = holidayCache.get(dateKey)}

              <div
                class={cn(
                  'min-h-[120px] p-2 border',
                  !isCurrentMonth && 'bg-muted/50',
                  holidayInfo && holidayInfo.isHoliday && 'bg-red-50',
                  'relative group',
                )}
              >
                <div class="flex justify-between">
                  <span
                    class={cn(
                      'text-sm',
                      !isCurrentMonth && 'text-muted-foreground',
                      date.getDay() === 0 || date.getDay() === 6
                        ? 'text-teal-500'
                        : '',
                      holidayInfo &&
                        holidayInfo.isHoliday &&
                        'text-red-600 font-medium',
                    )}
                  >
                    {date.getDate()}
                  </span>

                  {#if holidayInfo && holidayInfo.isHoliday}
                    <span
                      class="text-xs text-red-600"
                      title={holidayInfo.englishName}
                    >
                      {holidayInfo.name}
                    </span>
                  {/if}

                  <Button
                    variant="ghost"
                    size="icon"
                    class="hidden absolute right-1 group-hover:block"
                    on:click={() => {
                      timeEntry = {
                        ...defaultTimeEntry,
                        date: new Date(date.setHours(9, 0, 0, 0)).toISOString(),
                      };
                      open = true;
                    }}
                  >
                    <Icon icon="mdi:plus" class="ml-3 size-4" />
                  </Button>
                </div>

                <div class="pl-4 mt-4 space-y-1">
                  {#each dayData as item}
                    <button
                      class="p-1 pl-4 w-full text-xs text-left truncate rounded hover:bg-muted"
                      on:click={() => {
                        timeEntry = item;
                        open = true;
                      }}
                    >
                      <div class="text-xl font-medium text-primary">
                        {(
                          parseInt(item.duration.split(':')[0]) +
                          parseInt(item.duration.split(':')[1]) / 60
                        ).toFixed(1)}
                      </div>
                      {#if item.description}
                        <div class="truncate text-muted-foreground">
                          {item.description}
                        </div>
                      {/if}
                    </button>
                  {/each}
                </div>
              </div>
            {/each}
          {/each}
        </div>
      {/if}
    </div>
  </div>
</div>
