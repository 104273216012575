<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import Button from '../button/button.svelte';
  import Icon from '@iconify/svelte';

  export let maxSizeInMB = 5;
  export let acceptedFileTypes = 'image/*';

  const dispatch = createEventDispatcher();

  let fileInput: HTMLInputElement;

  function handleClick() {
    fileInput.click();
  }

  function handleFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (file) {
      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert(`File size should not exceed ${maxSizeInMB}MB`);
        return;
      }

      const reader = new FileReader();
      reader.onload = e => {
        const result = e.target?.result as string;

        dispatch('upload', { file, base64: result });
      };
      reader.readAsDataURL(file);
    }
  }
</script>

<div class="absolute right-0 bottom-0">
  <Button variant="ghost" on:click={handleClick}>
    <Icon icon="mdi:upload" class="size-4" />
  </Button>
  <input
    bind:this={fileInput}
    type="file"
    accept={acceptedFileTypes}
    on:change={handleFileChange}
    class="hidden"
  />
</div>
