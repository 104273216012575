<script lang="ts">
  import { DropdownMenu as DropdownMenuPrimitive } from 'bits-ui';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils';

  type $$Props = DropdownMenuPrimitive.SubTriggerProps & {
    inset?: boolean;
  };
  type $$Events = DropdownMenuPrimitive.SubTriggerEvents;

  let className: $$Props['class'] = undefined;
  export let inset: $$Props['inset'] = undefined;
  export { className as class };
</script>

<DropdownMenuPrimitive.SubTrigger
  class={cn(
    'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[highlighted]:bg-accent data-[state=open]:bg-accent data-[highlighted]:text-accent-foreground data-[state=open]:text-accent-foreground',
    inset && 'pl-8',
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
  on:focusin
  on:focusout
  on:pointerleave
  on:pointermove
>
  <slot />
  <Icon icon="mdi:chevron-right" class="ml-auto w-4 h-4" />
</DropdownMenuPrimitive.SubTrigger>
