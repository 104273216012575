<script lang="ts">
  import { cn } from '$lib/utils';
  import { Select as SelectPrimitive } from 'bits-ui';
  import Icon from '@iconify/svelte';
  type $$Props = SelectPrimitive.ItemProps;
  type $$Events = SelectPrimitive.ItemEvents;

  let className: $$Props['class'] = undefined;
  export let value: $$Props['value'];
  export let label: $$Props['label'] = undefined;
  export let disabled: $$Props['disabled'] = undefined;
  export { className as class };
</script>

<SelectPrimitive.Item
  {value}
  {disabled}
  {label}
  class={cn(
    'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50',
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
  on:focusin
  on:focusout
  on:pointerleave
  on:pointermove
>
  <span class="flex absolute left-2 justify-center items-center w-3.5 h-3.5">
    <SelectPrimitive.ItemIndicator>
      <Icon icon="mdi:check" class="size-4" />
    </SelectPrimitive.ItemIndicator>
  </span>
  <slot>
    {label ? label : value}
  </slot>
</SelectPrimitive.Item>
