<script lang="ts">
  import { toast } from 'svelte-sonner';
  import { createQuery, useQueryClient } from '@tanstack/svelte-query';
  import HantaInputMultiSelectLean from './hanta-input-multi-select-lean.svelte';
  import { getItems } from '$lib/api/queries';
  import { updateLov } from '$lib/api/mutations';
  import { insertLovsSchema, type Lovs } from '$db/schema';
  import { type LovType } from '$lib/widgets/input/lov-types';
  import { getFlagEmoji } from '$lib/app/utils';

  type $$Props = {
    form: any;
    value: any;
    type: LovType;
    name: string;
    label: string;
    placeholder?: string;
    multiple?: boolean;
    addable?: boolean;
    readOnly?: boolean;
    icon?: any;
  };

  export let form: any;
  export let value: any;
  export let type: LovType;
  export let name: string;
  export let label: string;
  export let placeholder: string = 'Select';
  export let multiple: boolean = true; // TODO: change to false as default
  export let addable: boolean = false;
  export let readOnly: boolean = false;
  export let icon: any | undefined = undefined;
  let options: any[];
  let lov: Lovs;

  const queryClient = useQueryClient();

  const lovQuery = createQuery({
    queryKey: ['lovs', type],
    queryFn: () =>
      getItems({
        collection: 'lovs',
        from: 0,
        to: 1,
        search: '',
        select: 'id,name,values',
        filters: [
          {
            field: 'name',
            operator: 'eq',
            value: type,
            active: true,
          },
        ],
      }),
  });

  $: lov = $lovQuery.data?.data[0] as Lovs | undefined;
  $: options = updateOptions(lov);

  function updateOptions(lov: Lovs | undefined) {
    return (lov?.values || [])
      .flat()
      .map(el =>
        type === 'Countries'
          ? `${el.name} / ${el.code} ${getFlagEmoji(el.code)}`
          : el.name || el.code || '',
      );
  }

  async function onNewLovAdded(newValue: string) {
    if (!lov) return;

    const updatedLov = { ...lov, values: [...lov.values, { name: newValue }] };
    const lovToUpdate = insertLovsSchema.parse(updatedLov) as Lovs;

    const UNDO_TIMEOUT = 5000;
    const timeoutId = setTimeout(async () => {
      const newLov = await updateLov(lovToUpdate);
      queryClient.setQueryData(['lovs', type], { data: [newLov] });
    }, UNDO_TIMEOUT);

    // Optimistically update the UI
    queryClient.setQueryData(['lovs', type], { data: [lovToUpdate] });

    toast(`New ${label ?? 'tag'} entry created: "${newValue}"`, {
      duration: UNDO_TIMEOUT,
      action: {
        label: 'Undo',
        onClick: e => {
          clearTimeout(timeoutId);
          const revertedLov = {
            ...lov,
            values: lov.values.filter(el => el.name !== newValue),
          };
          queryClient.setQueryData(['lovs', type], { data: [revertedLov] });
        },
      },
    });
  }
</script>

<HantaInputMultiSelectLean
  {readOnly}
  {form}
  bind:value
  {label}
  {placeholder}
  {name}
  {icon}
  onItemAdded={onNewLovAdded}
  {options}
  {multiple}
  {addable}
  simpleMode={true}
/>
