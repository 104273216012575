<script lang="ts">
  import { onMount, onDestroy } from 'svelte';
  import * as d3 from 'd3';
  import { formatNumber } from '$lib/utils';

  export let data = [];

  let container;
  let mounted = false;

  // Create or get existing tooltip
  function getTooltip() {
    let existing = d3.select('body').select('.chart-tooltip');
    if (!existing.empty()) {
      return existing;
    }
    return d3
      .select('body')
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('z-index', 1000);
  }

  function createChart() {
    if (!container || !data.length || !mounted) return;

    d3.select(container).selectAll('*').remove();

    const margin = { top: 20, right: 200, bottom: 60, left: 60 };
    const width = 900 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Get all keys except 'period'
    const keys = Object.keys(data[0]).filter(key => key !== 'period');

    // Stack the data
    const stack = d3
      .stack()
      .keys(keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const series = stack(data);

    // Create color scale
    const color = d3.scaleOrdinal().domain(keys).range(d3.schemeCategory10);

    const svg = d3
      .select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Create scales
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map(d => d.period))
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(series, d => d3.max(d, d => d[1]))]);

    // Add x-axis with conditional label filtering
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickFormat((d, i) => {
          // Show every 2nd label if more than 12 items
          if (data.length > 12) {
            return i % 2 === 0 ? d : '';
          }
          return d; // Show all labels if 12 or fewer items
        }),
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')
      .style('font-size', '12px')
      .style('font-family', 'system-ui, sans-serif')
      .each(function (d) {
        if (!d) return; // Skip empty labels
        const text = d3.select(this);
        const words = text.text();
        if (words.length > 15) {
          text.text(words.substring(0, 15) + '...');
        }
      });

    // Add x-axis gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(-height).tickFormat(''))
      .select('.domain')
      .remove();

    // Add y-axis
    svg.append('g').call(d3.axisLeft(y));

    // Get or create tooltip
    const tooltip = getTooltip();

    // Add bars with updated tooltip handling
    svg
      .append('g')
      .selectAll('g')
      .data(series)
      .join('g')
      .attr('fill', d => color(d.key))
      .selectAll('rect')
      .data(d => d)
      .join('rect')
      .attr('x', d => x(d.data.period))
      .attr('y', d => y(d[1]))
      .attr('height', d => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth())
      .on('mouseover', function (event, d) {
        const currentTooltip = getTooltip();
        const key = d3.select(this.parentNode).datum().key;
        const value = d.data[key];

        currentTooltip
          .html(
            `
            <div class="tooltip-content">
              <div class="tooltip-title">${key}</div>
              <div class="tooltip-value">${formatNumber(value)}</div>
            </div>
          `,
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`)
          .style('opacity', 1);
      })
      .on('mousemove', function (event) {
        const currentTooltip = getTooltip();
        currentTooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', function () {
        const currentTooltip = getTooltip();
        currentTooltip.style('opacity', 0);
      });

    // Add legend with improved positioning and styling
    const legendSpacing = 24;
    const legendPadding = 10;
    const legendX = width + 30;

    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('font-family', 'system-ui, sans-serif')
      .attr('font-size', 12)
      .attr('text-anchor', 'start');

    // Create legend items
    const legendItems = legend
      .selectAll('g')
      .data(keys)
      .join('g')
      .attr(
        'transform',
        (d, i) => `translate(${legendX},${i * legendSpacing + legendPadding})`,
      );

    // Add colored rectangles
    legendItems
      .append('rect')
      .attr('width', 16)
      .attr('height', 16)
      .attr('fill', color)
      .attr('rx', 2);

    // Add text labels
    legendItems
      .append('text')
      .attr('x', 24)
      .attr('y', 12)
      .style('font-weight', '500')
      .style('fill', '#64748b')
      .text(d => d);

    // Cleanup function
    return () => {
      d3.selectAll('.chart-tooltip').remove();
    };
  }

  onMount(() => {
    mounted = true;
    createChart();
  });

  onDestroy(() => {
    mounted = false;
    // Only remove tooltip if no other charts are using it
    const otherCharts = document.querySelectorAll('.chart');
    if (otherCharts.length <= 1) {
      d3.selectAll('.chart-tooltip').remove();
    }
  });

  // Watch for data changes
  $: if (data && mounted) {
    createChart();
  }
</script>

<div bind:this={container} class="chart" />

<style>
  .chart {
    width: 100%;
    min-width: 900px;
    height: auto;
    position: relative;
    overflow-x: auto;
  }

  :global(.grid line) {
    stroke: #e2e8f0;
    stroke-opacity: 0.3;
    shape-rendering: crispEdges;
  }

  :global(.grid path) {
    stroke-width: 0;
  }

  :global(.chart-tooltip) {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    transition: opacity 0.2s;
    white-space: nowrap;
  }

  :global(.tooltip-content) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  :global(.tooltip-title) {
    font-weight: bold;
    color: #fff;
  }

  :global(.tooltip-value) {
    color: #a5f3fc;
  }

  :global(.legend text) {
    font-family:
      system-ui,
      -apple-system,
      sans-serif;
    font-size: 12px;
    fill: #64748b;
  }

  :global(.legend rect) {
    stroke: rgba(0, 0, 0, 0.1);
    stroke-width: 1;
  }
</style>
