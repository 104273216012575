<script lang="ts">
  import IntersectionObserver from 'svelte-intersection-observer';
  import * as Card from '$lib/components/ui/card';
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/app-store';
  import * as Accordion from '$lib/components/ui/accordion';

  export let withContent: boolean = true;
  export let withSidebar: boolean = true;
  export let isPluginMode: boolean = false;

  let element: HTMLElement | null = null;
  let intersecting: boolean = true;
</script>

{#if $appStore.isMobile}
  <div class="flex flex-col h-full">
    <div
      class="flex overflow-y-scroll relative flex-col mb-16 w-full h-full bg-primary-50"
    >
      <slot name="card" />

      {#if false && withSidebar}
        <Accordion.Root class="w-full">
          <Accordion.Item value="item-1">
            <Accordion.Trigger>About</Accordion.Trigger>
            <Accordion.Content class="text-left">
              <slot name="sidebar" />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      {/if}

      <slot name="content" />
    </div>
  </div>
{:else}
  <div class={cn('flex flex-row overflow-hidden h-full ')}>
    {#if withContent}
      <div
        class={cn(
          'relative flex flex-col text-xs',
          withSidebar ? 'w-2/3' : 'w-full',
        )}
      >
        <div class="text-3xl text-primary">
          <div
            class={cn(
              intersecting && '-translate-y-20',
              'w-full h-20 space-x-4 items-center content-center flex-row flex transition absolute ease-out duration-300 border-solid border border-r border-b border-b-primary-200/20 backdrop-blur-xl bg-secondary z-20 px-6',
            )}
          >
            <slot name="short-card" />
          </div>
        </div>
        <Card.Root
          class="flex overflow-y-scroll flex-col w-full h-full bg-primary-50"
        >
          <Card.Header
            class="border-b border-solid bg-primary-800 border-b-primary-200/20"
          >
            <Card.Title>
              <IntersectionObserver
                {element}
                bind:intersecting
                rootMargin="40px"
              >
                <div bind:this={element} class="flex items-center space-x-8">
                  <slot name="card" />
                </div>
              </IntersectionObserver>
            </Card.Title>
          </Card.Header>
          <Card.Content>
            <slot name="content" />
          </Card.Content>
        </Card.Root>
      </div>
    {/if}

    {#if withSidebar}
      <div
        class={cn(
          'h-full overflow-scroll ',
          withContent ? 'w-1/3' : 'w-full',
          isPluginMode ? 'max-h-[70vh]' : '',
        )}
      >
        <slot name="sidebar" />
      </div>
    {/if}
  </div>
{/if}
