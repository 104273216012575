<script lang="ts">
  import Icon from '@iconify/svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import ContactsSelector from '$lib/widgets/contacts/contacts-selector.svelte';

  export let form;
  let { form: formData } = form;

  export let readOnly;
</script>

<div class="p-6 space-y-4 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Icon icon="mdi:account-circle" class="mr-2" />
    Customer Info
  </h2>

  <AccountSelector bind:value={$formData.account} {readOnly} />

  {#key $formData.account?.id}
    <ContactsSelector
      {readOnly}
      accountId={$formData.account?.id}
      bind:value={$formData.contactObj}
    />
  {/key}

  <div class="space-y-4">
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Credit Rating:</strong>
      <span class="text-gray-800">{$formData.account?.creditRating ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Debt Reason:</strong>
      <span class="text-gray-800">{$formData.account?.debtReason ?? '-'}</span>
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Current Balance:</strong>
      <span class="text-gray-800"
        >{$formData.account?.currentBalance?.toFixed(0) ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Max Debt:</strong>
      <span class="text-gray-800"
        >{$formData.account?.maxDebt?.toFixed(0) ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Current Credit Limit:</strong>
      <span class="text-gray-800"
        >{$formData.account?.currentCreditLimit?.toFixed(0) ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Customer Tariff:</strong>
      <span class="text-gray-800"
        >{$formData.account?.customerTariff?.name ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Base Tariff:</strong>
      <span class="text-gray-800"
        >{$formData.account?.baseTariff?.name ?? '-'}</span
      >
    </div>
  </div>
</div>
