<script lang="ts">
  import { Button, buttonVariants } from '$lib/components/ui/button/index.js';
  import * as Dialog from '$lib/components/ui/dialog/index.js';
  import { Input } from '$lib/components/ui/input/index.js';
  import { Label } from '$lib/components/ui/label/index.js';
  import Icon from '@iconify/svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let open = false;
  let name = 'New Pricelist';

  function onAdd() {
    if (name.trim()) {
      dispatch('add', { name });
      name = 'New Pricelist';
      open = false;
    }
  }
</script>

<Dialog.Root bind:open>
  <Dialog.Trigger class={buttonVariants({ variant: 'default' })}>
    <Icon icon="mdi:plus" class="mr-2 size-4" />
    Add Pricelist
  </Dialog.Trigger>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Add Pricelist</Dialog.Title>
      <Dialog.Description>Create a new pricelist.</Dialog.Description>
    </Dialog.Header>
    <div class="grid gap-4 py-4">
      <div class="grid grid-cols-4 gap-4 items-center">
        <Label for="name" class="text-right">Name</Label>
        <Input
          id="name"
          bind:value={name}
          class="col-span-3"
          autocomplete="off"
        />
      </div>
    </div>
    <Dialog.Footer>
      <div class="flex gap-2 justify-end">
        <Button
          type="button"
          variant="secondary"
          on:click={() => (open = false)}>Cancel</Button
        >
        <Button type="submit" on:click={onAdd} disabled={!name.trim()}
          >Add new pricelist</Button
        >
      </div>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
