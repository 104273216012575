import { NumberUtils } from './utils/NumberUtils';
import type {
  ExchangesData,
  PriceRequest,
  IExchangeRateService,
  Service,
} from './prices';

export class ExchangeRateService implements IExchangeRateService {
  private readonly DEFAULT_CURRENCY = 'EUR - Euro';

  public findExchanges(
    exchangesData: ExchangesData[] | null | undefined,
    currency: string = this.DEFAULT_CURRENCY,
  ): ExchangesData {
    return (
      exchangesData?.find(d => d.name === currency) ?? {
        name: currency,
        einheit: 1,
        mdl: 0,
        mdl2eur: 0,
      }
    );
  }

  public updateExchanges(
    item: PriceRequest,
    exchangesData: ExchangesData[],
  ): void {
    const eur = this.findExchanges(exchangesData);
    item.exchangeRate = eur.mdl / eur.einheit;
    item.mdl2eur = item.exchangeRate;

    this.updatePriceInInvoice(item, exchangesData);
  }

  private updatePriceInInvoice(
    item: PriceRequest,
    exchangesData: ExchangesData[],
  ): void {
    item.priceInInvoiceEur = 0;

    if (!this.canCalculatePriceInInvoice(item)) return;

    const currency = this.findExchanges(exchangesData, item.currencyInInvoice);

    item.priceInInvoiceEur = NumberUtils.roundValue(
      ((item.priceInInvoice / currency.einheit) * currency.mdl) /
        item.exchangeRate,
    );
  }

  private canCalculatePriceInInvoice(item: PriceRequest): boolean {
    return !!(
      item.exchangeRate &&
      item.currencyInInvoice &&
      item.priceInInvoice
    );
  }

  public setMdl2eurOnServices(item: PriceRequest): void {
    item.services?.forEach((service: Service) => {
      service.mdl2eur = item.mdl2eur;
    });
  }
}
