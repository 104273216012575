<script>
  import { onMount, onDestroy } from 'svelte';
  import jspreadsheet from 'jspreadsheet-ce';
  import 'jspreadsheet-ce/dist/jspreadsheet.css';
  import 'jsuites/dist/jsuites.css';
  import Button from '$lib/components/ui/button/button.svelte';

  export let columnHeaders = [];
  export let data = [];

  let jssInstance;
  let spreadsheetContainer;

  onMount(() => {
    jssInstance = jspreadsheet(spreadsheetContainer, {
      data,
      columns: columnHeaders,
      filters: false,
      contextMenu: false,
      copyCompatibility: true,
      onbeforechange: (instance, cell, x, y, value) => {
        data[y][x] = value;
      },
    });

    return () => {
      if (jssInstance && typeof jssInstance.destroy === 'function') {
        jssInstance.destroy();
      }
    };
  });

  onDestroy(() => {
    if (jssInstance && typeof jssInstance.destroy === 'function') {
      jssInstance.destroy();
    }
  });

  function downloadSpreadsheet() {
    if (jssInstance && typeof jssInstance.download === 'function') {
      jssInstance.download();
    }
  }
</script>

<Button class="hidden" on:click={downloadSpreadsheet}>Download</Button>
<div bind:this={spreadsheetContainer} style="width: 100%; height: 400px;"></div>
