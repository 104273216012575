<script lang="ts">
  import AuthUserButtonSupabase from './supabase/user/auth-user-button-supabase.svelte';
  import AuthUserButtonClerk from './clerk/auth-user-button-clerk.svelte';
</script>

{#if import.meta.env.VITE_AUTH_PROVIDER === 'clerk'}
  <AuthUserButtonClerk />
{:else if import.meta.env.VITE_AUTH_PROVIDER === 'supabase'}
  <AuthUserButtonSupabase />
{/if}
