<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import { RangeCalendar } from '$lib/components/ui/range-calendar/index.js';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import type { DateRange } from 'bits-ui';
  import Icon from '@iconify/svelte';
  import {
    DateFormatter,
    type DateValue,
    getLocalTimeZone,
    today,
  } from '@internationalized/date';
  import { cn } from '$lib/utils.js';

  // Constants
  const DATE_FORMATTER = new DateFormatter('en-US', { dateStyle: 'medium' });
  const DEFAULT_START_DATE = today(getLocalTimeZone());
  const DEFAULT_END_DATE = DEFAULT_START_DATE.add({ days: 7 });

  // Props
  export let value: DateRange | undefined = {
    start: DEFAULT_START_DATE,
    end: DEFAULT_END_DATE,
  };

  // Local state
  let startValue: DateValue | undefined = undefined;
  let selectedRelativeRange = 'Custom';

  // Relative date ranges
  const relativeDateRanges = [
    {
      label: 'Today',
      getValue: () => ({
        start: today(getLocalTimeZone()),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Yesterday',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 1 }),
        end: today(getLocalTimeZone()).subtract({ days: 1 }),
      }),
    },
    {
      label: 'Last 7 days',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 6 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last 30 days',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 29 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'This month',
      getValue: () => ({
        start: today(getLocalTimeZone()).set({ day: 1 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last month',
      getValue: () => {
        const lastMonth = today(getLocalTimeZone()).subtract({ months: 1 });
        return {
          start: lastMonth.set({ day: 1 }),
          end: lastMonth.set({ day: lastMonth.daysInMonth }),
        };
      },
    },
    {
      label: 'This quarter',
      getValue: () => {
        const currentQuarter = Math.floor(
          (today(getLocalTimeZone()).month - 1) / 3,
        );
        const quarterStart = today(getLocalTimeZone()).set({
          month: currentQuarter * 3 + 1,
          day: 1,
        });
        return { start: quarterStart, end: today(getLocalTimeZone()) };
      },
    },
    {
      label: 'Year to date',
      getValue: () => ({
        start: today(getLocalTimeZone()).set({ month: 1, day: 1 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last 365 days',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 364 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last 2 years',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 364 * 2 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last 5 years',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 364 * 5 }),
        end: today(getLocalTimeZone()),
      }),
    },
    {
      label: 'Last 10 years',
      getValue: () => ({
        start: today(getLocalTimeZone()).subtract({ days: 364 * 10 }),
        end: today(getLocalTimeZone()),
      }),
    },
  ];

  // Helper functions
  function formatDateRange(range: DateRange): string {
    if (!range || !range.start) return 'Pick a date';
    const start = DATE_FORMATTER.format(range.start.toDate(getLocalTimeZone()));
    const end = range.end
      ? DATE_FORMATTER.format(range.end.toDate(getLocalTimeZone()))
      : '';
    return end ? `${start} - ${end}` : start;
  }

  $: displayText = value?.start
    ? formatDateRange(value)
    : startValue
      ? DATE_FORMATTER.format(startValue.toDate(getLocalTimeZone()))
      : 'Pick a date';

  function handleRelativeDateSelect(range: DateRange, label: string) {
    value = range;
    selectedRelativeRange = label;
  }

  function handleAbsoluteDateChange() {
    selectedRelativeRange = 'Custom';
  }

  $: if (value?.start && value?.end) {
    // Check if the current value matches any predefined relative range
    const matchingRange = relativeDateRanges.find(range => {
      const relativeRange = range.getValue();
      return (
        relativeRange.start.compare(value.start) === 0 &&
        relativeRange.end.compare(value.end) === 0
      );
    });
    selectedRelativeRange = matchingRange ? matchingRange.label : 'Custom';
  } else {
    selectedRelativeRange = 'Custom';
  }
</script>

<div class="flex gap-2">
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="outline"
        class="w-[200px] justify-between"
      >
        {selectedRelativeRange}
        <Icon icon="mdi:chevron-down" class="ml-2 w-4 h-4" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="w-56">
      {#each relativeDateRanges as range}
        <DropdownMenu.Item
          on:click={() =>
            handleRelativeDateSelect(range.getValue(), range.label)}
        >
          {range.label}
        </DropdownMenu.Item>
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  <Popover.Root>
    <Popover.Trigger asChild let:builder>
      <Button
        variant="outline"
        class={cn(
          'w-[300px] justify-start text-left font-normal',
          !value && 'text-muted-foreground',
        )}
        builders={[builder]}
      >
        <Icon icon="mdi:calendar" class="mr-2 w-4 h-4" />
        {displayText}
      </Button>
    </Popover.Trigger>
    <Popover.Content class="p-0 w-auto" align="start">
      <RangeCalendar
        bind:value
        bind:startValue
        initialFocus
        numberOfMonths={2}
        on:select={handleAbsoluteDateChange}
      />
    </Popover.Content>
  </Popover.Root>
</div>
