<script lang="ts">
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import {
    orderWithShipmentAndRateSchema,
    type OrderWithShipmentAndRate,
  } from '$db/schema';
  import { saveOrder } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';

  import LeadInfoCard from '../leads_orders/lead-order-info-card.svelte';
  import CustomerInfoCard from '../leads_orders/customer-info-card.svelte';
  import ShippingDetailsCard from '../leads_orders/shipping-details-card.svelte';
  import WeightVolumeCard from '../leads_orders/weight-volume-card.svelte';
  import AdditionalServicesCard from '../leads_orders/additional-services-card.svelte';
  import FinancialDetailsCard from '../leads_orders/financial-details-card.svelte';
  import RateDetailsCard from '../leads_orders/rate-details-card.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  export let item;

  let billedWeight = 0;

  const formSchema = orderWithShipmentAndRateSchema.partial();
  const form = superForm<OrderWithShipmentAndRate>(item, {
    resetForm: false,
    SPA: true,
    validators: zodClient(formSchema),
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async () => {
      if (item.status === 'ARCHIVE') {
        return;
      }
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }
      /*
      $formData.shipment.weight = weight;
      $formData.shipment.volume = volume;
      $formData.shipment.volumeKg = volumeKg; */
      $formData.shipment.billedWeight = billedWeight;

      await saveOrder($formData);
      appStore.closeActivity();

      $appStore.queryClient.invalidateQueries(['orders', $formData.id], {
        exact: true,
        refetchActive: true,
      });
    },
  });

  let { form: formData, enhance, errors } = form;

  $: readOnly = $formData.status === 'ARCHIVE';
</script>

<main class="overflow-scroll p-6 py-24 min-h-screen bg-primary/70">
  <form class="space-y-6 w-full" method="POST" use:enhance>
    <div class="mx-auto max-w-5xl">
      <div class="grid grid-cols-1 gap-8 xl:grid-cols-2">
        <LeadInfoCard {form} {readOnly} type={'Order'} />

        <CustomerInfoCard {form} {readOnly} />
        <ShippingDetailsCard {form} {readOnly} />
        <WeightVolumeCard {form} {readOnly} bind:billedWeight />
        <AdditionalServicesCard {form} {readOnly} />
        <FinancialDetailsCard {form} {readOnly} {billedWeight} />
      </div>

      <RateDetailsCard rate={$formData.shipment?.rate} />

      <FormActions {form} />
    </div>
  </form>
</main>
