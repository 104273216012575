<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { Switch } from '$lib/components/ui/switch';
  import { Label } from '$lib/components/ui/label';
  import PricelistTable from './pricelist-table.svelte';
  import { Button } from '$lib/components/ui/button';
  import Dimensions from './dimensions.svelte';

  export let pricelist: any;

  const removePricelist = () => {
    console.debug('removePricelist', pricelist);
  };
</script>

{#if pricelist && pricelist.prices}
  {#key pricelist?.name}
    <div
      class="flex absolute right-0 -top-4 justify-between items-center mt-4 space-x-4"
    >
      <div class="flex items-center space-x-2">
        <Switch id="active" bind:checked={pricelist.active} />
        <Label for="active">Active</Label>
      </div>
      <Button variant="destructive" size="sm" on:click={removePricelist}
        >Remove Pricelist</Button
      >
    </div>
    <Tabs.Root value="dimensions">
      <Tabs.List>
        <Tabs.Trigger value="dimensions">Dimensions</Tabs.Trigger>
        <Tabs.Trigger value="prices">Prices</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="dimensions">
        <Dimensions bind:dimensions={pricelist.dimensions} />
      </Tabs.Content>
      <Tabs.Content value="prices">
        <PricelistTable
          dimensions={pricelist?.dimensions}
          bind:data={pricelist.prices}
        />
      </Tabs.Content>
    </Tabs.Root>
  {/key}
{/if}
