<script lang="ts">
  import { slide } from 'svelte/transition';
  import { Button } from '$lib/components/ui/button';

  export let form;

  let { form: formData, errors } = form;

  $: hasChanges = $formData && form.isTainted();
  $: errorCount = Object.keys($errors).length;
</script>

{#if hasChanges || errorCount > 0}
  <div
    class="sticky bottom-0 pb-0 mb-0 border-t border-solid shadow border-t-primary-200/30 bg-muted"
    out:slide={{ duration: 200 }}
    in:slide={{ duration: 200 }}
  >
    <div class="container flex justify-between items-center py-4">
      <div class="flex items-center space-x-2">
        {#if errorCount > 0}
          <div class="text-destructive">
            {errorCount}
            {errorCount === 1 ? 'error' : 'errors'} in form
          </div>
        {/if}

        {#if hasChanges}
          <div class="text-muted-foreground">Unsaved changes</div>
        {/if}
      </div>
      <div class="flex items-center space-x-2">
        <Button type="button" variant="outline" on:click={() => form.reset()}>
          Reset
        </Button>
        <Button type="submit" disabled={errorCount > 0}>Save Changes</Button>
      </div>
    </div>
  </div>
{/if}
