<script>
  import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query';
  import { toast } from 'svelte-sonner';

  import { supabase } from '$lib/api/supabase';
  import { ModeWatcher } from 'mode-watcher';
  import { Plane } from 'svelte-loading-spinners';
  import { appStore } from '$lib/app-store';
  import { Toaster } from '$lib/components/ui/sonner';
  import MainWidget from '$lib/widgets/main-widget.svelte';
  import ActivityPopup from '$lib/pages/activities/activity-popup.svelte';

  import CrmApp from '$lib/app/crm-app.svelte';
  import CrmChecker from '$lib/checker/crm-checker.svelte';
  import LinkedInChecker from '$lib/checker/linkedIn-checker.svelte';

  import { contactLocal } from '$lib/mock-data';
  import { onDestroy, onMount } from 'svelte';
  import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
  import { authStore } from '$lib/auth-store';
  import { get } from 'svelte/store';

  let contact = import.meta.env.DEV ? contactLocal : null;

  let subscription;

  function unsubscribe() {
    if (subscription) {
      supabase($authStore.token).removeAllChannels();
    }
  }

  const exceptionTables = ['search', 'rates', 'shipments'];
  function subcribeToChannels() {
    unsubscribe();

    subscription = supabase(get(authStore).token)
      .channel('*')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public' },
        async payload => {
          if (exceptionTables.includes(payload.table)) {
            console.debug('Exception table: ', payload.table);
            return;
          }
          console.debug('Database changes detected: ', payload);

          if (payload.table === 'dealsToContacts') {
            const { contactId, dealId } = payload.new;

            $appStore.queryClient.invalidateQueries(['deals', dealId], {
              exact: true,
              refetchActive: true,
            });
            $appStore.queryClient.invalidateQueries(['contacts', contactId], {
              exact: true,
              refetchActive: true,
            });
          } else {
            if (payload.table !== 'sequences') {
              toast.success(
                'Changes in ' +
                  payload.table.charAt(0).toUpperCase() +
                  payload.table.slice(1),
              );
            }
          }

          const { eventType, old } = payload;

          if (eventType === 'DELETE' && old.id) {
            if ($appStore.selected?.id === old.id) {
              appStore.openPopup(false);
              appStore.select(undefined);
            }
            if (appStore.crmAccount?.id === old.id) {
              appStore.setCrmAccount(undefined);
            }
            if (appStore.crmContact?.id === old.id) {
              appStore.setCrmContact(undefined);
            }
          }

          if (payload.new?.id) {
            if ($appStore.crmAccount?.id === payload.new.id) {
              appStore.setCrmAccount(payload.new);
            }
            if ($appStore.crmContact?.id === payload.new.id) {
              appStore.setCrmContact(payload.new);
            }
          }
          if ($appStore.queryClient) {
            $appStore.queryClient.invalidateQueries([payload.table], {
              exact: false,
              refetchActive: true,
            });

            if (
              payload.table === 'activities' &&
              payload.new.type === 'Assignment'
            ) {
              $appStore.queryClient.invalidateQueries(
                ['assignments', payload.new.deal.id],
                {
                  exact: false,
                  refetchActive: true,
                },
              );
            }
          }
        },
      )
      .subscribe();
  }

  onMount(async () => {
    if (!$appStore.queryClient) {
      const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60 * 1000,
          },
        },
      });
      appStore.setQueryClient(queryClient);
      subcribeToChannels();
    }
  });

  onDestroy(() => {
    unsubscribe();
  });

  /*
  $: pluginMode = import.meta.env.MODE === 'plugin';
  $: linkedInDarkTheme = document
    .getElementsByTagName('html')[0]
    ?.classList?.contains('theme--dark');
    */
</script>

{#if $appStore.queryClient}
  <QueryClientProvider client={$appStore.queryClient}>
    {#if false && import.meta.env.DEV}
      <SvelteQueryDevtools initialIsOpen={false} />
    {/if}
    <div class="app bg-primary-foreground">
      <ModeWatcher track={true} />
      <Toaster class="z-[100000]" position="bottom-left" />

      <CrmApp />

      {#if $appStore.isParsing}
        <div class="ml-4 min-h-[60px]">
          <Plane size="15" color="#09D2C2" unit="px" duration="1.5s" />
        </div>
      {/if}

      <MainWidget />

      <LinkedInChecker />
      <CrmChecker linkedin={contact?.linkedin} />
      <ActivityPopup />
    </div>
  </QueryClientProvider>
{/if}

<style>
  :global(body) {
    margin: 0;
    font-family: system-ui, sans-serif;
  }

  .app {
    /* min-height: 25vh; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    z-index: 10000;
  }

  /*
    Cannot target component in CSS, target SVG
    instead using Svelte's :global() function
  */
  div :global(svg) {
    font-size: 16px;
    line-height: 1em;
  }

  /*
    Must use :global() because Svelte cannot assign style to a
    component by class name, it can only work with standard HTML tags.
  */
  div :global(.big-icon) {
    font-size: 72px;
  }
</style>
