<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import Label from '$lib/components/ui/label/label.svelte';

  export let form;
  export let readOnly = false;
  let { form: formData } = form;
</script>

<div class="p-6 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Icon icon="mdi:file-text" class="mr-2" />
    Additional Services
  </h2>
  {#if $formData.shipment}
    <div class="space-y-4">
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.extraService}
        name="Extra Service"
        label="Extra Service"
        type="Deals > Extra Service"
        {readOnly}
        multiple={false}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.insurance}
        name="Insurance"
        label="Insurance"
        type="Insurance"
        multiple={false}
        {readOnly}
      />

      {#if $formData.shipment.insurance}
        <div class="flex items-center space-x-4">
          <HantaInputNumber
            {form}
            name={'shipment.priceInInvoice'}
            label="Invoice Price"
            {readOnly}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.shipment.currencyInInvoice}
            label="Currency"
            type="Currencies"
            multiple={false}
            {readOnly}
          />

          <div class="hidden space-x-2 text-xl font-semibold text-gray-800">
            <Label>Invoice Price</Label>
            <div>
              {$formData.shipment.rate.priceInInvoiceEur}
            </div>
            <div class="text-muted-foreground">€</div>
          </div>
        </div>
      {/if}
    </div>
  {/if}
</div>
