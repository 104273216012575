<script>
  import Spreadsheet from '$lib/components/spreadsheet.svelte';

  export let dimensions;
  export let data = [];

  $: columnHeaders = [
    { title: 'Price', width: 100 },
    { title: 'Min price', width: 70 },
    { title: 'Unit price', width: 70 },
    { title: 'Unit', width: 50 },
    { title: 'Percent', width: 70 },
    ...dimensions?.map(el => ({
      type: 'dropdown',
      title: el.name,
      width: '130px',
      source: (el?.values || []).map(el =>
        el?.from ? el.from + ' - ' + el?.to : el?.name,
      ),
    })),
  ];
</script>

<Spreadsheet {columnHeaders} {data} />
