import { NumberUtils } from './utils/NumberUtils';

interface PriceItem {
  price: number;
  weight?: number;
  weightOrig?: number;
  product?: string;
  dealCurrency?: string;
}

interface PriceQuote {
  prices: (number | string)[];
}

export class PriceEvaluator {
  public getPrice(
    quote: PriceQuote,
    item: PriceItem,
    isSupplier?: boolean,
    isCustomerTarif?: boolean,
  ): number {
    const prices = quote.prices;
    const fixPrice = NumberUtils.parseValue(prices[0]);
    const minPrice = NumberUtils.parseValue(prices[1]);
    const pricePerUnit = NumberUtils.parseValue(prices[2]);
    const unit = NumberUtils.parseValue(prices[3]);
    let percents = NumberUtils.parseValue(prices[4]);

    // Handle percentage-based pricing
    if (percents !== 0 && prices[4] !== undefined) {
      if (percents < 1 && percents > 0) {
        percents = 100 * percents;
      }

      if (isCustomerTarif) {
        item.dealCurrency = percents >= 0 ? 'EUR' : '';
      }

      if (!isNaN(percents)) {
        return (1.0 - percents / 100) * item.price;
      }
    }

    // Clear deal currency for non-percentage prices in customer tariffs
    if (isCustomerTarif) {
      item.dealCurrency = '';
    }

    // Handle fixed price with per-unit calculations
    let result = fixPrice;
    if (pricePerUnit > 0 && unit > 0) {
      result = this.calculatePriceWithUnit(
        item,
        isSupplier,
        fixPrice,
        pricePerUnit,
        unit,
        prices,
      );
    }

    // Apply minimum price if specified
    return minPrice > 0 ? Math.max(minPrice, result) : result;
  }

  private calculatePriceWithUnit(
    item: PriceItem,
    isSupplier: boolean | undefined,
    fixPrice: number,
    pricePerUnit: number,
    unit: number,
    prices: (number | string)[],
  ): number {
    let weight = isSupplier ? item.weightOrig || item.weight : item.weight;
    if (!weight) return fixPrice;

    // Handle weight ranges
    const range = prices.find((val: any, index: number) =>
      this.isRange(prices, index),
    );
    if (range) {
      const minRange = parseFloat(range.split(' - ')[0]);
      if (!isNaN(minRange)) {
        weight = Math.max(0, weight - minRange);
      }
    }

    // Calculate quantity based on product type
    const quantity =
      item.product === 'Топливная надбавка' ||
      item.product === 'Надбавка за безопасность'
        ? weight / unit
        : Math.ceil(weight / unit);

    return fixPrice + quantity * pricePerUnit;
  }

  private isRange(price: any[], idxDimension: number): boolean {
    const value = price[idxDimension];
    if (typeof value === 'string' && value.includes(' - ')) {
      const [min, max] = value.split(' - ').map(parseFloat);
      return !isNaN(min) && !isNaN(max) && min >= 0 && max >= 0;
    }
    return false;
  }
}
