<script lang="ts">
  import { Checkbox as CheckboxPrimitive } from 'bits-ui';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils';

  type $$Props = CheckboxPrimitive.Props;
  type $$Events = CheckboxPrimitive.Events;

  let className: $$Props['class'] = undefined;
  export let checked: $$Props['checked'] = false;
  export { className as class };
</script>

<CheckboxPrimitive.Root
  class={cn(
    'peer box-content h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[disabled=true]:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground data-[disabled=true]:opacity-50',
    className,
  )}
  bind:checked
  {...$$restProps}
  on:click
>
  <CheckboxPrimitive.Indicator
    class={cn('flex h-4 w-4 items-center justify-center text-current')}
    let:isChecked
    let:isIndeterminate
  >
    {#if isChecked}
      <Icon icon="mdi:check" class="w-3.5 h-3.5" />
    {:else if isIndeterminate}
      <Icon icon="mdi:minus" class="w-3.5 h-3.5" />
    {/if}
  </CheckboxPrimitive.Indicator>
</CheckboxPrimitive.Root>
