<script lang="ts">
  import { getAvatar } from './../../api/queries';
  import * as HoverCard from '$lib/components/ui/hover-card/index.js';

  export let id = '';
  export let module = '';

  let item;
  $: if (module && id) {
    getAvatar(module, id).then(data => {
      item = data;
    });
  }
</script>

<HoverCard.Root>
  <HoverCard.Trigger
    rel="noreferrer noopener"
    class="rounded-sm underline-offset-4 hover:underline focus-visible:outline-2 focus-visible:outline-offset-8 focus-visible:outline-black"
  >
    <slot name="trigger" />
  </HoverCard.Trigger>
  <HoverCard.Content class="w-80">
    <div class="flex space-x-4">
      <slot name="content" />
    </div>
  </HoverCard.Content>
</HoverCard.Root>
