<script>
  import { onMount } from 'svelte';
  export let file;
  export let name;
  export let mode = 'IN_LINE';
  export let onClose = () => {};

  const loadPdf = () => {
    const adobeDCView = new AdobeDC.View({
      clientId: import.meta.env.VITE_ADOBE_VIEW_API_CLIENT_ID,
      divId: name,
    });
    adobeDCView.registerCallback(
      AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      function (event) {
        if (event.type === 'PDF_VIEWER_CLOSE') {
          console.debug('The PDF viewer was closed');
          if (onClose) {
            onClose();
          }
        }
      },
      { enablePDFAnalytics: false },
    );

    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: file,
          },
        },
        metaData: { fileName: name },
      },
      {
        embedMode: mode,
        enableFormFilling: false,
        defaultViewMode: 'FIT_HEIGHT',
      },
    );
  };
  onMount(() => {
    if (window.adobe_dc_sdk) {
      loadPdf();
      return;
    }
    // Function to load the Adobe PDF viewer script
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error('Failed to load the Adobe PDF viewer script'));
        document.head.appendChild(script);
      });
    };

    loadScript()
      .then(() => {
        // Listen for the Adobe PDF Embed API to be ready
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          loadPdf();
        });
      })
      .catch(error => {
        console.error('Error loading Adobe PDF viewer script:', error);
      });

    return () => {
      // Clean up the event listener if needed
      document.removeEventListener('adobe_dc_view_sdk.ready', () => {});
    };
  });
</script>

<div id={name}></div>
