<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import ActivityRefsPopover from '../activity-refs-popover.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';
  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import { formatRoleName, isCandidate } from '$lib/app/utils';
  import { onMount, tick } from 'svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,photo,role',
    });

    return result.data;
  };

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,photo',
    });

    return result.data;
  };

  const getRole = selection => {
    if ($formData['contacts'].find(c => c.id === selection.id))
      return 'Contact';
    if ($formData['candidates'].find(c => c.id === selection.id))
      return 'Candidate';
    return '';
  };

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);

      saveActivity($formData);
      appStore.closeActivity();
    },
  });

  let { form: formData, errors } = form;

  const onContactsChanged = value => {
    const candidates = [];
    const contacts = [];
    value.forEach(v => {
      if (isCandidate(v.role)) {
        candidates.push(v);
      } else {
        contacts.push(v);
      }
    });

    formData.update(data => ({
      ...data,
      candidates: candidates,
      contacts: contacts,
    }));
  };

  onMount(() => {
    formData.update(data => ({
      ...data,
      allContacts: [...data.contacts, ...data.candidates],
    }));
  });

  const refsChanged = async value => {
    const allContacts = [
      ...new Map(
        [...$formData.contacts, ...$formData.candidates].map(item => [
          item.id,
          item,
        ]),
      ).values(),
    ];

    await tick();

    formData.update(data => ({
      ...data,
      allContacts,
    }));
  };
</script>

<Popup {form}>
  <svelte:fragment slot="header">
    <Icon icon="mdi:chat-bubble" class="mr-4 w-5 h-5" />
    Message
  </svelte:fragment>
  <div class="flex overflow-scroll flex-col py-0 mt-4 h-full" slot="content">
    <div class="flex px-6 min-w-0 max-w-full">
      <HantaInputMultiSelect
        {form}
        label="Contacted"
        loadOptions={loadOptionsContacts}
        multiple={true}
        name="allContacts"
        onSelectChanged={onContactsChanged}
      >
        <div
          class="flex gap-1.5 items-center w-full h-full content-stretch"
          let:index
          let:item
          slot="item"
        >
          <div class="w-full">
            <CrmAvatar id={item.id} module="contacts" name={item.name} />
          </div>
          <Badge class="text-xs font-light" variant="secondary"
            >{formatRoleName(item.role) ?? ''}</Badge
          >
        </div>
        <div
          class="flex overflow-hidden gap-1.5 items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <CrmAvatar
            id={selection.id}
            module="contacts"
            name={selection.name}
          />
          <span class="text-xs font-light text-muted-foreground">
            {getRole(selection)}
          </span>
        </div>
      </HantaInputMultiSelect>
    </div>
    <div class="flex flex-col gap-6 px-6 w-full md:flex-row">
      <HantaInputDate {form} label="Date" name="startDate" withTime={true} />
      <HantaInputMultiSelect
        {form}
        label="Consultant"
        {loadOptions}
        multiple={false}
        asArray={false}
        name="consultantObj"
        placeholder="Choose consultant..."
      >
        <div
          class="flex items-center w-full h-full"
          let:index
          let:item
          slot="item"
        >
          <Avatar.Root class="size-7">
            <Avatar.Image
              class="object-scale-down rounded-none"
              src={item?.photo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>
          <div class="ml-2 text-sm truncate">{item?.name}</div>
        </div>
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <Avatar.Root class="size-7">
            <Avatar.Image
              class="object-scale-down rounded-none"
              src={selection?.photo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>
          <div class="ml-2 w-full text-sm truncate">{selection?.name}</div>
        </div>
      </HantaInputMultiSelect>
    </div>
    <hr class="mx-6 my-1 border-t border-solid border-t-primary-200/20" />
    <HantaInputTextareaRichtext
      {form}
      name="description"
      placeholder="Notes..."
      resizable={false}
      variant="ghost"
    />
    <div
      class="flex flex-row items-center mx-6 space-x-4 border-t border-solid border-t-primary-200/20"
    >
      <ActivityRefsPopover {activity} {form} onSelectChanged={refsChanged} />
    </div>
  </div>
</Popup>
