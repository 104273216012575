import { DiscountCalculator } from './DiscountCalculator';
import { NumberUtils } from './utils/NumberUtils';
import { PriceFinder } from './PriceFinder';
import { Tariff } from './prices';

export class PriceCalculator {
  private readonly manualPriceFields = [
    'price',
    'supplierEur',
    'customerPrice',
  ];
  private readonly fields = [
    'supplierPercents',
    'discountEur',
    'discountPercents',
    'discountTotalEur',
    'discountTotalPercents',
    'revenueEur',
    'revenuePercents',
    'mdl',
    'eur',
    'totalEUR',
    'totalMDL',
    'revenueTotalEur',
    'supplierTotalEur',
  ];

  constructor(
    private readonly priceFinder: PriceFinder,
    private readonly discountCalculator: DiscountCalculator,
  ) {}

  public async calculatePrice(
    item: any,
    {
      baseTarif,
      supplierTarif,
      customerTarif,
    }: { baseTarif: Tariff; supplierTarif: Tariff; customerTarif: Tariff },
    exchangesData: any[],
  ): Promise<void> {
    const standardPrice = await this.priceFinder.findPrice(baseTarif, item);

    if (!item.manualPrice) {
      item.price = standardPrice;
    }

    await this.calculateSupplierPrice(item, supplierTarif);
    await this.calculateCustomerPrice(item, customerTarif, exchangesData);
  }

  private async calculateSupplierPrice(
    item: any,
    supplierTarif: any,
  ): Promise<void> {
    if (supplierTarif) {
      const supplierPrice = await this.priceFinder.findPrice(
        supplierTarif,
        item,
        true,
      );
      if (!item.manualPrice) {
        item.supplierEur = supplierPrice;
      }
      if (item.price > 0) {
        item.supplierPercents = item.supplierEur / item.price;
      }
    }
  }

  private async calculateCustomerPrice(
    item: any,
    customerTarif: any,
    exchangesData: any[],
  ): Promise<void> {
    if (customerTarif) {
      let customerPrice = await this.priceFinder.findPrice(
        customerTarif,
        item,
        false,
        true,
      );

      customerPrice = this.adjustCustomerPrice(
        customerPrice,
        customerTarif,
        item,
        exchangesData,
      );

      this.assignCustomerPrice(item, customerPrice);
      this.discountCalculator.calculateDiscounts(item);
    }
  }

  private adjustCustomerPrice(
    customerPrice: number,
    customerTarif: any,
    item: any,
    exchangesData: any[],
  ): number {
    if (!customerTarif.currency) {
      item.dealCurrency = 'EUR';
      return customerPrice;
    }

    if (customerPrice > 0 && exchangesData) {
      const day = exchangesData.find(d => d.name === 'EUR - Euro');
      if (day) {
        if (customerTarif.currency === 'AZN' && item.dealCurrency !== 'EUR') {
          customerPrice = Math.floor((customerPrice / day.mdl2eur) * 100) / 100;
          item.dealCurrency = 'AZN';
        } else {
          item.dealCurrency = 'EUR';
        }
      } else {
        customerPrice = NaN;
      }
    }
    return customerPrice;
  }

  private assignCustomerPrice(item: any, customerPrice: number): void {
    if (!item.manualPrice) {
      if (Number.isNaN(customerPrice)) {
        item.price = 0;
        item.customerPrice = 0;
      } else {
        item.customerPrice = customerPrice === 0 ? item.price : customerPrice;
        if (customerPrice === 0) {
          item.dealCurrency = 'EUR';
        }
      }
    }
  }

  public resetPrices(item: any): void {
    if (!item.manualPrice) {
      this.resetFields(item, this.manualPriceFields);
    }
    this.resetFields(item, this.fields);

    if (item.services) {
      item.services.forEach((service: any) => {
        if (!service.manualPrice) {
          this.resetFields(service, this.manualPriceFields);
        }
        this.resetFields(service, this.fields);
      });
    }
  }

  public roundPrices(item: any): void {
    if (item.services) {
      item.services.forEach((service: any) => {
        this.roundFields(service, this.manualPriceFields);
        this.roundFields(service, this.fields);
      });
    }
    this.roundFields(item, this.manualPriceFields);
    this.roundFields(item, this.fields);
  }

  private resetFields(item: any, fields: string[]): void {
    fields.forEach(field => (item[field] = 0));
  }

  private roundFields(item: any, fields: string[]): void {
    fields.forEach(
      field => (item[field] = NumberUtils.roundValue(item[field])),
    );
  }
}
