<script lang="ts">
  import { Select as SelectPrimitive } from 'bits-ui';
  import { cn } from '$lib/utils';
  import Icon from '@iconify/svelte';
  type $$Props = SelectPrimitive.TriggerProps & {
    hideChevron?: boolean;
  };

  type $$Events = SelectPrimitive.TriggerEvents;

  let className: $$Props['class'] = undefined;
  export let hideChevron: $$Props['hideChevron'] = undefined;

  export { className as class };
</script>

<SelectPrimitive.Trigger
  {...$$restProps}
  class={cn(
    'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
    className,
  )}
  let:builder
  on:click
  on:keydown
>
  <slot {builder} />
  {#if hideChevron !== true}
    <div>
      <Icon icon="mdi:chevron-down" class="opacity-50 size-4" />
    </div>
  {/if}
</SelectPrimitive.Trigger>
