<script lang="ts">
  import Card from './../../../components/ui/card/card.svelte';
  import { createQuery } from '@tanstack/svelte-query';
  import { invoiceStatistics } from '$lib/api/invoice-api';
  import InvoicesChart from './invoices-chart.svelte';
  import InvoicesResults from './invoices-results.svelte';
  import { derived, writable } from 'svelte/store';
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from '$lib/components/ui/dialog';
  import InvoicesTable from './invoices-table.svelte';

  export let start: Date;
  export let end: Date;

  const startStore = writable(start);
  const endStore = writable(end);

  $: $startStore = start;
  $: $endStore = end;

  let showDialog = false;
  let selectedStatus: string | null = null;

  const INVOICE_STATUS_COLORS = {
    Draft: 'hsl(270, 60%, 60%)',
    Open: 'hsl(210, 100%, 50%)',
    Paid: 'hsl(120, 60%, 50%)',
    Uncollectable: 'hsl(30, 100%, 50%)',
    Void: 'hsl(0, 100%, 50%)',
    Overdue: 'hsl(200, 50%, 50%)',
    Cancelled: 'hsl(0, 0%, 50%)',
  };

  const invoiceStatisticsQuery = createQuery(
    derived([startStore, endStore], ([$start, $end]) => ({
      queryKey: ['invoices', 'statistics', $startStore, $endStore],
      queryFn: async ({}) => {
        const data = await invoiceStatistics($startStore, $endStore);
        return processInvoiceData(data);
      },
    })),
  );

  $: ({ data, isLoading, isError, error } = $invoiceStatisticsQuery);

  function processInvoiceData(data) {
    return Object.keys(INVOICE_STATUS_COLORS).map(status => {
      const found = data?.find(el => el.status === status);
      return {
        status,
        count: found?.count || 0,
        amount: found?.amount || 0,
        color: INVOICE_STATUS_COLORS[status],
      };
    });
  }

  function handleInvoiceClick(
    event: CustomEvent<{ status: string; count: number; amount: number }>,
  ) {
    const { status } = event.detail;
    selectedStatus = status;
    showDialog = true;
  }
</script>

<Card class="mb-4 max-w-lg">
  <div class="flex p-2">
    <div>
      <h2 class="mb-2 text-lg font-semibold">Invoices</h2>
      {#if isLoading}
        <p>Loading invoice data...</p>
      {:else if isError}
        <p>Error loading invoice data: {error.message}</p>
      {:else if data}
        <InvoicesChart {data} statusColors={INVOICE_STATUS_COLORS} />
        <InvoicesResults {data} on:click={handleInvoiceClick} />
      {/if}
    </div>
  </div>
</Card>

<Dialog bind:open={showDialog}>
  <DialogContent class="max-w-4xl">
    <DialogHeader>
      <DialogTitle>
        Invoices - {selectedStatus}
      </DialogTitle>
    </DialogHeader>
    {#if selectedStatus}
      <div class="max-h-[80vh] overflow-auto">
        <InvoicesTable
          filter={{
            status: selectedStatus,
            startDate: $startStore,
            endDate: $endStore,
          }}
        />
      </div>
    {/if}
  </DialogContent>
</Dialog>
