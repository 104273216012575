import QRCode from 'qrcode';
import { type Order, type Invoice, InvoiceWithRefs } from '$db/schema';
import { currencyFormatter } from '$lib/app/utils';

type Address = {
  street: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;
};

type Customer = {
  name: string;
  logo: string;
  address: Address;
};

type Recipient = {
  id: string;
  name: string;
};

type Position = {
  text: string | null;
  unit: 'Stunden' | 'pauschal' | 'Stück';
  quantity: string;
  price: string;
  tax: string;
  sum: string;
};

type Payment = {
  paymentReceipt: string;
  total: string;
  date: string;
  gross: string;
  debit: string;
  differenceReason: string;
};

export type InvoiceForPrint = {
  createdOn: string;
  gross: string | null;
  debit: string | null;
  vat: string | null;
  net: string | null;
  vatInPercent: string | null;
  delivery: 'E-Mail' | 'Letter';
  paymentDeadline: Date | null;
  fixedOn: string | null;
  withQrCode: boolean | null;
  withVat: boolean | null;
  invoiceId: string | null;
  subject: string | null;
  customer: Customer;
  recipient: Recipient;
  positions: Position[];
  ordersRefs: Order[];
  introduction: string | null;
  conclusion: string | null;
  qrCode: string | null;
};

const format: (value: number) => string = currencyFormatter().format;
const formatNational: (value: number) => string =
  currencyFormatter('MDL').format;

export const formatDate = (value: Date): string => {
  if (!value) return '';
  const date = new Date(value);

  let userLocale = 'de-DE';
  return date.toLocaleDateString(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getQrCode = (invoice: Invoice): string => {
  const tenantInfo = {
    bankdaten: {
      bic: 'PBNKDEFF',
      zahlungsempfaenger: 't-next GmbH',
      iban: 'DE52440100460354436460',
    },
  };

  let qrCode = window.location.href;
  qrCode =
    'BCD\n' +
    '001\n' +
    '1\n' +
    'SCT\n' +
    tenantInfo.bankdaten.bic +
    '\n' +
    tenantInfo.bankdaten.zahlungsempfaenger +
    '\n' +
    tenantInfo.bankdaten.iban +
    '\n' +
    'EUR' +
    invoice.gross +
    '\n' +
    '\n' +
    '\n' +
    invoice.invoiceId +
    '\n';
  return qrCode;
};

export const generateQR = async (qrCodeData: any) => {
  return new Promise<any>((resolve, reject) => {
    QRCode.toDataURL(qrCodeData, (err, qrCode) => {
      if (err) {
        reject(err);

        console.error('QR-Code generation failed!', err);

        return;
      }
      resolve(qrCode);
    });
  });
};

export const calculatePosition = (position: {
  quantity: number;
  price: number;
  tax: number;
  unit: string;
}): { net: number; tax: number; sum: number; unit: string } => {
  if (!position) {
    return { net: 0, tax: 0, sum: 0, unit: '' };
  }

  const withoutTax =
    position.unit === 'pauschal'
      ? position.price
      : position.quantity * position.price;
  const tax = withoutTax * (position.tax / 100.0);
  return {
    net: withoutTax,
    tax: tax,
    sum: withoutTax + tax,
    unit: position.unit,
  };
};

export const calculateSum = (
  invoice: InvoiceWithRefs,
): { sum: number; tax: number; net: number; sumNational: number } => {
  const result = {
    sum: 0,
    tax: 0,
    net: 0,
    sumNational: 0,
  };

  (invoice?.positions || []).map(position => {
    result.sum += calculatePosition(position).sum || 0;
    result.tax += calculatePosition(position).tax || 0;
    result.net += calculatePosition(position).net || 0;
    result.sumNational += calculatePosition(position).sum || 0;
  });

  (invoice?.ordersRefs || []).map(order => {
    // orderToSave.totalAmountNational = order.shipment?.totalNational;
    // orderToSave.totalAmountEUR = order.shipment?.totalEUR;

    result.sumNational += order.shipment?.totalNational || 0;
    result.sum += order.shipment?.totalEUR || 0;
    result.tax += 0; // TODO: add tax from rate
    result.net += order.shipment?.totalEUR || 0; // TODO: add net from rate
  });

  return result;
};

export const updateValuesAndPositions = (invoice: Invoice) => {
  const calculated = calculateSum(invoice);
  invoice.gross = calculated.sum;
  invoice.debit = calculated.sum;
  invoice.vat = calculated.tax;
  invoice.net = calculated.net;

  invoice.positions = invoice.positions.map(position => {
    const calculated = calculatePosition(position);
    position.sum = calculated.net;
    return position;
  });
};

export const createCreditNoteForInvoice = (invoice: Invoice): Invoice => {
  const creditNote: Invoice = JSON.parse(JSON.stringify(invoice));

  delete creditNote.id;
  delete creditNote.invoiceId;
  delete creditNote.fixedOn;
  delete creditNote.createdAt;
  delete creditNote.paymentDeadline;

  creditNote.status = 'Stornorechnung';
  creditNote.customer = creditNote.customer?.id;
  creditNote.recipient = creditNote.recipient?.id;
  creditNote.name = invoice.invoiceId; //'Credit Note No. 2023-7 for Invoice No. 2023-6';

  creditNote.positions = creditNote.positions.map(position => {
    position.sum = -calculatePosition(position).net;
    return position;
  });

  creditNote.gross = -creditNote.gross;
  creditNote.debit = -creditNote.debit;
  creditNote.vat = -creditNote.vat;
  creditNote.net = -creditNote.net;

  return creditNote;
};

export const createPrintDataForInvoice = (
  invoice: Invoice,
): InvoiceForPrint => {
  const calculated = calculateSum(invoice);

  const result: InvoiceForPrint = {
    name: invoice.name,
    invoiceDate: formatDate(invoice.invoiceDate),
    gross: format(calculated.sum),
    debit: format(invoice.debit),
    vat: format(calculated.tax),
    net: format(calculated.net),
    netNational: calculated.sumNational,
    vatInPercent: invoice.vatInPercent ? invoice.vatInPercent + '%' : '0%',
    delivery: invoice.delivery,
    paymentDeadline: invoice.paymentDeadline,
    fixedOn: formatDate(invoice.fixedOn),
    withQrCode: invoice.withQrCode,
    withVat: invoice.withVat,
    invoiceId: invoice.invoiceId,
    subject: invoice.subject,
    customer: invoice.customerObj,
    recipient: invoice.recipientObj,
    payments: invoice.payments,
    introduction: invoice.introduction,
    conclusion: invoice.conclusion,
    qrCode: getQrCode(invoice),
  };

  if (invoice.positions) {
    result.positions = invoice.positions.map(position => {
      const calculated = calculatePosition(position);
      return {
        text: position.text,
        unit: position.unit,
        quantity: position.unit !== 'pauschal' ? position.quantity : '',
        price: format(position.price),
        tax: format(calculated.tax),
        sum: format(calculated.net),
      };
    });
  }
  if (invoice.ordersRefs) {
    result.ordersRefs = invoice.ordersRefs.map(order => ({
      ...order,
      orderDate: formatDate(order.orderDate),
    }));
  }
  return result;
};
