<script lang="ts">
  import Select from 'svelte-select';
  import { cn } from '$lib/utils';
  import Label from '$lib/components/ui/label/label.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';

  export let value = undefined;

  export let readOnly = false;
  export let label = undefined;
  export let description = '';
  export let placeholder = '';
  export let simpleMode = false; // if true, the value is a simple array of strings
  export let multiple = true; // if true, the value is an array
  export let options = []; // if provided, the options are static
  export let disabled = false;
  export let asArray = false;
  export let loadOptions: ((keyword: string) => any) | undefined = () => [];
  export let onSelectChanged = () => {};

  let items: any[];

  type $$Props = {
    value: any;
    readOnly?: boolean;
    variant?: 'default' | 'light';
    label?: string;
    description?: string;
    placeholder?: string;
    simpleMode?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    options?: any[];
    class?: string;
    asArray?: boolean;
    loadOptions?: ((keyword: string) => any) | undefined;
    onSelectChanged?: (e) => void;
  };

  let className: $$Props['class'] = 'w-full';
  export { className as class };

  const onSelectChangedFn = async e => {
    value = multiple
      ? e.detail.map(item => item.value)
      : asArray
        ? [e.detail.value]
        : e.detail.value;
    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    console.debug('onSelectChanged', e, value);
    onSelectChanged(value);
  };

  const onSelectClear = async e => {
    const vals = e.detail;

    if (Array.isArray(vals)) {
      value = value?.filter(a => !vals.find(v => v.id === a.id));
    } else {
      value =
        multiple || asArray ? value?.filter(a => a.id !== vals.id) : undefined;
    }

    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    console.debug('onSelectChanged accounts removed', value);
  };

  function isSelected(item) {
    return multiple || asArray
      ? !value?.find(a => a.id === item.id)
      : value !== item.id;
  }

  $: loadOptionsInternal = async query => {
    if (options && options.length > 0) {
      return options;
    }

    if (!loadOptions) return [];

    let result = await loadOptions(query);
    return result?.map(item => ({
      ...item,
      value: item,
      selectable: isSelected(item),
    }));
  };

  $: value = multiple
    ? value?.map(a => (simpleMode ? a : { value: a, ...a })) || []
    : asArray
      ? (value && value[0]) || undefined
      : value;
  let svelteSelect: Select;
</script>

<div class={cn('grid-cols-1 grid-rows-1 gap-y-2 grid', className)}>
  {#if label}
    <Label class="flex flex-row gap-2 items-center">
      <slot name="icon" />
      {label}
    </Label>
  {/if}
  {#if !readOnly}
    <Select
      bind:this={svelteSelect}
      {disabled}
      items={options && options.length > 0 ? options : items}
      itemId="value"
      {multiple}
      loadOptions={simpleMode ? undefined : loadOptionsInternal}
      on:change={onSelectChangedFn}
      on:clear={onSelectClear}
      placeholder={placeholder || label}
      {value}
    >
      <svelte:fragment let:index let:item slot="item">
        {#if $$slots.item}
          <slot {index} {item} name="item" />
        {:else}
          <div class="flex items-center h-full">
            <div class="text-sm">{item?.label}</div>
          </div>
        {/if}
      </svelte:fragment>

      <svelte:fragment let:selection slot="selection">
        {#if $$slots.selection}
          <slot name="selection" {selection} />
        {:else}
          <div class="flex items-center h-full">
            <div class="ml-2 text-sm">{selection?.label}</div>
          </div>
        {/if}
      </svelte:fragment>
    </Select>
  {:else if $$slots.selection}
    <slot name="selection" selection={value} />
  {:else}
    <div class="flex items-center h-full">
      {#each Array.isArray(value) ? value : [value] as item, index}
        <Badge class="ml-2 text-xs truncate">{item || 'n/a'}</Badge>
      {/each}
    </div>
  {/if}

  {#if description}
    <div>{description ?? ''}</div>
  {/if}
</div>

<style lang="postcss">
  :global(.svelte-select .value-container) {
    flex-shrink: 0;
  }

  :global(.svelte-select) {
    border: solid 1px theme(colors.gray.200) !important;
    @apply !border-primary/20 hover:!bg-muted transition-all !px-2 shrink-0 border border-muted hover:border-primary/20;
    --multi-select-padding: 0;
    --multi-select-border: solid 1px theme(colors.gray.200);
    --border-hover: solid 1px theme(colors.gray.200);
    --border: solid 1px theme(colors.gray.200);
    --border-radius: 3px;
    --multi-item-border-radius: 3px;
    --multi-item-bg: #fff;
    --item-hover-bg: theme(colors.muted.DEFAULT);
  }

  :global(.svelte-select input::placeholder) {
    @apply px-2;
  }
  :global(.svelte-select input:focus, .svelte-select input:hover) {
    box-shadow: unset;
  }

  :global(.svelte-select input) {
    --tw-ring-shadow: 0 0 #000 !important;
    --placeholder-color: theme(colors.primary.DEFAULT);
    @apply !text-sm font-medium !text-primary;
    box-shadow: unset;
  }

  :global(.svelte-select:hover) {
    border: none;
  }
</style>
