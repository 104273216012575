import { DimensionService } from './DimensionService';
import { DiscountCalculator } from './DiscountCalculator';
import { ServicePreparator } from './ServicePreparator';
import { ExchangeRateService } from './ExchangeRateService';
import { MainPriceService } from './MainPriceService';
import { PriceCalculator } from './PriceCalculator';
import { PriceEvaluator } from './PriceEvaluator';
import { PriceFinder } from './PriceFinder';
import { TotalsCalculator } from './TotalsCalculator';

export class PriceServiceFactory {
  public static create(): MainPriceService {
    const dimensionService = new DimensionService();
    const priceEvaluator = new PriceEvaluator();
    const priceFinder = new PriceFinder(dimensionService, priceEvaluator);
    const discountCalculator = new DiscountCalculator();

    const priceCalculator = new PriceCalculator(
      priceFinder,
      discountCalculator,
    );

    return new MainPriceService(
      new ExchangeRateService(),
      priceCalculator,
      new ServicePreparator(),
      new TotalsCalculator(),
    );
  }
}
