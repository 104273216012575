<script lang="ts">
  import { getTariff, getExchangeRates } from '$lib/api/queries';
  import { createQuery } from '@tanstack/svelte-query';
  import PriceServiceCalculate from './price-service-calculate.svelte';

  export let lead;
  export let supplierTarifId;
  export let customerTarifId;
  export let baseTarifId;
  export let refDate;
  export let weight;
  export let diff = 0;
  export let withMdl = true;
  export let currency = 'EUR';

  export let showPrice = false;

  const defaultSupplierTarifId = 'c1eba30a-15d5-4967-9951-79a4e571ab18';
  const defaultCustomerTarifId = 'c1eba30a-15d5-4967-9951-79a4e571ab18';
  const defaultBaseTarifId = 'c1eba30a-15d5-4967-9951-79a4e571ab18';

  $: lookupCustomerTarifId = customerTarifId || defaultCustomerTarifId;
  $: lookupSupplierTarifId = supplierTarifId || defaultSupplierTarifId;
  $: lookupBaseTarifId = baseTarifId || defaultBaseTarifId;

  // Queries for tariffs
  $: supplierTarifQuery = createQuery({
    queryKey: ['tariff', lookupSupplierTarifId],
    queryFn: () => getTariff(lookupSupplierTarifId),
    enabled: !!lookupSupplierTarifId,
  });

  $: customerTarifQuery = createQuery({
    queryKey: ['tariff', lookupCustomerTarifId],
    queryFn: () => getTariff(lookupCustomerTarifId),
    enabled: !!lookupCustomerTarifId,
  });

  $: baseTarifQuery = createQuery({
    queryKey: ['tariff', lookupBaseTarifId],
    queryFn: () => getTariff(lookupBaseTarifId),
    enabled: !!lookupBaseTarifId,
  });

  // Query for exchange rates
  $: exchangeRatesQuery = createQuery({
    queryKey: ['day', refDate],
    queryFn: () => getExchangeRates(refDate),
    enabled: !!refDate,
  });
</script>

{#if $supplierTarifQuery.isSuccess && $customerTarifQuery.isSuccess && $baseTarifQuery.isSuccess && ((refDate && $exchangeRatesQuery.isSuccess) || !refDate)}
  <PriceServiceCalculate
    bind:lead
    supplierTarif={$supplierTarifQuery.data}
    customerTarif={$customerTarifQuery.data}
    baseTarif={$baseTarifQuery.data}
    exchangesData={$exchangeRatesQuery.data}
    {showPrice}
    {weight}
    {diff}
    {withMdl}
    {currency}
  />
{/if}
