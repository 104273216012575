<script lang="ts">
  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';

  export let form;
  export let isLocked;
</script>

<div
  class="p-6 mt-6 text-left bg-opacity-50 rounded border border-solid shadow-xs bg-primary-800"
>
  <HantaInputTextArea
    {form}
    name="conclusion"
    placeholder="Enter conclusion..."
    readOnly={isLocked}
  />
</div>
