<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountInvoices } from '$lib/api/invoice-api';
  import InvoiceStatus from '$lib/components/hanta/invoice-status.svelte';

  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { format } from 'date-fns';
  import { appStore } from '$lib/app-store';
  import { formatCurrency } from '$lib/utils';

  export let accountId: string;

  const invoicesQuery = createQuery({
    queryKey: ['accountInvoices', accountId],
    queryFn: () => getAccountInvoices(accountId),
    enabled: !!accountId,
  });

  $: ({ data: invoices, isLoading, isError, error } = $invoicesQuery);
</script>

{#if isLoading}
  <p>Loading invoices...</p>
{:else if isError}
  <p>Error loading invoices: {error.message}</p>
{:else if invoices && invoices.length > 0}
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Actions</TableHead>
        <TableHead>Invoice Number</TableHead>
        <TableHead>Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead class="text-right">Amount</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each invoices as invoice}
        <TableRow>
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                appStore.select({
                  id: invoice.id,
                  module: 'invoices',
                });
                appStore.openPopup(true);
              }}
            >
              View
            </Button>
          </TableCell>
          <TableCell>{invoice.invoiceId}</TableCell>
          <TableCell
            >{format(new Date(invoice.createdAt), 'dd MMM yyyy')}</TableCell
          >
          <TableCell>
            <InvoiceStatus status={invoice.status} />
          </TableCell>
          <TableCell class="text-right"
            >{formatCurrency(invoice.gross)}</TableCell
          >
        </TableRow>
      {/each}
    </TableBody>
  </Table>
{:else}
  <p>No invoices found for this account.</p>
{/if}
