<script lang="ts">
  import WorldMap from '$lib/components/hanta/statistics/world.svelte';
  import { getCustomerOrderMetrics } from '$lib/api/aggregations';
  import { onMount } from 'svelte';

  export let accountId: string;
  export let date_from: string;
  export let date_to: string;
  export let field: 'order_count' | 'total_sales' | 'total_weight' =
    'order_count';

  let mapData: Record<string, number> = {};
  let isLoading = false;

  async function fetchMapData() {
    if (!accountId || isLoading) return;

    isLoading = true;
    try {
      const data = await getCustomerOrderMetrics({
        customer_uuid: accountId,
        time_group: '',
        criteria: 'country',
        date_from,
        date_to,
      });

      // Create a new object to trigger reactivity
      const newMapData = data.reduce(
        (acc, item) => {
          acc[item.criteria_value] =
            field === 'order_count'
              ? item.order_count
              : field === 'total_weight'
                ? item.total_weight
                : item.total_amount_eur;
          return acc;
        },
        {} as Record<string, number>,
      );

      // Assign the new object to trigger reactivity
      mapData = { ...newMapData };
    } catch (error) {
      console.error('Error fetching map data:', error);
      mapData = {}; // Reset on error
    } finally {
      isLoading = false;
    }
  }

  // Watch for all relevant prop changes
  $: {
    if (accountId && date_from && date_to && field) {
      fetchMapData();
    }
  }

  // Initial fetch
  onMount(() => {
    if (accountId && date_from && date_to) {
      fetchMapData();
    }
  });
</script>

<div class="relative h-[400px]">
  <!-- Added fixed height -->
  <div
    class="h-full transition-opacity duration-300"
    class:opacity-50={isLoading}
  >
    <WorldMap data={mapData} />
  </div>

  {#if isLoading}
    <div
      class="flex absolute inset-0 justify-center items-center bg-background/50"
    >
      <div class="text-sm text-muted-foreground">Loading...</div>
    </div>
  {/if}
</div>

<style>
  div {
    width: 100%;
  }
</style>
