<script lang="ts">
  import Icon from '@iconify/svelte';
  export let address;

  $: addressLink = [address?.street, address?.city, address?.country]
    .filter(el => el)
    .join(', ');
</script>

{#if addressLink}
  <div class="flex space-x-2">
    <a
      class="flex no-underline text-primary/80 hover:text-primary/80 visited:text-primary/80"
      href={`https://www.google.de/maps/search/${addressLink}`}
      target="_blank"
    >
      <Icon icon="mdi:map" class="mr-2 size-4" />
      {addressLink}
    </a>
  </div>
{/if}
