<script>
  import { Button } from '$lib/components/ui/button';
  import { onMount } from 'svelte';
  import { getCountries } from '$lib/api/queries';

  import Spreadsheet from '$lib/components/spreadsheet.svelte';

  export let data = [];
  const lovType = 'Countries';
  let options;

  onMount(async () => {
    const result = await getCountries();

    if (result) {
      options = result.map(el => el.name);
    }
  });

  $: columnHeaders = [
    { title: 'Country', type: 'dropdown', width: 300, source: options },
    { title: 'Zone TNT', width: 70 },
    { title: 'Zone TNT Eco', width: 70 },
    { title: 'Zone FedEx', width: 70 },
  ];
</script>

{#if options?.length}
  <Button
    variant="outline"
    class="mb-2"
    on:click={() => {
      data = [['Germany', -1, -1, -1], ...data];
    }}>Add Exception</Button
  >
  {#key data?.length}
    <Spreadsheet {columnHeaders} {data} />
  {/key}
{/if}
