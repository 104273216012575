<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import Icon from '@iconify/svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Drawer from '$lib/components/ui/drawer';

  import Filters from './filters.svelte';

  export let collection: string = 'accounts';
  export let filters = [];
  let open = false;

  const dispatch = createEventDispatcher();

  function onCancel() {
    open = false;
  }
  function onApplyFilters(filters) {
    console.debug('onApplyFilters', filters);
    dispatch('filters', filters);
    open = false;
  }
</script>

<Drawer.Root bind:open>
  <Drawer.Trigger asChild let:builder>
    <Button builders={[builder]} variant="outline" class="space-x-2 text-xs">
      <Icon icon="mdi:filter" size="14" />
      <span>Filter</span>
    </Button>
  </Drawer.Trigger>
  <Drawer.Content class="z-[10000] ">
    <div class="relative mx-auto w-full max-w-4xl">
      <Drawer.Header>
        <Drawer.Title>Filters</Drawer.Title>
        <Drawer.Description>Add fields to filter your data.</Drawer.Description>
      </Drawer.Header>
      <Filters
        {collection}
        {filters}
        on:cancel={onCancel}
        on:filters={event => onApplyFilters(event.detail)}
      />
    </div>
  </Drawer.Content>
</Drawer.Root>
