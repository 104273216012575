<script lang="ts">
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import { Button } from '$lib/components/ui/button';
  import { deleteItem } from '$lib/api/mutations';
  import { getItemById } from '$lib/api/queries';
  import { toast } from 'svelte-sonner';

  export let module;
  export let id;
  export let open = false;
  export let onDeleteFn = () => {};

  async function onDeleteItem() {
    if (module && id) {
      try {
        await deleteItem(module, id);
        toast.info('Item deleted successfully!');
        open = false;
        onDeleteFn();
      } catch (error) {
        toast.error('Error deleting item: ' + error.message);
      }
    }
  }
</script>

{#await getItemById(module, id)}
  <div>Loading...</div>
{:then item}
  <AlertDialog.Root bind:open>
    <AlertDialog.Content class="z-[55]">
      <AlertDialog.Header>
        <AlertDialog.Title>Are you absolutely sure?</AlertDialog.Title>
        <AlertDialog.Description>
          {#if item?.deleted}
            This action cannot be undone.
          {:else}
            Move this item to the trash
          {/if}
        </AlertDialog.Description>
      </AlertDialog.Header>
      <AlertDialog.Footer>
        <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
        <AlertDialog.Action asChild>
          <Button variant="destructive" on:click={onDeleteItem}>Delete</Button>
        </AlertDialog.Action>
      </AlertDialog.Footer>
    </AlertDialog.Content>
  </AlertDialog.Root>
{:catch error}
  <div>Error: {error.message}</div>
{/await}
