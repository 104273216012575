import { ContactRoleType } from '$db/schema';
import { format, parseISO } from 'date-fns';

import { getLocalTimeZone, now } from '@internationalized/date';

export const getFlagEmoji = (countryCode: string) => {
  if (
    !countryCode ||
    typeof countryCode !== 'string' ||
    countryCode.length !== 2
  ) {
    console.warn('Invalid country code:', countryCode);
    return '';
  }
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export function isValidUUID(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
] as const;

export function formatDateForPrint(dateString: string) {
  return new Date(dateString).toLocaleDateString(undefined, {
    day: '2-digit',
    year: 'numeric',
    month: '2-digit',
  });
}

export function formatDate(dateString) {
  if (!dateString) return 'Invalid date';
  const date = parseISO(dateString);
  return format(date, 'd. MMM yyyy, HH:mm');
}

const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
});

export function formatTimeAgo(date: Date) {
  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

export const currencyFormatter = (
  currency: 'EUR' | 'USD' | 'MDL' = 'EUR',
): Intl.NumberFormat =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
  });

export const getNowAsLocalIsoString = (): string => {
  const nowDate = now();
  const localDate = nowDate.toDate(getLocalTimeZone());
  const offset = localDate.getTimezoneOffset();
  const localIsoDate = new Date(localDate.getTime() - offset * 60000);
  return localIsoDate.toISOString().slice(0, -1);
};

export const formatRoleName = (role: string) => {
  switch (role) {
    case ContactRoleType.enum.ContactAndCandidate:
      return 'Contact & Candidate';
    default:
      return role;
  }
};

export const isContact = (role: string): boolean => {
  const enums = ContactRoleType.enum;
  switch (role) {
    case enums.Contact:
    case enums.ContactAndCandidate:
      return true;
    default:
      return false;
  }
};

export const isCandidate = (role: string): boolean => {
  const enums = ContactRoleType.enum;
  switch (role) {
    case enums.Candidate:
    case enums.ContactAndCandidate:
      return true;
    default:
      return false;
  }
};
