<script lang="ts">
  import ContactFactSheetForm from './../../widgets/contacts/contact-fact-sheet-form.svelte';

  import DealsWidget from '$lib/widgets/deals-widget.svelte';
  import * as Tabs from '$lib/components/ui/tabs';

  import Activities from '$lib/pages/activities/activities.svelte';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';

  import { type ActivityWithRefs, type ContactWithRefs } from '$db/schema';
  import ContactsDeals from './contact-deals.svelte';
  import CandidateFactSheetForm from '$lib/widgets/contacts/candidate-fact-sheet-form.svelte';

  import { isCandidate, isContact } from '$lib/app/utils.js';
  import ContactCardShort from './contact-card-short.svelte';
  import ContactCard from './contact-card.svelte';
  import { appStore } from '$lib/app-store';

  import AdobeSupabasePreview from '$lib/components/hanta/files/adobe-supabase-preview.svelte';

  type $$Props = {
    crmContact: ContactWithRefs;
    contact: any;
    isPluginMode: boolean;
  };

  export let crmContact: ContactWithRefs;
  export let isPluginMode = false;
  export let currentTab = $appStore.isMobile ? 'about' : 'overview';
  let refresh = 0;
  let refreshPreview = 0;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [],
    deals: [],
    candidates: isCandidate(crmContact?.role) ? [crmContact] : [],
    contacts: isCandidate(crmContact?.role) ? [] : [crmContact],
  };

  function normalize(value) {
    return value === null || value === '' || value === undefined ? null : value;
  }

  export function getUnequalProperties(obj1, obj2, properties) {
    const unequalProperties = [];
    for (const property of properties) {
      if (normalize(obj1[property]) !== normalize(obj2[property])) {
        unequalProperties.push(property);
      }
    }
    return unequalProperties;
  }

  function onRefreshPreview() {
    refreshPreview = refreshPreview + 1;
  }
</script>

<PageWithTitle {isPluginMode} withSidebar={!!crmContact?.createdAt}>
  <div class="flex w-full space-x-2" slot="short-card">
    <ContactCardShort {crmContact} {activity} />
  </div>

  <div slot="card">
    <ContactCard {crmContact} {activity} {isPluginMode} />
  </div>

  <div slot="content">
    <Tabs.Root class="flex flex-col mt-4" bind:value={currentTab}>
      <Tabs.List class="flex justify-start w-fit">
        {#if $appStore.isMobile}
          <Tabs.Trigger value="about">About</Tabs.Trigger>
        {/if}
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
        {#if isCandidate(crmContact?.role)}
          <Tabs.Trigger value="profile">Profile</Tabs.Trigger>
        {/if}
        <!--<Tabs.Trigger value="profile">Profile</Tabs.Trigger>-->
      </Tabs.List>

      {#if $appStore.isMobile}
        <Tabs.Content value="about" class="text-left">
          {#key refresh}
            {#if isCandidate(crmContact?.role)}
              <CandidateFactSheetForm bind:crmContact />
            {:else}
              <ContactFactSheetForm bind:crmContact />
            {/if}
          {/key}
        </Tabs.Content>
      {/if}

      <Tabs.Content value="profile" class="h-screen ">
        {#if currentTab === 'profile'}
          <Tabs.Root class="flex-col mt-4 " value="cv">
            <Tabs.Trigger value="cv">CV</Tabs.Trigger>
            <Tabs.Trigger value="profile">Profile</Tabs.Trigger>

            <Tabs.Content value="cv" class="h-screen ">
              <Files
                title=""
                noFilesFoundLabel=""
                folder={`contacts/${crmContact?.id}/cv`}
                compact={true}
                singleFileMode={true}
                singleFileName="cv.pdf"
                onUpload={onRefreshPreview}
              />
              {#key refreshPreview}
                <AdobeSupabasePreview
                  folder={`contacts/${crmContact?.id}/cv`}
                  filename={`cv.pdf`}
                />
              {/key}
            </Tabs.Content>
            <Tabs.Content value="profile" class="h-screen ">
              <Files
                title=""
                noFilesFoundLabel=""
                folder={`contacts/${crmContact?.id}/profile`}
                compact={true}
                singleFileMode={true}
                singleFileName="profile.pdf"
                onUpload={onRefreshPreview}
              />
              {#key refreshPreview}
                <AdobeSupabasePreview
                  folder={`contacts/${crmContact?.id}/profile`}
                  filename={`profile.pdf`}
                />
              {/key}
            </Tabs.Content>
          </Tabs.Root>
        {/if}
      </Tabs.Content>

      <Tabs.Content class="h-full overflow-scroll" value="overview">
        <div
          class="p-4 px-4 py-5 mb-8 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
        >
          <Files folder={`contacts/${crmContact?.id}`} compact={true} />
        </div>

        {#if isCandidate(crmContact?.role)}
          <div class="mb-8">
            <ContactsDeals contact={crmContact} />
          </div>
        {/if}
        {#if isContact(crmContact?.role)}
          {#key crmContact.id + ' ' + crmContact?.deals?.length}
            <DealsWidget
              label="Contact for deals"
              deals={crmContact?.deals || []}
              customerId={crmContact?.customer?.id}
              contactId={crmContact?.id}
            />
          {/key}
        {/if}
      </Tabs.Content>

      <Tabs.Content value="timeline">
        <Activities
          id={crmContact?.id}
          module={isCandidate(crmContact?.role) ? 'candidates' : 'contacts'}
          type="all"
        />
      </Tabs.Content>
    </Tabs.Root>
  </div>

  <div slot="sidebar">
    {#if !$appStore.isMobile}
      {#key refresh}
        {#if isCandidate(crmContact?.role)}
          <CandidateFactSheetForm bind:crmContact />
        {:else}
          <ContactFactSheetForm bind:crmContact />
        {/if}
      {/key}
    {/if}
  </div>
</PageWithTitle>
