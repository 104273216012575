<script lang="ts">
  import { Input } from '$lib/components/ui/input/index.js';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import Pagination from '$lib/app/list/pagination.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import * as Card from '$lib/components/ui/card';

  import {
    type ActivityWithRefs,
    type Account,
    type Activity,
  } from '$db/schema';
  import { getItems, getTodos } from '$lib/api/queries';
  import { cn } from '$lib/utils';
  import Icon from '@iconify/svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { superForm } from 'sveltekit-superforms';
  import { Button } from '$lib/components/ui/button';
  import { formatDate } from '$lib/pages/invoices/invoice-utils.js';
  import { appStore } from '$lib/app-store';
  import {
    createMutation,
    createQuery,
    useQueryClient,
  } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { saveActivity } from '$lib/api/mutations';
  import { flip } from 'svelte/animate';

  type $$Props = {
    class?: string;
  };

  let filterValue = writable('');
  let page = writable(0);

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name',
    });

    return result.data;
  };

  const form = superForm<ActivityWithRefs>(
    {},
    {
      resetForm: false,
      SPA: true,
      dataType: 'json',
    },
  );

  const { form: formData } = form;

  const client = useQueryClient();

  const query = createQuery(
    derived([formData, filterValue, page], ([data, search, page]) => ({
      enabled: true,
      queryKey: [
        'activities',
        'todos',
        page,
        data?.consultantObj?.id ?? 'n/a',
        search,
      ],
      queryFn: async () => {
        return await getTodos(
          {
            search,
            consultantId: data?.consultantObj?.id,
          },
          page,
          page + 10,
        );
      },
    })),
  );

  const addMutation = createMutation({
    mutationFn: (activity): Promise<Account> =>
      Promise.resolve(saveActivity(activity)),
    onSuccess: () =>
      client.invalidateQueries({
        queryKey: [
          'activities',
          'todos',
          $page,
          $formData?.consultantObj?.id ?? 'na',
          $filterValue,
        ],
      }),
    onMutate: async newItem => {
      const mutationKey = [
        'activities',
        'todos',
        $page,
        $formData?.consultantObj?.id ?? 'na',
        $filterValue,
      ];
      await client.cancelQueries({ queryKey: mutationKey });
      const previousItems = client.getQueryData<Activity[]>(mutationKey);
      const data = previousItems.data.map(item =>
        item.id === newItem.id ? newItem : item,
      );
      // sort done to end of array
      data.sort((a, b) => (a.done === b.done ? 0 : a.done ? 1 : -1));
      client.setQueryData(mutationKey, { ...previousItems, data });
      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        const mutationKey = [
          'activities',
          'todos',
          $page,
          $formData.consultantObj?.id,
          $filterValue,
        ];
        client.setQueryData(mutationKey, context.previousItems);
      }
    },
    onSettled: () => {
      const mutationKey = [
        'activities',
        'todos',
        $page,
        $formData.consultantObj?.id,
        $filterValue,
      ];
      client.invalidateQueries({ queryKey: mutationKey });
    },
  });

  let className: $$Props['class'] = undefined;
  export { className as class };

  function saveActivityDone(e: MouseEvent, activity, done: boolean) {
    e.stopPropagation();
    $addMutation.mutate({ ...activity, done });
  }
</script>

<Card.Root
  class={cn('h-full overflow-hidden bg-primary-50 flex flex-col', className)}
>
  <Card.Header
    class="flex relative flex-row justify-between space-y-0 border-b border-solid bg-primary-800 border-b-primary-200/20"
  >
    <Card.Title class="flex gap-2 items-center">
      Todos
      <Badge>{$query.data?.count ?? '0'}</Badge>
    </Card.Title>
    <div class="absolute top-3 right-4">
      <HantaInputMultiSelect
        class="w-64"
        {form}
        {loadOptions}
        multiple={false}
        asArray={false}
        name="consultantObj"
        placeholder="Filter by Consultant"
        variant="light"
      >
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:index
          let:item
          slot="item"
        >
          <CrmAvatar id={item.id} module="users" />
        </div>
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <CrmAvatar id={selection.id} module="users" />
        </div>
      </HantaInputMultiSelect>
    </div>
  </Card.Header>
  <Card.Content class="flex overflow-scroll relative flex-col gap-2 p-6 h-full">
    {#if $query?.data?.data}
      {#each $query.data?.data as activity (activity.id)}
        <div animate:flip={{ duration: 200 }} class="transition-all">
          <Button
            on:click={() => {
              appStore.openActivity({
                ...activity,
                consultantObj: { id: activity.consultant },
              });
            }}
            variant="link"
            class="{activity.done
              ? 'line-through'
              : ''} flex text-left content-start justify-start w-full p-4 bg-primary-800 shadow-sm border border-muted rounded items-center gap-4 text-sm h-auto"
          >
            <Button variant="ghost" class="p-0 m-0">
              {#if activity.done}
                <Button
                  variant="ghost"
                  on:click={e => {
                    saveActivityDone(e, activity, false);
                  }}
                >
                  <Icon icon="mdi:check-square" class="size-6" />
                </Button>
              {:else}
                <Button
                  variant="ghost"
                  on:click={e => {
                    saveActivityDone(e, activity, true);
                  }}
                >
                  <Icon icon="mdi:square" class="size-6" />
                </Button>
              {/if}
            </Button>
            <div class="w-full">
              {activity.name ?? '-'}
            </div>
            <CrmAvatar id={activity.consultant} module="users" />
            {#if activity.dueDate}
              <div class="w-40">
                <Badge>{formatDate(activity.dueDate)}</Badge>
                <!--<CrmAvatar id={activity.customer} module="accounts" name={activity.name} /> -->
              </div>
            {/if}
          </Button>
        </div>
      {/each}
    {/if}
  </Card.Content>
  <Card.Footer
    class="gap-4 justify-between pt-1 pb-1.5 border-t border-solid shadow-sm border-gray 200 bg-primary-800"
  >
    <Input
      bind:value={$filterValue}
      class="pl-2 w-full"
      placeholder="Filter deals"
      type="text"
    />
    <Pagination
      count={$query.data?.count}
      from={$page}
      on:next={() => ($page += 10)}
      on:previous={() => ($page -= 10)}
    />
  </Card.Footer>
</Card.Root>
