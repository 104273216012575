import type { Action } from 'svelte/action';

interface DraggableOptions {
  relativeTo?: HTMLElement;
  minX?: number;
  minY?: number;
  maxX?: number;
  maxY?: number;
  onDragStart?: (x: number, y: number) => { x: number; y: number };
  onDragMove?: (x: number, y: number, dx: number, dy: number) => void;
  onDragEnd?: (x: number, y: number, dx: number, dy: number) => void;
}

export const asDraggable: Action<HTMLElement, DraggableOptions> = (node, options) => {
  let initialX: number;
  let initialY: number;
  let startX: number;
  let startY: number;
  let isDragging = false;

  function handleMouseDown(event: MouseEvent) {
  if (event.button !== 0) return; // Only handle left click

  isDragging = true;
  initialX = event.clientX;
  initialY = event.clientY;

  if (options.onDragStart) {
  const startPos = options.onDragStart(initialX, initialY);
  startX = startPos.x;
  startY = startPos.y;
} else {
  startX = initialX;
  startY = initialY;
}

  // Add event listeners to document to handle mouse movements outside the element
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);

  // Prevent text selection while dragging
  event.preventDefault();
}

  function handleMouseMove(event: MouseEvent) {
  if (!isDragging) return;

  const dx = event.clientX - initialX;
  const dy = event.clientY - initialY;

  let newX = startX + dx;
  let newY = startY + dy;

  // Apply constraints if specified
  if (options.minX !== undefined) newX = Math.max(options.minX, newX);
  if (options.maxX !== undefined) newX = Math.min(options.maxX, newX);
  if (options.minY !== undefined) newY = Math.max(options.minY, newY);
  if (options.maxY !== undefined) newY = Math.min(options.maxY, newY);

  if (options.onDragMove) {
  options.onDragMove(newX, newY, dx, dy);
}
}

  function handleMouseUp(event: MouseEvent) {
  if (!isDragging) return;

  isDragging = false;
  const dx = event.clientX - initialX;
  const dy = event.clientY - initialY;

  let finalX = startX + dx;
  let finalY = startY + dy;

  // Apply constraints if specified
  if (options.minX !== undefined) finalX = Math.max(options.minX, finalX);
  if (options.maxX !== undefined) finalX = Math.min(options.maxX, finalX);
  if (options.minY !== undefined) finalY = Math.max(options.minY, finalY);
  if (options.maxY !== undefined) finalY = Math.min(options.maxY, finalY);

  if (options.onDragEnd) {
  options.onDragEnd(finalX, finalY, dx, dy);
}

  // Remove document event listeners
  document.removeEventListener('mousemove', handleMouseMove);
  document.removeEventListener('mouseup', handleMouseUp);
}

  // Add touch event handlers for mobile support
  function handleTouchStart(event: TouchEvent) {
  if (event.touches.length !== 1) return; // Only handle single touch

  const touch = event.touches[0];
  isDragging = true;
  initialX = touch.clientX;
  initialY = touch.clientY;

  if (options.onDragStart) {
  const startPos = options.onDragStart(initialX, initialY);
  startX = startPos.x;
  startY = startPos.y;
} else {
  startX = initialX;
  startY = initialY;
}

  // Prevent scrolling while dragging
  event.preventDefault();
}

  function handleTouchMove(event: TouchEvent) {
  if (!isDragging || event.touches.length !== 1) return;

  const touch = event.touches[0];
  const dx = touch.clientX - initialX;
  const dy = touch.clientY - initialY;

  let newX = startX + dx;
  let newY = startY + dy;

  // Apply constraints if specified
  if (options.minX !== undefined) newX = Math.max(options.minX, newX);
  if (options.maxX !== undefined) newX = Math.min(options.maxX, newX);
  if (options.minY !== undefined) newY = Math.max(options.minY, newY);
  if (options.maxY !== undefined) newY = Math.min(options.maxY, newY);

  if (options.onDragMove) {
  options.onDragMove(newX, newY, dx, dy);
}

  event.preventDefault();
}

  function handleTouchEnd(event: TouchEvent) {
  if (!isDragging) return;

  isDragging = false;
  const dx = event.changedTouches[0].clientX - initialX;
  const dy = event.changedTouches[0].clientY - initialY;

  let finalX = startX + dx;
  let finalY = startY + dy;

  // Apply constraints if specified
  if (options.minX !== undefined) finalX = Math.max(options.minX, finalX);
  if (options.maxX !== undefined) finalX = Math.min(options.maxX, finalX);
  if (options.minY !== undefined) finalY = Math.max(options.minY, finalY);
  if (options.maxY !== undefined) finalY = Math.min(options.maxY, finalY);

  if (options.onDragEnd) {
  options.onDragEnd(finalX, finalY, dx, dy);
}
}

  // Add event listeners
  node.addEventListener('mousedown', handleMouseDown);
  node.addEventListener('touchstart', handleTouchStart);
  node.addEventListener('touchmove', handleTouchMove);
  node.addEventListener('touchend', handleTouchEnd);

  return {
  destroy() {
  // Clean up event listeners
  node.removeEventListener('mousedown', handleMouseDown);
  node.removeEventListener('touchstart', handleTouchStart);
  node.removeEventListener('touchmove', handleTouchMove);
  node.removeEventListener('touchend', handleTouchEnd);
  document.removeEventListener('mousemove', handleMouseMove);
  document.removeEventListener('mouseup', handleMouseUp);
},

  update(newOptions: DraggableOptions) {
  options = newOptions;
}
};
};
