import { writable } from 'svelte/store';
import { getCurrentUser } from '$lib/api/queries';
import { User } from 'src/types';

function createCurrentUserStore() {
  const { subscribe, set } = writable<User | null>(null);
  let initialized = false;

  const store = {
    subscribe,
    loadUser: async () => {
      if (initialized) return; // Prevent multiple initial loads

      try {
        const user = await getCurrentUser();
        if (user) {
          set(user);
          initialized = true;
        }
      } catch (error) {
        console.error('Failed to load user:', error);
        set(null);
      }
    },
    refreshUser: async () => {
      try {
        const user = await getCurrentUser();
        set(user);
      } catch (error) {
        console.error('Failed to refresh user:', error);
        set(null);
      }
    },
    logout: () => {
      initialized = false;
      set(null);
    },
  };

  return store;
}

export const currentUserStore = createCurrentUserStore();
