<script lang="ts">
  import DealsCard from './deals-card.svelte';
  import TasksCard from './tasks-card.svelte';
  import TimelineCard from './timeline-card.svelte';
  import Prices from '../prices/prices.svelte';

  import * as Tabs from '$lib/components/ui/tabs';
  import { appStore } from '$lib/app-store';
  import { authStore } from '$lib/auth-store';

  let currentTab = 'timeline';
</script>

{#if $appStore.isMobile}
  <Tabs.Root bind:value={currentTab} class="">
    <Tabs.List class="grid-cols-3 ml-1 max-w-xs tn-grid">
      <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
      <Tabs.Trigger value="deals">Deals</Tabs.Trigger>
      <Tabs.Trigger value="tasks">Tasks</Tabs.Trigger>
    </Tabs.List>
    <Tabs.Content value="timeline">
      {#if currentTab === 'timeline'}
        <TimelineCard class="w-full" />
      {/if}
    </Tabs.Content>
    <Tabs.Content value="deals">
      {#if currentTab === 'deals'}
        <DealsCard class="w-full" />
      {/if}
    </Tabs.Content>
    <Tabs.Content value="taks">
      {#if currentTab === 'tasks'}
        <TasksCard class="w-full" />
      {/if}
    </Tabs.Content>
  </Tabs.Root>
{:else}
  <div
    class="grid-cols-1 gap-4 overflow-scroll tn-grid md:grid-cols-2 xl:grid-cols-3 h-[calc(100vh-7rem)] pt-12"
  >
    <TimelineCard class="col-span-2 w-full xl:col-span-1" />

    {#if authStore.isLogistics()}
      <div class="col-span-2 pr-6 w-full text-lg">
        <Prices withMdl={true} currency="MDL" refDate={new Date()} />
      </div>
    {:else if authStore.isHeadhunter()}
      <DealsCard class="w-full min-h-96" />
    {/if}

    <TasksCard class="w-full min-h-96" />
  </div>
{/if}
