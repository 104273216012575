<script>
  import { onMount } from 'svelte';
  import { hantaLinkedinParser } from '../../linkedin/parser';

  import { saveLiMails } from '$lib/api/mutations';

  import * as Accordion from '$lib/components/ui/accordion';

  import { appStore } from '$lib/app-store';
  let parseResult = null;

  onMount(() => {
    init();
  });

  function init() {
    parseProfile();
  }

  function parseProfile() {
    const hantaCurrentUrl =
      location.protocol + '//' + location.host + location.pathname;
    appStore.setLinkedInAccount(undefined);
    appStore.setLinkedInContact(undefined);

    setTimeout(() => {
      appStore.setParsing(true);
      setTimeout(() => {
        appStore.setParsing(false);
      }, 5000);
      hantaLinkedinParser(hantaCurrentUrl, result => {
        appStore.setParsing(false);
        console.debug('result', result);
        const { error, isCandidate, messages, chatWithUser } = result;

        if (messages) {
          //appStore.setMessages(allMessages);
          // console.debug(JSON.stringify({ messages }, null, 2));
          saveLiMails(chatWithUser, messages).then(data => {
            console.debug('saved', data);
          });
          return;
        }
        if (error) {
          appStore.setLinkedInAccount(undefined);
          appStore.setLinkedInContact(undefined);
          return;
        } else {
          parseResult = result;
          if (isCandidate) {
            appStore.setLinkedInContact(parseResult);
          } else {
            appStore.setLinkedInAccount(parseResult);
          }
        }
      });
    }, 500);
  }
</script>

{#if false && parseResult && !parseResult.error && import.meta.env.MODE === 'plugin'}
  <Accordion.Root>
    <Accordion.Item value="item-1">
      <Accordion.Trigger>
        <div class="flex items-center space-x-2">
          <span>Log </span>
        </div>
      </Accordion.Trigger>
      <Accordion.Content>
        <pre>{JSON.stringify(parseResult, null, 2)}</pre>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
{/if}
