<script lang="ts">
  import { type AccountWithRefs } from '$db/schema';
  import Icon from '@iconify/svelte';

  export let account: AccountWithRefs;
  export let stats = [
    { label: 'Is customer', value: 'No', icon: 'mdi:users' },
    { label: 'Customer since', value: '24.06.2024', icon: 'mdi:calendar-days' },
    { label: 'Last contact', value: '09.09.2024', icon: 'mdi:phone' },
    { label: 'Last sale', value: '20.09.2024', icon: 'mdi:badge-dollar-sign' },
    { label: 'Last invoice', value: '20.09.2024', icon: 'mdi:file-check' },
    { label: 'Last payment', value: '20.09.2024', icon: 'mdi:wallet' },
  ];

  export let volumeStats = {
    'Current Q': account.volumeCurrentQ,
    'Last Q': account.volumeLastQ,
    Difference: account.volumeDiff,
    'Current Q Category': account.categoryCurrentQ,
    'Last Q Category': account.categoryLastQ,
    'Category Trend': account.categoryDiffToLastQ,
  };

  const formatNumber = (num: number) => {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const isPositive = (value: number | string) => {
    if (typeof value === 'number') {
      return value > 0;
    }
    return value === 'Повышение';
  };

  const getVolumeIcon = (key: string) => {
    switch (key) {
      case 'Current Q':
      case 'Last Q':
        return 'mdi:circle-dollar-sign';
      case 'Difference':
        return 'mdi:trending-up';
      case 'Current Q Category':
      case 'Last Q Category':
      case 'Category Trend':
        return 'bxs:badge-check';
      default:
        return 'mdi:circle-dollar-sign';
    }
  };
</script>

<div class="grid grid-cols-1 gap-4 my-12 lg:grid-cols-2">
  <div class="p-6 space-y-6 w-full rounded-lg shadow-md bg-primary-foreground">
    <h2 class="text-xl font-bold text-gray-800">Customer Statistics</h2>
    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
      {#each stats as stat}
        <div
          class="p-4 bg-gray-50 rounded-md transition-all duration-300 hover:bg-blue-50 hover:shadow-md"
        >
          <div class="flex flex-col space-y-4">
            <div class="flex justify-between justify-items-center items-center">
              <span class="text-sm font-medium text-gray-600">{stat.label}</span
              >
              <Icon icon={stat.icon} class="text-blue-500 size-5" />
            </div>
            <span class="text-lg font-semibold text-gray-800">{stat.value}</span
            >
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div class="p-6 space-y-6 w-full rounded-lg shadow-md bg-primary-foreground">
    <h2 class="text-xl font-bold text-gray-800">Volume Statistics</h2>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {#each Object.entries(volumeStats) as [key, value]}
        <div
          class="p-4 bg-gray-50 rounded-md transition-all duration-300 hover:bg-blue-50 hover:shadow-md"
        >
          <div class="flex flex-col space-y-4">
            <div class="flex justify-between items-center">
              <span class="text-sm font-medium text-gray-600">{key}</span>
              <Icon icon={getVolumeIcon(key)} class="text-blue-500 size-5" />
            </div>
            <div class="flex justify-between items-center">
              {#if typeof value === 'number'}
                <span class="text-lg font-semibold text-gray-900"
                  >{formatNumber(value)}</span
                >
              {:else}
                <span class="text-lg font-semibold text-gray-900">{value}</span>
              {/if}
              {#if key === 'Difference' || key === 'Category Trend'}
                <span class="ml-2">
                  <Icon
                    icon={isPositive(value)
                      ? 'mdi:arrow-up-right'
                      : 'mdi:arrow-down-right'}
                    class={isPositive(value)
                      ? 'text-green-500 size-5'
                      : 'text-red-500 size-5'}
                  />
                </span>
              {/if}
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
