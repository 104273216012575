<script lang="ts">
  import { onMount } from 'svelte';
  import * as d3 from 'd3';

  export interface InvoiceData {
    status: string;
    amount: number;
    count: number;
  }

  export interface InvoiceStatusColors {
    [key: string]: string;
  }

  export let data: InvoiceData[] = [];
  export let statusColors: InvoiceStatusColors = {
    Draft: 'hsl(270, 60%, 60%)', // Purple
    Open: 'hsl(210, 100%, 50%)', // Blue
    Paid: 'hsl(120, 60%, 50%)', // Green
    Uncollectible: 'hsl(30, 100%, 50%)', // Orange
    Void: 'hsl(0, 100%, 50%)', // Red
    Overdue: 'hsl(0, 100%, 50%)', // Red
    Cancelled: 'hsl(0, 0%, 50%)', // Gray
  };
  export let width: number = 600;
  export let height: number = 40;

  let chart: SVGSVGElement;

  onMount(() => {
    drawChart();
    return () => {
      // Cleanup if necessary
    };
  });

  $: if (data && chart) {
    drawChart();
  }

  function drawChart() {
    if (!chart) return;

    // Clear previous chart
    d3.select(chart).selectAll('*').remove();
    const svg = d3.select(chart);
    const margin = { top: 5, right: 5, bottom: 5, left: 5 };

    // Create color scale
    const colorScale = d3
      .scaleOrdinal()
      .domain(Object.keys(statusColors))
      .range(Object.values(statusColors));

    // Prepare data for stacking
    const stack = d3
      .stack()
      .keys(data.map(d => d.status))
      .value((d, key) => d.find(item => item.status === key)?.amount || 0);

    const series = stack([data]);

    const x = d3
      .scaleLinear()
      .domain([0, d3.sum(data, d => d.amount)])
      .range([margin.left, width - margin.right]);

    svg.attr('width', width).attr('height', height);

    svg
      .selectAll('g')
      .data(series)
      .join('g')
      .attr('fill', d => colorScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .join('rect')
      .attr('x', d => x(d[0]))
      .attr('y', margin.top)
      .attr('height', height - margin.top - margin.bottom)
      .attr('width', d => x(d[1]) - x(d[0]))
      .on('mouseover', (event, d) => {
        const status = d3.select(event.currentTarget.parentNode).datum().key;
        const amount = d[1] - d[0];
        showTooltip(event, `${status}: ${formatCurrency(amount)}`);
      })
      .on('mouseout', hideTooltip);

    // Add tooltip div if it doesn't exist
    if (!d3.select('body').select('.tooltip').size()) {
      d3.select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'white')
        .style('border', '1px solid #ddd')
        .style('padding', '5px')
        .style('border-radius', '3px');
    }
  }

  function showTooltip(event: MouseEvent, text: string) {
    d3.select('.tooltip')
      .style('visibility', 'visible')
      .style('top', event.pageY - 10 + 'px')
      .style('left', event.pageX + 10 + 'px')
      .text(text);
  }

  function hideTooltip() {
    d3.select('.tooltip').style('visibility', 'hidden');
  }

  function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  }
</script>

<svg bind:this={chart}></svg>

<style>
  svg {
    max-width: 100%;
    height: auto;
  }
</style>
