import { SupabaseClient, createClient } from '@supabase/supabase-js';

const environment = import.meta.env.VITE_SUPABASE_ENVIRONMENT;
const SUPABASE_URL = import.meta.env[
  `VITE_SUPABASE_URL_${environment.toUpperCase()}`
];
const SUPABASE_ANON_KEY = import.meta.env[
  `VITE_SUPABASE_ANON_KEY_${environment.toUpperCase()}`
];

const AUTH_PROVIDER = import.meta.env.VITE_AUTH_PROVIDER;

let supabaseClient: SupabaseClient = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
);

let supabaseClientPool: Record<string, SupabaseClient> = {};

export const refreshClient = () => {
  supabaseClient = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
};

export const supabase = (accessToken?: string) => {
  if (!accessToken && supabaseClient) {
    return supabaseClient;
  }
  if (accessToken && supabaseClientPool[accessToken]) {
    return supabaseClientPool[accessToken];
  }
  purgePoolOnBrowser();
  const client = createClient(
    SUPABASE_URL,
    SUPABASE_ANON_KEY,
    AUTH_PROVIDER !== 'supabase' && {
      global: { headers: { Authorization: `Bearer ${accessToken}` } },
    },
  );
  if (AUTH_PROVIDER !== 'supabase') {
    client.realtime.setAuth(accessToken);
  }

  if (accessToken) {
    supabaseClientPool[accessToken] = client;
  }
  return client;
};

const purgePoolOnBrowser = () => {
  if (typeof window !== 'undefined') {
    supabaseClientPool = {};
  }
};
