<script lang="ts">
  import { appStore } from '$lib/app-store';
  import Icon from '@iconify/svelte';
  import { beforeUpdate, onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import { flyAndScale } from '$lib/utils';
  import { cn } from '$lib/utils';
  import { type SuperForm } from 'sveltekit-superforms';
  import { type ActivityWithRefs } from '$db/schema';
  import * as Form from '$lib/components/ui/form';
  import {asDraggable} from '$lib/ui/draggable';

  let popup: HTMLElement;

  export let form: SuperForm<ActivityWithRefs> = null;

  export let minWidth = 700;
  export let minHeight = 500;

  export let width = 700;
  export let height = 500;

  let posX = 0;
  let posY = 0;

  type $$Props = {
    class?: string;
    width?: number;
    height?: number;
    form?: SuperForm<ActivityWithRefs>;
    minWidth?: number;
    minHeight?: number;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  onMount(() => {
    moveToLastPosition();
  });

  beforeUpdate(() => {
    moveToLastPosition();
  });

  function moveToLastPosition() {
    /**
    if(popup && !document.body.contains(popup)) {
      console.debug("moving to last position!!!");
      document.body.appendChild(popup);
    }
      **/

    // check if the popup is on the last position inside dom, if not move it to last position
    if (popup && document.body.lastChild !== popup) {
      document.body.appendChild(popup);
    }
  }

  function onDragStart() {
    return { x: posX, y: posY };
  }

  function onDragMove(x, y, dx, dy) {
    posX = x;
    posY = y;
  }

  function onDragEnd(x, y, dx, dy) {
    posX = x;
    posY = y;
  }

  function startResizing() {
    return { x: width, y: height };
  }

  function continueResizing(x, y) {
    width = x;
    height = y;
  }

  function finishResizing(x, y) {
    width = x;
    height = y;
  }

  const updatePosition = () => {
    const rect = popup.getBoundingClientRect();
    posX = rect.left;
    posY = rect.top;
  };

  let { form: formData, enhance, errors } = form;
</script>

<div
  bind:this={popup}
  class={cn(
    'fixed flex top-0 left-0 z-50 border bg-background shadow-lg sm:rounded-lg',
    className,
    $appStore.isMobile ? 'w-full h-full' : 'w-[700px] h-[500px]',
  )}
  on:introend={() => updatePosition()}
  style={$appStore.isMobile
    ? ``
    : `width: ${width}px; height: ${height}px; min-width: ${minWidth}px; min-height: ${minHeight}px; transform: translate3d(${posX}px, ${posY}px, 0);`}
  transition:flyAndScale={{ duration: 200, center: true }}
>
  <form
    autocomplete="off"
    class="flex flex-col gap-0 w-full h-full"
    datatype="JSON"
    method="POST"
    use:enhance
  >

    <div
      class="flex flex-col flex-grow-0 px-6 py-4 pt-6 pb-6 space-y-1.5 text-center sm:text-left bg-muted"
      use:asDraggable={{
        relativeTo: document.body,
        onDragStart,
        onDragMove,
        onDragEnd,
        minX: 0,
        minY: 0,
        maxX: window.innerWidth - width,
        maxY: window.innerHeight - height,
      }}
    >

      <div
        class="flex flex-row items-center text-lg font-semibold tracking-tight leading-none"
      >
        <slot name="header" />
        <Button
          class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          on:click={() => {
            appStore.closeActivity();
          }}
          variant="ghost"
        >
          <Icon icon="mdi:close" class="w-4 h-4" />
          <span class="sr-only">Close</span>
        </Button>
      </div>
    </div>
    <slot name="content" />
    <div
      class="flex flex-col px-6 py-4 border-t sm:flex-row sm:justify-start sm:space-x-2 border-t-solid border-t-primary-200/20"
    >
      {#if $$slots.footer}
        <slot name="footer" />
      {:else}
        <Form.Button variant="default">Log activity</Form.Button>
        <Button
          on:click={e => {
            form.reset();
            appStore.closeActivity();
          }}
          variant="outline"
          >Cancel
        </Button>
      {/if}
    </div>
    <div
      class="fixed right-0 bottom-0 cursor-move"
      use:asDraggable={{
        onDragStart: startResizing,
        onDragMove: continueResizing,
        onDragEnd: finishResizing,
        minX: minWidth,
        minY: minHeight,
        maxX: window.innerWidth,
        maxY: window.innerHeight,
      }}

    >
      <Icon icon="mdi:grip" class="w-4 h-4" />
    </div>

  </form>
</div>
