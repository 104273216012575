<script lang="ts">
  import Icon from '@iconify/svelte';
  import { tick } from 'svelte';
  import * as Command from '$lib/components/ui/command';
  import * as Popover from '$lib/components/ui/popover';
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils';

  import { Avatar } from '$lib/components/ui/avatar';
  import { authStore } from '$lib/auth-store';
  import { switchOrganization } from '$lib/api/supabase-auth-api';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import { supabase } from '$lib/api/supabase';

  import { appStore } from '$lib/app-store';

  let open = false;
  let showDialog = false;
  let name = '';
  let slug = '';
  let logoFile: File | null = null;
  let logoPreview: string | null = null;

  let orgs = [];
  $: orgs = $authStore.organizations ?? [];

  // Subscribe to organization changes
  $: selectedOrg = orgs.find(org => org.id === $authStore.organization?.id);
  $: selectedValue = selectedOrg?.name ?? 'Select organization...';

  // Function to handle organization switch
  async function handleOrgSelect(orgId: string) {
    try {
      await switchOrganization(orgId);

      closeAndFocusTrigger('org-trigger');
    } catch (error) {
      console.error('Error switching organization:', error);
    }
  }

  // Helper to close popover and refocus trigger
  function closeAndFocusTrigger(triggerId: string) {
    open = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
    });
  }

  // Create new organization
  async function handleCreateOrg() {
    open = false;
    showDialog = true;
  }

  // Handle slug generation from name
  $: {
    if (name && !slug) {
      slug = name.toLowerCase().replace(/[^a-z0-9]/g, '-');
    }
  }

  function handleLogoChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      logoFile = input.files[0];
      // Create preview URL
      logoPreview = URL.createObjectURL(logoFile);
    }
  }

  async function handleSubmit() {
    try {
      let logoUrl = null;

      // Upload logo if selected
      if (logoFile) {
        const fileExt = logoFile.name.split('.').pop();
        const fileName = `${crypto.randomUUID()}.${fileExt}`;

        const { data: uploadData, error: uploadError } = await supabase()
          .storage.from('organization-logos')
          .upload(fileName, logoFile);

        if (uploadError) throw uploadError;

        // Get public URL
        const {
          data: { publicUrl },
        } = supabase()
          .storage.from('organization-logos')
          .getPublicUrl(fileName);

        logoUrl = publicUrl;
      }

      // Create organization
      const { data: org, error: orgError } = await supabase()
        .from('organizations')
        .insert([
          {
            name,
            slug,
            logo_url: logoUrl,
          },
        ]);

      if (orgError) throw orgError;

      showDialog = false;
      // Optionally refresh organization list or redirect
    } catch (error) {
      console.error('Error creating organization:', error);
      // Handle error (show toast notification, etc.)
    }
  }
</script>

<div
  class="md:fixed md:-top-2 md:left-0 md:bg-background z-[99999] pl-1 pt-1 md:dark:!text-white"
>
  <Popover.Root bind:open let:ids>
    <Popover.Trigger asChild let:builder>
      <Button
        id="org-trigger"
        builders={[builder]}
        variant="outline"
        role="combobox"
        aria-expanded={open}
        class="justify-between w-full"
      >
        <div class="flex gap-2 items-center">
          {#if selectedOrg?.logo_url}
            <Avatar
              class="w-6 h-6"
              src={selectedOrg.logo_url}
              alt={selectedOrg.name}
            />
          {:else if selectedOrg?.name}
            <Avatar class="w-6 h-6">
              <span class="text-xs font-semibold">
                {selectedOrg.name[0].toUpperCase()}
              </span>
            </Avatar>
          {/if}
          {#if !$appStore.isCollapsed}
            <span class="truncate">{selectedValue}</span>
          {/if}
        </div>
        <Icon
          icon="lucide:chevron-down"
          class="ml-2 w-4 h-4 opacity-50 transition duration-200 shrink-0"
          style={open ? 'transform: rotate(180deg)' : ''}
        />
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-[250px] p-0 z-[99999]">
      <Command.Root>
        <Command.Input
          placeholder="Search organization..."
          autofocus={true}
          class="border-none focus:ring-0"
        >
          <div class="flex gap-2 items-center">
            <Icon icon="lucide:search" class="w-4 h-4 opacity-50 shrink-0" />
          </div>
        </Command.Input>
        <Command.List>
          <Command.Group>
            {#if orgs.length > 0}
              {#each orgs as org}
                <Command.Item
                  value={org.id}
                  onSelect={handleOrgSelect}
                  class="flex gap-2 items-center"
                >
                  {#if org.logo_url}
                    <Avatar class="w-6 h-6" src={org.logo_url} alt={org.name} />
                  {:else}
                    <Avatar class="w-6 h-6">
                      <span class="text-xs font-semibold">
                        {org.name[0].toUpperCase()}
                      </span>
                    </Avatar>
                  {/if}
                  <div class="flex flex-1 justify-between items-center">
                    <span>{org.name}</span>
                    {#if org.role === 'admin'}
                      <span class="ml-2 text-xs text-muted-foreground"
                        >Admin</span
                      >
                    {/if}
                  </div>
                  <Icon
                    icon="lucide:check"
                    class={cn(
                      'ml-2 h-4 w-4',
                      $authStore.organization?.id !== org.id &&
                        'text-transparent',
                    )}
                  />
                </Command.Item>
              {/each}
            {:else}
              <Command.Item class="text-muted-foreground">
                No organizations found.
              </Command.Item>
            {/if}
          </Command.Group>

          <Command.Separator />

          <Command.Group>
            <Command.Item
              onSelect={handleCreateOrg}
              class="flex gap-2 items-center text-muted-foreground"
            >
              <Icon icon="lucide:plus" class="w-4 h-4 shrink-0" />
              Create Organization
            </Command.Item>
          </Command.Group>

          <!-- User section with personal account -->
          <Command.Separator />

          <Command.Group heading="Personal account">
            <Command.Item
              class="flex gap-2 items-center"
              onSelect={() => {
                // Handle personal account selection
                closeAndFocusTrigger('org-trigger');
              }}
            >
              <Avatar class="w-6 h-6">
                {#if $authStore.user?.user_metadata?.avatar_url}
                  <img
                    src={$authStore.user.user_metadata.avatar_url}
                    alt="User avatar"
                    class="object-cover w-full h-full"
                  />
                {:else}
                  <span class="text-xs font-semibold">
                    {$authStore.user?.email?.[0].toUpperCase() ?? 'U'}
                  </span>
                {/if}
              </Avatar>
              <span>Personal Account</span>
              <Icon
                icon="lucide:check"
                class={cn(
                  'ml-auto h-4 w-4',
                  $authStore.organization?.id !== 'personal' &&
                    'text-transparent',
                )}
              />
            </Command.Item>
          </Command.Group>
        </Command.List>
      </Command.Root>
    </Popover.Content>
  </Popover.Root>
</div>

<Dialog.Root bind:open={showDialog}>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Create organization</Dialog.Title>
      <Dialog.Description>
        Create a new organization to collaborate with your team.
      </Dialog.Description>
    </Dialog.Header>

    <form class="space-y-6" on:submit|preventDefault={handleSubmit}>
      <!-- Logo Upload -->
      <div class="space-y-2">
        <Label>Logo</Label>
        <div class="flex gap-4 items-center">
          <div
            class="flex overflow-hidden justify-center items-center w-16 h-16 rounded-lg border bg-muted"
          >
            {#if logoPreview}
              <img
                src={logoPreview}
                alt="Logo preview"
                class="object-cover w-full h-full"
              />
            {:else}
              <Icon icon="lucide:image" class="w-8 h-8 text-muted-foreground" />
            {/if}
          </div>
          <div>
            <Input
              type="file"
              accept="image/*"
              class="hidden"
              id="logo-upload"
              on:change={handleLogoChange}
            />
            <Label
              for="logo-upload"
              class="inline-flex justify-center items-center px-3 h-9 text-sm font-medium rounded-md transition-colors cursor-pointer bg-primary text-primary-foreground ring-offset-background hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
            >
              Upload
            </Label>
            <p class="mt-1 text-xs text-muted-foreground">
              Recommended size 1:1, up to 10MB.
            </p>
          </div>
        </div>
      </div>

      <!-- Organization Name -->
      <div class="space-y-2">
        <Label for="name">Name</Label>
        <Input id="name" bind:value={name} required placeholder="Acme Inc." />
      </div>

      <!-- Organization Slug -->
      <div class="space-y-2">
        <Label for="slug">Slug</Label>
        <Input
          id="slug"
          bind:value={slug}
          required
          placeholder="acme"
          pattern="[a-z0-9\-]+"
          title="Only lowercase letters, numbers, and hyphens are allowed"
        />
      </div>

      <Dialog.Footer>
        <Button
          type="button"
          variant="outline"
          on:click={() => (showDialog = false)}
        >
          Cancel
        </Button>
        <Button type="submit">Create organization</Button>
      </Dialog.Footer>
    </form>
  </Dialog.Content>
</Dialog.Root>

<style>
  :global(.org-menu) {
    z-index: 99999;
  }
  :global(#global-nav) {
    z-index: 10;
  }
</style>
