export class NumberUtils {
  public static roundValue(value: number): number {
    return Number(parseFloat(value?.toString() || '0').toFixed(2));
  }

  public static parseValue(value: string | number | undefined | null): number {
    if (value === null || value === undefined) {
      return 0;
    }

    // If it's already a number, return it
    if (typeof value === 'number') {
      return value;
    }

    // Convert to string and handle comma decimals
    const strValue = String(value).replace(',', '.');
    const parsed = Number(parseFloat(strValue));

    return isNaN(parsed) ? 0 : parsed;
  }
}
