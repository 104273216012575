<script lang="ts">
  import { tabs } from './activities-data';
  import * as Tabs from '$lib/components/ui/tabs';
  import { appStore } from '$lib/app-store';
  import HantaInputMultiSelectLean from '$lib/widgets/input/hanta-input-multi-select-lean.svelte';

  export let type = 'all';
</script>

{#if $appStore.isMobile}
  <HantaInputMultiSelectLean
    class="w-64"
    bind:value={type}
    options={tabs}
    multiple={false}
    placeholder="Filter by Type"
  >
    <div
      class="flex overflow-hidden items-center w-full h-full"
      let:index
      let:item
      slot="item"
    >
      {item.label}
    </div>
    <div
      class="flex overflow-hidden items-center w-full h-full"
      let:selection
      slot="selection"
    >
      {selection.label}
    </div>
  </HantaInputMultiSelectLean>{:else}
  <Tabs.Root class="w-full" bind:value={type}>
    <Tabs.List class="bg-transparent w-fit">
      {#each tabs as tab}
        <Tabs.Trigger value={tab.value}>{tab.label}</Tabs.Trigger>
      {/each}
    </Tabs.List>
    <Tabs.TabsContent value="all"></Tabs.TabsContent>
  </Tabs.Root>
{/if}
