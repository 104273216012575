<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { Card } from '$lib/components/ui/card';
  import Icon from '@iconify/svelte';
  import InvoicesTable from './invoices-table.svelte';
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from '$lib/components/ui/dialog';

  import { outstandingInvoicesStatistics } from '$lib/api/invoice-api';
  import InvoicesChart from './invoices-chart.svelte';
  import InvoicesResults from './invoices-results.svelte';
  const OUTSTANDING_INVOICE_COLORS = {
    'More than 60 days past due': 'hsl(0, 87%, 67%)', // Bright red, similar to red-500
    '30–60 days past due': 'hsl(27, 96%, 61%)', // Bright orange, similar to orange-500
    '0-30 days past due': 'hsl(48, 96%, 53%)', // Bright yellow, similar to yellow-500
    'Not yet past due': 'hsl(217, 91%, 60%)', // Bright blue, similar to blue-500
  };

  const outstandingInvoicesQuery = createQuery({
    queryKey: ['invoices', 'outstanding'],
    queryFn: async () => {
      const data = await outstandingInvoicesStatistics();
      return processOutstandingInvoicesData(data);
    },
  });

  $: ({ data, isLoading, isError, error } = $outstandingInvoicesQuery);

  function processOutstandingInvoicesData(data) {
    return data?.map(item => ({
      ...item,
      color: OUTSTANDING_INVOICE_COLORS[item.status],
    }));
  }

  function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }).format(amount);
  }

  let showDialog = false;
  let filter: {
    status?: string;
    paymentDeadlineStart?: string;
    paymentDeadlineEnd?: string;
  } = {};

  function handleInvoiceClick(
    event: CustomEvent<{
      status: string;
      count: number;
      amount: number;
      paymentDeadlineStart?: string;
      paymentDeadlineEnd?: string;
    }>,
  ) {
    showDialog = true;

    filter = {
      status: event.detail.status === 'Not yet past due' ? 'Open' : 'Overdue',
      paymentDeadlineStart: event.detail.paymentDeadlineStart,
      paymentDeadlineEnd: event.detail.paymentDeadlineEnd,
    };
  }

  $: total = data?.reduce((acc, curr) => acc + curr.amount, 0);
</script>

<Card class="mb-4 max-w-lg">
  <div class="flex justify-between items-center p-4">
    <div class="flex gap-2 items-center">
      <Icon icon="mdi:alert-circle" class="text-blue-500" />
      <div>
        <h2 class="text-base">Outstanding invoices</h2>
        <p class="font-mono text-lg text-muted-foreground">
          {formatCurrency(total ?? 0)}
        </p>
      </div>
    </div>
  </div>
  <div class="flex p-2">
    <div>
      <h2 class="hidden mb-2 text-lg font-semibold">Outstanding invoices</h2>
      {#if isLoading}
        <p>Loading outstanding invoice data...</p>
      {:else if isError}
        <p>Error loading outstanding invoice data: {error.message}</p>
      {:else if data}
        <InvoicesChart {data} statusColors={OUTSTANDING_INVOICE_COLORS} />
        <InvoicesResults
          label="Invoice age"
          {data}
          on:click={handleInvoiceClick}
        />
      {/if}
    </div>
  </div>
</Card>

<Dialog bind:open={showDialog}>
  <DialogContent class="max-w-4xl">
    <DialogHeader>
      <DialogTitle>Invoices</DialogTitle>
    </DialogHeader>
    <div class="max-h-[80vh] overflow-auto">
      <InvoicesTable {filter} />
    </div>
  </DialogContent>
</Dialog>
