<script lang="ts">
  export let item;
  let className = undefined;

  export { className as class };
</script>

<iframe
  title="Email Content"
  class={className}
  srcdoc={`
<html>
    <head>
    <style>
        body { 
            color: #000;
            background: #fff;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            margin: 0; 
            height: 100%; 
        }
        
        @media (prefers-color-scheme: dark) {
            body {
                color: hsl(210 40% 98%);
                background: #020817;
            }
        }
    </style>
    </head>
    <body>
    ${item?.description ?? ''}    
    </body>
</html>
`}
/>
