<script lang="ts">
  import EventsSimpleTimeline from '$lib/ui/events-simple-timeline.svelte';
  import { Button } from '$lib/components/ui/button';
  import PdfIcon from '$lib/components/icons/pdf-icon.svelte';
  import AdobeSupabasePreview from '$lib/components/hanta/files/adobe-supabase-preview.svelte';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  import { createPrintDataForInvoice } from '$lib/pages/invoices/invoice-utils';

  export let item;
  export let isLocked;
  export let form;

  let openPdf = false;

  const { form: formData } = form;

  $: printData = createPrintDataForInvoice(item);
</script>

<div class="flex flex-col gap-4 p-6 mb-20 w-full">
  <EventsSimpleTimeline
    activities={[
      {
        description: 'Invoice was paid',
        timestamp: item.paidOn,
        highlight: true,
      },
      {
        description: 'Invoice was finalized',
        timestamp: item.fixedOn,
        highlight: true,
      },
      {
        description: 'Invoice was created',
        timestamp: item.createdAt,
        highlight: true,
      },
    ].filter(timeline => timeline.timestamp)}
  />

  {#if isLocked && $formData.status !== 'Draft'}
    <div>
      <Button
        variant="outline"
        class="px-6 py-8"
        on:click={() => (openPdf = true)}
      >
        <PdfIcon />
        <div class="ml-2">
          {$formData.name}
        </div>
      </Button>
      {#if openPdf}
        <div class="absolute">
          <AdobeSupabasePreview
            folder={`invoices/${item?.id}/invoice`}
            filename={'invoice.pdf'}
            outputfilename={`${$formData.name}.pdf`}
            mode={'LIGHT_BOX'}
            onClose={() => (openPdf = false)}
          />
        </div>
      {/if}
    </div>
  {/if}

  <DocxTemplates
    class="px-4 py-2"
    qrCodeData={printData.qrCode}
    data={printData}
    label="Invoice"
    templateName="invoice.docx"
    customFilename={printData.name}
  />

  <HantaInputLovSelect
    readOnly={isLocked}
    {form}
    placeholder="Select tags..."
    bind:value={$formData.tags}
    type="Rechnung - Tags"
    label="Tags"
    addable={true}
    multiple={true}
  />

  <Files compact={true} folder={`invoices/${item?.id}`} />

  <FormActions {form} />
</div>
