<script lang="ts">
  import { getPrimaryRoutes } from '$lib/config';
  import { Command as CommandPrimitive } from 'cmdk-sv';

  import { appStore } from '$lib/app-store';
  import * as Command from '$lib/components/ui/command';
  import { cn } from '$lib/utils';

  import Icon from '@iconify/svelte';
  import { onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import {
    globalSearch,
    getItemById,
    getSearchSuggestions,
  } from '$lib/api/queries';
  import * as Avatar from '$lib/components/ui/avatar';
  import Address from '$lib/components/hanta/address.svelte';
  import Communication from '$lib/components/hanta/communication.svelte';
  import { formatDistanceToNow } from 'date-fns';

  export let open = false;
  let searchTerm = '';
  let currentSelected = 0;

  let lastShiftPressTime = 0;
  const doubleShiftInterval = 300; // Maximum time in milliseconds between double presses
  let shiftPressed = false;

  onMount(() => {
    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        open = !open;
      }
      if (e.key === 'Shift' && import.meta.env.MODE !== 'plugin') {
        const currentTime = new Date().getTime();
        if (
          currentTime - lastShiftPressTime <= doubleShiftInterval &&
          !shiftPressed
        ) {
          e.preventDefault();
          console.debug('Double Shift pressed!');
          open = !open;
        }

        shiftPressed = true;
        lastShiftPressTime = currentTime;
      }
    }

    function handleKeyup(e) {
      if (e.key === 'Shift') {
        shiftPressed = false;
      }
    }

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);
    };
  });

  let results = [];
  let list;

  let ac = new AbortController();

  $: if (searchTerm) {
    console.debug('searchTerm', searchTerm);
    if (ac) {
      ac.abort();
    }

    ac = new AbortController();

    globalSearch(searchTerm, ac.signal).then(response => {
      results = response || [];
      console.debug(response);
      currentSelected = 0;
    });
  } else {
    results = [];
    getSearchSuggestions().then(suggestions => {
      results = suggestions || [];
    });
    if (ac) {
      ac.abort();
    }
  }

  function scrollTo() {
    if (!list || results.length === 0) return;

    const itemHeight = 96; // Height of each result item in pixels
    const padding = 16; // Additional padding for better visibility

    const itemTop = currentSelected * itemHeight;
    const itemBottom = itemTop + itemHeight;

    const viewportTop = list.scrollTop;
    const viewportBottom = viewportTop + list.clientHeight;

    // Scroll up if item is above viewport
    if (itemTop < viewportTop) {
      list.scrollTo({
        top: itemTop - padding,
        behavior: 'smooth',
      });
    }
    // Scroll down if item is below viewport
    else if (itemBottom > viewportBottom) {
      list.scrollTo({
        top: itemBottom - list.clientHeight + padding,
        behavior: 'smooth',
      });
    }
  }

  function onKeyDown(e) {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      currentSelected = Math.min(currentSelected + 1, results.length - 1);
      scrollTo();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      currentSelected = Math.max(currentSelected - 1, 0);
      scrollTo();
    } else if (e.key === 'Enter') {
      openItem(results[currentSelected]);
    }
  }

  function openItem({ module, id }) {
    appStore.select({
      module,
      id,
    });
    appStore.openPopup(true);
    open = false;
  }

  function groupByDate(items) {
    const groups = {};

    items.forEach(item => {
      const date = new Date(item.modifiedAt);
      const key = formatDistanceToNow(date, { addSuffix: true });

      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    });

    return groups;
  }

  const routes = getPrimaryRoutes();

  function getModuleIcon(module: string) {
    const route = routes.find(r => r.module === module);
    return route?.icon || 'mdi:folder-outline'; // fallback icon
  }
</script>

<div
  class={cn(
    $appStore.isCollapsed
      ? 'group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 bg-primary-100 md:rounded-t md:border md:border-b-0 md:border-solid md:border-primary/20 z-10 md:shadow-border md:shadow-md'
      : '-mb-2 -ml-1',
  )}
>
  <nav
    class="tn-grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2"
  >
    <Tooltip.Root openDelay={0}>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="ghost"
          size="icon"
          class={cn(
            $appStore.isCollapsed
              ? 'md:size-9'
              : 'w-full flex justify-start space-x-4 px-2',
          )}
          on:click={() => (open = !open)}
        >
          <Icon icon="mdi:magnify" class="size-5" />

          <span class:hidden={$appStore.isCollapsed}>Search</span>
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content side="right">
        <div class="flex z-10 items-center space-x-2 w-1/2">
          <span>Search</span>
          <span>
            <kbd
              class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
            >
              <span class="text-xs">⌘</span>J
            </kbd>
          </span>
          {#if import.meta.env.MODE !== 'plugin'}
            <span class="mx-2">or</span>
            <span>
              <kbd
                class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
              >
                <kbd>⇧</kbd> twice
              </kbd>
            </span>
          {/if}
        </div>
      </Tooltip.Content>
    </Tooltip.Root>

    <Command.Dialog bind:open>
      <div class="pt-2 pr-12 pb-2 pl-6 bg-secondary">
        <CommandPrimitive.Input
          class={cn(
            'focus-visible:ring-0 flex h-10 w-full rounded-md bg-transparent py-3 border-none text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          )}
          placeholder="Search in accounts, deals and contacts"
          bind:value={searchTerm}
          on:keydown={onKeyDown}
        />
      </div>
      <div class="grid-cols-2 tn-grid">
        {#if !searchTerm}
          <div class="p-2">
            <div
              class="overflow-scroll min-h-[30vh] max-h-[50svh] space-y-4"
              bind:this={list}
            >
              {#each Object.entries(groupByDate(results)) as [dateGroup, items], groupIndex}
                <div>
                  <h3 class="px-2 mb-2 text-sm text-muted-foreground">
                    {dateGroup}
                  </h3>
                  <div class="space-y-2">
                    {#each items as item, idx}
                      {@const absoluteIndex = results.findIndex(
                        r => r.id === item.id,
                      )}
                      <button
                        on:click={() => openItem(item)}
                        on:keydown={e => {
                          if (e.key === 'Enter') {
                            openItem(item);
                          }
                        }}
                        class={cn(
                          absoluteIndex === currentSelected && 'bg-secondary',
                          'flex items-center gap-2 w-full text-left p-2 overflow-hidden whitespace-nowrap rounded cursor-pointer hover:bg-primary/20 transition-colors duration-100 ease-in-out',
                        )}
                      >
                        <span class="flex-shrink-0">
                          <Icon
                            icon={getModuleIcon(item.module)}
                            class="size-5"
                          />
                        </span>
                        <div>
                          <div class="text-base">{item.name ?? ''}</div>
                          {#if item.module}
                            <div class="text-sm text-muted-foreground">
                              {item.module}
                            </div>
                          {/if}
                        </div>
                      </button>
                    {/each}
                  </div>
                </div>
              {/each}
            </div>
          </div>
        {:else}
          <div>
            <div class="mt-2 ml-2 text-xs">Best matches: {results?.length}</div>

            <div class="p-2">
              <div
                class="overflow-scroll min-h-[30vh] max-h-[50svh] space-y-2"
                bind:this={list}
              >
                {#each results || [] as result, idx}
                  <button
                    on:click={() => openItem(result)}
                    on:keydown={e => {
                      if (e.key === 'Enter') {
                        openItem(result);
                      }
                    }}
                    class={cn(
                      idx === currentSelected && 'bg-secondary',
                      'flex items-center gap-2 w-full text-left p-2 overflow-hidden whitespace-nowrap rounded cursor-pointer hover:bg-primary/20 transition-colors duration-100 ease-in-out',
                    )}
                  >
                    <span class="flex-shrink-0">
                      <Icon
                        icon={getModuleIcon(result.module)}
                        class="size-5"
                      />
                    </span>
                    <div>
                      <div class="text-base">{result.name ?? ''}</div>
                      {#if result.module}
                        <div class="text-sm text-muted-foreground">
                          {result.module}
                        </div>
                      {/if}
                    </div>
                  </button>
                {/each}
              </div>
            </div>
          </div>
        {/if}

        <div class="p-4 bg-primary-foreground">
          {#if results.length > 0 && currentSelected >= 0 && currentSelected < results.length}
            {@const item = results[currentSelected]}
            {#await getItemById(item.module, item.id, false)}
              <div></div>
            {:then item}
              <Avatar.Root class="rounded-none size-36">
                <Avatar.Image
                  src={item?.photo || item?.logo}
                  alt="Contact Photo"
                  class="object-scale-down"
                />
                <Avatar.Fallback>{item?.name ?? ''}</Avatar.Fallback>
              </Avatar.Root>

              <div class="text-xl">{item['name']}</div>
              {#if item['invoiceId']}
                <div class="text-sm text-muted-foreground">
                  {item['invoiceId']}
                </div>
              {/if}

              {@const address =
                item['addressPrivate'] ||
                item['addressWork'] ||
                item['address']}
              {@const communication =
                item['communicationPrivate'] ||
                item['communicationWork'] ||
                item['communication']}
              {#if address}
                <div class="mt-2">
                  <Address {address} />
                </div>
              {/if}
              {#if communication}
                <div class="mt-2">
                  <Communication {communication} />
                </div>
              {/if}
            {:catch error}
              <div>Error: {error.message}</div>
            {/await}
          {/if}
        </div>
      </div>
      <div
        class="flex gap-4 items-center p-2 text-sm border-t border-border text-muted-foreground"
      >
        <span>↑↓ Select</span>
        <span>↲ Open</span>
        <span class="hidden">⌘L Copy link</span>
        <span>⌘J Command Search</span>
      </div>
    </Command.Dialog>
  </nav>
</div>
